export const ENV = process.env.REACT_APP_ENV
export const BUILD_ENV = process.env.REACT_APP_BUILD_ENV
export const BUCKET_NAME = process.env.REACT_APP_BUCKET_NAME
export const SCOPE = process.env.REACT_APP_SCOPE
export const REPORT_BASE_URL = process.env.REACT_APP_REPORT_BASE_URL

//  AWS COGNITO ENV
export const AWS_COGNITO_REGION = process.env.REACT_APP_AWS_COGNITO_REGION
export const AWS_USER_POOLS_ID = process.env.REACT_APP_AWS_USER_POOLS_ID
export const AWS_USER_POOLS_WEB_CLIENT_ID =
  process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID
export const AWS_COGNITO_AUTH_DOMAIN =
  process.env.REACT_APP_AWS_COGNITO_AUTH_DOMAIN
export const AWS_COGNITO_SHARED_DOMAIN = process.env.REACT_APP_SHARED_DOMAIN

// AWS IDENTITY POOL ENV
export const AWS_IDENTITY_POOL_ID = process.env.REACT_APP_AWS_IDENTITY_POOL_ID
export const AWS_IDENTITY_POOL_REGION =
  process.env.REACT_APP_AWS_IDENTITY_POOL_REGION

// CUSTOMS
export const PUBLIC_ATRIO_URL = process.env.REACT_APP_PUBLIC_ATRIO_URL

const verifyEnv = () => {
  const envs = {
    ENV,
    BUILD_ENV,
    BUCKET_NAME,
    SCOPE,
    REPORT_BASE_URL,
    AWS_COGNITO_REGION,
    AWS_USER_POOLS_ID,
    AWS_USER_POOLS_WEB_CLIENT_ID,
    AWS_COGNITO_AUTH_DOMAIN,
    AWS_IDENTITY_POOL_ID,
    AWS_IDENTITY_POOL_REGION,
    AWS_COGNITO_SHARED_DOMAIN
  }

  Object.entries(envs).forEach(([name, value]) => {
    if ([undefined, null, ''].includes(value)) {
      throw new Error(`${name} is not defined, current value is ${value}`)
    }
  })
}

verifyEnv()

const commons = {}

const configs = {
  prod: {
    SQS_BASE_URL: 'https://wtk56jffii.execute-api.us-east-1.amazonaws.com/Prod',
    COMMENTS_BASE_URL:
      'https://pky1gpfc7e.execute-api.us-east-1.amazonaws.com/Prod',
    DOCX_API_BASE_URL:
      'https://bnzlegh7qh.execute-api.us-east-1.amazonaws.com/Prod',
    ROOT_STATE_MACHINE_BASE_URL:
      'https://xm3qf78q9d.execute-api.us-east-1.amazonaws.com/Prod',
    GOOGLE_MAPS_BASE_URL:
      'https://760o4hdpji.execute-api.us-east-1.amazonaws.com/Prod',
    JURIDICO_DETAILS_BASE_URL:
      'https://3wk2e1bf84.execute-api.sa-east-1.amazonaws.com/Prod',
    GRUPO_SOCIETARIO_BASE_URL:
      'https://7270a8jqpi.execute-api.us-east-1.amazonaws.com/Prod'
  },
  staging: {
    SQS_BASE_URL:
      'https://bcnjvqsdy4.execute-api.us-east-1.amazonaws.com/Staging',
    COMMENTS_BASE_URL:
      'https://8c77wcrcpj.execute-api.us-east-1.amazonaws.com/Staging',
    DOCX_API_BASE_URL:
      'https://uegleesx32.execute-api.us-east-1.amazonaws.com/Staging',
    ROOT_STATE_MACHINE_BASE_URL:
      'https://2odilfao8g.execute-api.us-east-1.amazonaws.com/Staging',
    GOOGLE_MAPS_BASE_URL:
      'https://gkpckl89b9.execute-api.us-east-1.amazonaws.com/Staging',
    JURIDICO_DETAILS_BASE_URL:
      'https://99a1ctjdyj.execute-api.sa-east-1.amazonaws.com/Staging',
    GRUPO_SOCIETARIO_BASE_URL:
      'https://9oauztwbj1.execute-api.us-east-1.amazonaws.com/Staging'
  },
  dev: {
    SQS_BASE_URL: 'https://4vqjtfkgu6.execute-api.us-east-1.amazonaws.com/Dev',
    COMMENTS_BASE_URL:
      'https://3wswlq1vqf.execute-api.us-east-1.amazonaws.com/Dev',
    DOCX_API_BASE_URL:
      'https://cc34nlqt0d.execute-api.us-east-1.amazonaws.com/Dev',
    ROOT_STATE_MACHINE_BASE_URL:
      'https://5pgemy0dgh.execute-api.us-east-1.amazonaws.com/Dev',
    GOOGLE_MAPS_BASE_URL:
      'https://34b29j11ji.execute-api.us-east-1.amazonaws.com/Dev',
    JURIDICO_DETAILS_BASE_URL:
      'https://31aerxtvu0.execute-api.us-east-1.amazonaws.com/Dev',
    GRUPO_SOCIETARIO_BASE_URL:
      'https://kj0rzhunk2.execute-api.us-east-1.amazonaws.com/Dev'
  }
}

export const {
  GOOGLE_MAPS_BASE_URL,
  DOCX_API_BASE_URL,
  JURIDICO_DETAILS_BASE_URL,
  SQS_BASE_URL,
  ROOT_STATE_MACHINE_BASE_URL,
  GRUPO_SOCIETARIO_BASE_URL,
  COMMENTS_BASE_URL
} = { ...commons, ...configs[BUILD_ENV] }
