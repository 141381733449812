import React from 'react'
import { GiInjustice } from 'react-icons/gi'
import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import ReactTooltip from 'react-tooltip'

import CustomWindow from './CustomWindow'
import DetailsTimeline from './detailsTimeline'
import DetailsPartes from './detailsPartes'
import DetailsProcesso from './detailsProcesso'

const DetailsJuridico = ({ processoDetails, processo, resultData }) => {
  const juridico = resultData

  const nup = processo?.nup?.replace(/\D/g, '')

  return processoDetails ? (
    <CustomWindow title={`Detalhes do Processo - ${processo?.nup}`} defaultOpen>
      <ReactTooltip />
      <div className='row'>
        <div className='col-auto d-flex'>
          <div
            className='col-auto text-center juridico-divisor-icon'
            style={{ marginTop: '20px' }}
          >
            <GiInjustice size={30} />
          </div>

          <div
            className='col-auto text-center juridico-divisor-icon juridico-divisor-uf'
            style={{ marginTop: '20px' }}
          >
            <span>RJ</span>
          </div>
        </div>

        <div className='col-6'>
          {processo && (
            <div className='col-12 text-left juridico-divisor-boxH1'>
              {processo && processo.nup}
            </div>
          )}
          {processo && (
            <div className='col-12 text-left juridico-divisor-box'>
              {processo && processo.justica}
            </div>
          )}
          {processo && (
            <div className='col-12 text-left juridico-divisor-nup'>
              {processo && processo.diario}
            </div>
          )}
        </div>

        <div className='col-3'>
          {processo && juridico?.scores?.societario && (
            <div
              className='col-12 row justify-content-center juridico-scores-box'
              style={{ marginTop: '10px' }}
            >
              <div className='col-6'>
                <div className='value'>
                  <CircularProgressbar
                    maxValue={1}
                    value={parseFloat(
                      juridico.scores.parental?.[nup] || '0'
                    ).toFixed(3)}
                    text={`${(
                      (juridico.scores.parental?.[nup] || 0) * 100
                    ).toFixed(0)}%`}
                  />
                </div>
                <div
                  className='label'
                  style={{
                    fontSize: '10px',
                    fontWeight: '700',
                    textTransform: 'uppercase',
                    textAlign: 'center',
                    marginTop: '10px'
                  }}
                >
                  Pessoas Físicas
                </div>
              </div>

              <div className='col-6'>
                <div className='value'>
                  <CircularProgressbar
                    maxValue={1}
                    value={parseFloat(
                      juridico.scores.societario?.[nup] || '0'
                    ).toFixed(3)}
                    text={`${(
                      (juridico.scores.societario?.[nup] || 0) * 100
                    ).toFixed(0)}%`}
                  />
                </div>
                <div
                  className='label'
                  style={{
                    fontSize: '10px',
                    fontWeight: '700',
                    textTransform: 'uppercase',
                    textAlign: 'center',
                    marginTop: '10px'
                  }}
                >
                  Pessoas Jurídicas
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {processo && processo.nup && (
        <>
          <DetailsProcesso
            processo={
              processoDetails &&
              processoDetails.length > 0 &&
              processoDetails[0].partes
            }
            detalhes={
              processoDetails &&
              processoDetails.length > 0 &&
              processoDetails[0].detalhes
            }
            finalizado={processo.finalizado}
            nup={processo && processo.nup}
          />
        </>
      )}

      {processo && (
        <>
          <p className='text-primary mt-4 mb-0' style={{ fontSize: 24 }}>
            Andamento do Processo
          </p>
          <DetailsTimeline
            timeline={
              processoDetails &&
              processoDetails.length > 0 &&
              processoDetails[0] &&
              processoDetails[0].andamentos
            }
          />
        </>
      )}

      <DetailsPartes
        processo={
          processoDetails &&
          processoDetails.length > 0 &&
          processoDetails[0].partes
        }
        detalhes={
          processoDetails &&
          processoDetails.length > 0 &&
          processoDetails[0].detalhes
        }
        nup={processo && processo.nup}
      />
    </CustomWindow>
  ) : (
    <div className='row'>
      <div className='col-12 text-center'>
        <div className='noItemChecked'>
          clique em algum processo acima para ver detalhes
        </div>
      </div>
    </div>
  )
}

export default DetailsJuridico
