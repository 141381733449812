import {
  S3Client as S3ClientAWS,
  GetObjectCommand,
  PutObjectCommand,
  CopyObjectCommand
} from '@aws-sdk/client-s3'
import { Buffer } from 'buffer'

import { resolveCredentials } from '../utils'

const S3Client = ({ region, s3Client, credentials }) => {
  const client =
    s3Client ||
    new S3ClientAWS({
      region: region ?? 'us-east-1',
      credentials: credentials
    })

  const readFile = async ({ bucket, key, responseContentType }) => {
    const command = new GetObjectCommand({
      Bucket: bucket,
      Key: key,
      ...(responseContentType
        ? { ResponseContentType: responseContentType }
        : {})
    })

    const data = await client.send(command)

    if (!isReadableStream(data.Body)) {
      throw new Error(
        'Expected stream to be instance of ReadableStream, but got ' +
          typeof data.Body
      )
    }

    const contentType = data.ContentType || ''

    if (
      ['application/octet-stream', 'binary/octet-stream'].includes(contentType)
    ) {
      return streamToBuffer(data.Body)
    }

    const bodyContent = await streamToString(data.Body)

    try {
      if (data.ContentType === 'application/json') {
        return JSON.parse(bodyContent)
      }

      return bodyContent
    } catch (err) {
      return bodyContent
    }
  }

  const writeFile = async ({ bucket, key, data, contentType }) => {
    const body =
      contentType === 'application/json' ? JSON.stringify(data) : data

    const command = new PutObjectCommand({
      Bucket: bucket,
      Key: key,
      Body: body,
      ContentType: contentType
    })

    return await client.send(command)
  }

  const copyFile = async ({
    bucket,
    sourceKey,
    destinationKey,
    contentType
  }) => {
    const command = new CopyObjectCommand({
      Bucket: bucket,
      CopySource: sourceKey,
      Key: destinationKey,
      ContentType: contentType
    })

    return await client.send(command)
  }

  return {
    _client: client,
    readFile,
    copyFile,
    writeFile
  }
}

async function streamToBuffer (stream) {
  return new Promise((resolve, reject) => {
    const array = []

    const reader = stream.getReader()
    const processRead = ({ done, value }) => {
      if (done) {
        resolve(Buffer.from(array))
        return
      }

      value.forEach(v => array.push(v))

      // Not done, keep reading
      reader
        .read()
        .then(processRead)
        .catch(reject)
    }

    // start read
    reader
      .read()
      .then(processRead)
      .catch(reject)
  })
}

const isReadableStream = stream => {
  return stream instanceof ReadableStream
}

async function streamToString (stream) {
  return new Promise((resolve, reject) => {
    let text = ''
    const decoder = new TextDecoder('utf-8')

    const reader = stream.getReader()
    const processRead = ({ done, value }) => {
      if (done) {
        resolve(text)
        return
      }

      text += decoder.decode(value)

      // Not done, keep reading
      reader
        .read()
        .then(processRead)
        .catch(reject)
    }

    // start read
    reader
      .read()
      .then(processRead)
      .catch(reject)
  })
}

export class StaticS3Client {
  static _instance
  static _accessToken
  static _credentials

  static async _resolveCredentials () {
    if (!StaticS3Client._accessToken) {
      throw new Error('Access token is required')
    }

    const credentials = await resolveCredentials({
      accessToken: StaticS3Client._accessToken
    })

    StaticS3Client._credentials = credentials
  }

  static _resolveInstance () {
    if (!StaticS3Client._accessToken) {
      throw new Error('Access token is required')
    }
    StaticS3Client._instance = S3Client({
      accessToken: StaticS3Client._accessToken,
      credentials: StaticS3Client._credentials
    })
  }

  static async getInstance ({ accessToken }) {
    const isNewToken =
      !StaticS3Client._accessToken ||
      StaticS3Client._accessToken !== accessToken
    if (isNewToken) {
      StaticS3Client._accessToken = accessToken
      await StaticS3Client._resolveCredentials()
    }

    const isCredentialsExpired =
      StaticS3Client._credentials?.expiration &&
      StaticS3Client._credentials.expiration.getTime() <= Date.now()
    if (isCredentialsExpired) {
      await StaticS3Client._resolveCredentials()
    }

    console.log(
      'S3:',
      'isNewToken',
      isNewToken,
      'isCredentialsExpired',
      isCredentialsExpired,
      StaticS3Client._credentials?.expiration
    )

    const isNewInstanceRequired = isNewToken || isCredentialsExpired

    if (!StaticS3Client._instance || isNewInstanceRequired) {
      StaticS3Client._resolveInstance()
    }

    return StaticS3Client._instance
  }
}

export default S3Client
