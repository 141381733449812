import { StaticS3Client } from './s3'
import API from '../services/api'
import { BUCKET_NAME } from '../config/env'

import {
  hashObject,
  getCurrentUser,
  resolveScope,
  getIdToken,
  resolveInternalScope
} from '../utils'

export const gerarDocumento = async ({
  state,
  queueUrl,
  document,
  filename,
  discoveryStartedAt
}) => {
  const userId = await getCurrentUser()
  const scope = resolveScope()
  const internalScope = await resolveInternalScope()

  if (!scope) {
    console.error(`Scope inválido:${scope}`)
    throw new Error('Scope inválido')
  }

  const timestamp = new Date().getTime()
  const hash = hashObject({ state, timestamp, scope, userId })

  const s3Path = {
    bucket: BUCKET_NAME,
    key: `boanota/${scope}/applications/relatorio_cadastro/docx/document=${document}/date=${discoveryStartedAt}/${userId}-${internalScope}-${hash}-input.json`
  }

  const accessToken = await getIdToken()
  const s3Client = await StaticS3Client.getInstance({ accessToken })

  await s3Client.writeFile({
    bucket: s3Path.bucket,
    key: s3Path.key,
    data: state,
    contentType: 'application/json'
  })

  const { executionArn } = await API.docx.startGenerateDocumento({
    queueUrl,
    document,
    hash,
    filename,
    s3InputPath: s3Path,
    userId,
    scope,
    bucketOutput: BUCKET_NAME,
    discoveryStartedAt
  })

  return executionArn
}
