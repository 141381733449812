export const PROGRESS_TYPES = {
  LOADED: 'loaded',
  PENDING: 'pending',
  ERROR: 'error',
  PARTIAL_ERROR: 'partialError'
}

export const resolveProgressStatus = (s3Path, name) => {
  const { succeeded, total, failedJobs } = s3Path
  if (typeof succeeded === 'boolean') {
    // O tipo booleano é usado para indicar que o arquivo não existe,
    // isso significa que a step rodou corretamente, só não tinha a informação
    return PROGRESS_TYPES.LOADED
  }

  if (
    (typeof succeeded !== 'number' && typeof failedJobs !== 'number') ||
    typeof total !== 'number'
  ) {
    throw new Error(`${name}:Tipo inválido`)
  }

  if (succeeded === total || failedJobs === 0) {
    return PROGRESS_TYPES.LOADED
  }

  if (succeeded === 0 || failedJobs === total) {
    return PROGRESS_TYPES.ERROR
  }

  return PROGRESS_TYPES.PARTIAL_ERROR
}

export const isLoaded = status =>
  [PROGRESS_TYPES.LOADED, PROGRESS_TYPES.PARTIAL_ERROR].includes(status)

export const isError = status => status === PROGRESS_TYPES.ERROR
