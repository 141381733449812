import axios from 'axios'

import { ROOT_STATE_MACHINE_BASE_URL } from '../../config/env'
import { getIdToken, toCamelCase } from '../../utils'

export const startRootStepFunction = async ({
  document,
  queueUrl,
  enableSerasa,
  enablePartners,
  username
}) => {
  const url = `${ROOT_STATE_MACHINE_BASE_URL}/boanota/search`

  const idToken = await getIdToken()

  if (!username) {
    throw new Error('Username is required')
  }

  const { data } = await axios.post(
    url,
    {
      document,
      skip_serasa: !enableSerasa,
      skip_partners: !enablePartners,

      skip_termo_suspensao: false,
      skip_termo_embargo: false,
      skip_termo_apreensao: false,
      skip_auto_infracao: false,
      skip_pep: false,
      skip_acordos_leniencia: false,
      skip_ceaf: false,
      skip_ceis: false,
      skip_cepim: false,
      skip_cnep: false,
      skip_cadastro_expulsoes: false,
      skip_ofac: false,

      skip_documents_jive: false,
      skip_nome_jive: false,
      skip_nomes_jive: false,
      skip_certidoes: false,
      skip_devedores: false,

      skip_protesto: false,
      skip_cndt: false,
      skip_pgfn: false,
      skip_cheque: false,
      skip_bndes: false,
      skip_comprot: false,
      skip_registrosrde: false,
      skip_offshore: false,
      skip_aeronaves: false,
      skip_sncr: false,
      skip_sigef: false,
      skip_ibama: false,
      skip_inpi: false,

      skip_news: false,
      skip_juridico: false,
      skip_cruzamentos: false,
      skip_bigboost_juridico: false,

      skip_trabalho_escravo: false,

      queue_url: queueUrl
    },
    {
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    }
  )

  return toCamelCase(data)
}
