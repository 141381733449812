import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Tab, Tabs } from 'react-bootstrap'

import { selectMinData } from '../../../../redux/selectors/dadosBasicos'

import '../../../../assets/sass/cards.scss'

import { Content } from '../../../../components'
import {
  Faturamento,
  Images,
  Operacionais,
  ScoreComportamental,
  ScorePld,
  ScoreProspeccao
} from './components'

const Formulario = () => {
  const { targetName } = useSelector(selectMinData)
  const formularioData = useSelector(state => state.sulbrasil.formulario)

  const [data, setData] = useState({
    faturamento: formularioData.faturamento || [],
    dadosOperacionais: {}
  })

  return (
    <Content
      title={`Formulário ${targetName ? `- ${targetName}` : ''}`}
      subtitle=''
    >
      <Tabs defaultActiveKey='scorePld' className='mb-3' justify>
        <Tab eventKey='scorePld' title='Score PLD'>
          <ScorePld />
        </Tab>
        <Tab eventKey='scoreComportamental' title='Score Comportamental'>
          <ScoreComportamental />
        </Tab>
        <Tab eventKey='scoreProspeccao' title='Score Prospecção'>
          <ScoreProspeccao />
        </Tab>
        <Tab eventKey='faturamento' title='Faturamento'>
          <Faturamento value={data.faturamento} />
        </Tab>
        <Tab eventKey='operacionais' title='Dados Operacionais'>
          <Operacionais
            values={data.pld}
            onChange={nextValue =>
              setData(prev => ({
                ...prev,
                pld: { ...prev.pld, ...nextValue }
              }))
            }
          />
        </Tab>
        <Tab eventKey='images' title='Imagens'>
          <Images />
        </Tab>
      </Tabs>
    </Content>
  )
}

export default Formulario
