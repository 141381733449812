import {
  resolveProgressStatus,
  isError,
  isLoaded
} from '../../lib/progressStatus'

// Action Types
export const Types = {
  RESET: 'MATCH_GRUPO_SOCIETARIO_THEME/RESET',
  SET_S3_PATH: 'MATCH_GRUPO_SOCIETARIO_THEME/SET_S3_PATH',
  SET_ERROR: 'MATCH_GRUPO_SOCIETARIO_THEME/SET_ERROR'
}

// Reducer
const initialState = {
  email: {
    s3Path: undefined,
    error: false,
    loaded: false
  },
  endereco: {
    s3Path: undefined,
    error: false,
    loaded: false
  },
  telefone1: {
    s3Path: undefined,
    error: false,
    loaded: false
  },
  telefone2: {
    s3Path: undefined,
    error: false,
    loaded: false
  }
}

export default function reducer (state = initialState, action) {
  switch (action.type) {
    case Types.SET_S3_PATH: {
      const statusEmail = resolveProgressStatus(action.payload.email)
      const statusEndereco = resolveProgressStatus(action.payload.endereco)
      const statusTelefone1 = resolveProgressStatus(action.payload.telefone1)
      const statusTelefone2 = resolveProgressStatus(action.payload.telefone2)

      return {
        ...state,
        email: {
          ...state.email,
          s3Path: action.payload.email,
          error: isError(statusEmail),
          loaded: isLoaded(statusEmail)
        },
        endereco: {
          ...state.endereco,
          s3Path: action.payload.endereco,
          error: isError(statusEndereco),
          loaded: isLoaded(statusEndereco)
        },
        telefone1: {
          ...state.telefone1,
          s3Path: action.payload.telefone1,
          error: isError(statusTelefone1),
          loaded: isLoaded(statusTelefone1)
        },
        telefone2: {
          ...state.telefone2,
          s3Path: action.payload.telefone2,
          error: isError(statusTelefone2),
          loaded: isLoaded(statusTelefone2)
        }
      }
    }
    case Types.SET_ERROR:
      return {
        ...state,
        email: {
          ...state.email,
          error: action.payload,
          loaded: true
        },
        endereco: {
          ...state.endereco,
          error: action.payload,
          loaded: true
        },
        telefone1: {
          ...state.telefone1,
          error: action.payload,
          loaded: true
        },
        telefone2: {
          ...state.telefone2,
          error: action.payload,
          loaded: true
        }
      }
    case Types.RESET:
      return initialState
    default:
      return state
  }
}

// Action Creators
export function setErrorMatchGrupoSocietario (data) {
  return {
    type: Types.SET_ERROR,
    payload: data
  }
}

export function setS3PathMatchGrupoSocietario ({
  email,
  endereco,
  telefone1,
  telefone2
}) {
  return {
    type: Types.SET_S3_PATH,
    payload: { email, endereco, telefone1, telefone2 }
  }
}

export function resetMatchGrupoSocietarioTheme () {
  return {
    type: Types.RESET
  }
}
