import Swal from 'sweetalert2'
import { toast } from 'react-toastify'
import { backOff } from 'exponential-backoff'

import { gerarDocumento } from '../lib/gerarDocumento'
import { SQSConsumer } from '../lib/sqs'
import { getIdToken } from '../utils'

const exportDoc = async ({ state, queueUrl }) => {
  const { globalStep, ...internalState } = state
  const document = globalStep?.targetDocument
  const discoveryStartedAt = globalStep?.metadata?.startedAt.toISOString()

  try {
    const { dismiss, value: name } = await Swal.fire({
      title: 'Exportar Dados',
      text:
        'As informações deste CNPJ/CPF serão exportadas para o Microsoft Word',
      showCancelButton: true,
      icon: 'info',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
        placeholder: 'nome do documento'
      }
    })

    if (dismiss) {
      return { name: undefined, executionArn: undefined }
    }

    toast('Gerando dossiê', {
      toastId: 'gerando-dossie',
      type: toast.TYPE.INFO,
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: false
    })

    const accessToken = await getIdToken()

    const DOCX_PROVIDER_NAME = 'DocxGeneratorStateMachine'
    const consumer = await SQSConsumer.getInstance({ accessToken })
    consumer.addProvider(DOCX_PROVIDER_NAME)
    const executionArn = await gerarDocumento({
      state: internalState,
      queueUrl,
      document,
      filename: name,
      discoveryStartedAt
    })

    if (!consumer.running) {
      await backOff(() => consumer.consume(), { startingDelay: 0 })
    }

    return { name, executionArn }
  } catch (err) {
    console.error(err)
    toast.error('Erro ao gerar dossiê!', {
      position: toast.POSITION.BOTTOM_RIGHT
    })
    toast.dismiss('gerando-dossie')
  }

  return { name: undefined, executionArn: undefined }
}

export default exportDoc
