import React, { useMemo } from 'react'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import ReactTooltip from 'react-tooltip'

import { AiFillAlert } from 'react-icons/ai'

import { hashObject } from '../utils'

import Table from './Table'

const { SearchBar } = Search

const onSortAlertas = (...args) => {
  const order = args[2]
  const a = args[4]?.rawAlertas || ''
  const b = args[5]?.rawAlertas || ''

  if (order === 'asc') {
    return a.length - b.length
  } else {
    return b.length - a.length
  }
}

const normalize = str => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

const ALERTAS = [
  'adjudicação de bens deferida',
  'deferimento de arresto de bens',
  'deferimento de penhora de bens',
  'leilão deferido',
  'edital de leilão expedido',
  'expedição de termo de penhora',
  'homologação de acordo',
  'homologação de cálculo'
].map(classificacao => normalize(classificacao.toLowerCase()))

const DBJusTable = ({
  data,
  scores,
  clickItemProcesso,
  probableVinculados,
  vinculadosMap,
  showScores,
  showPartes
}) => {
  const dataSubstring = 40

  const fixJustica = justica => {
    switch (justica) {
      case 'JUSTIÇA DO TRABALHO':
        return 'JT'
      case 'JUSTIÇA ESTADUAL':
        return 'JE'
      case 'JUSTIÇA FEDERAL':
        return 'JF'
      default:
        return justica
    }
  }

  const tipoPartes = useMemo(
    () =>
      Array.from(
        new Set(
          (data || [])
            .map(item =>
              item.partes.map(parte => parte.tipo.replace(/_/g, ' '))
            )
            .flat()
        )
      ),
    [data]
  )

  const columns = useMemo(() => {
    const defaultColumns1 = [
      {
        dataField: 'alertas',
        text: 'Alertas',
        sort: true,
        sortFunc: onSortAlertas
      },
      {
        dataField: 'rawAlertas',
        hidden: true
      },
      {
        dataField: 'nup',
        text: 'NUP',
        sort: true
      },
      {
        dataField: 'estado',
        text: 'UF',
        sort: true
      },
      {
        dataField: 'justica',
        text: 'Jus.',
        sort: true
      },
      {
        dataField: 'classes',
        text: 'Classes',
        sort: true
      },
      {
        dataField: 'assuntos',
        text: 'Assuntos',
        sort: true
      },
      {
        dataField: 'valor',
        text: 'Valor',
        sort: true
      }
    ]

    const partesColumns = showPartes
      ? tipoPartes.map(item => ({
          dataField: item,
          text: item
        }))
      : []

    const defaultColumns2 = [
      showScores && {
        dataField: 'scorePf',
        text: 'Score PF',
        sort: true
      },
      showScores && {
        dataField: 'scorePj',
        text: 'Score PJ',
        sort: true
      },
      {
        dataField: 'actions',
        text: '',
        searchable: false
      }
    ].filter(Boolean)

    const groupedColumns = [
      ...defaultColumns1,
      ...partesColumns,
      ...defaultColumns2
    ]

    return groupedColumns.map(item => ({
      ...item,
      align: 'center',
      headerAlign: 'center'
    }))
  }, [tipoPartes, showScores, showPartes])

  const mappedData = useMemo(() => {
    return (data || []).map(item => {
      const nup = item.nup.replace(/\D/g, '')
      const id = hashObject(item)

      const alertas = Array.from(
        new Set(
          (item.andamentos || []).map(andamento => andamento.classificacao)
        )
      )
        .filter(Boolean)
        .filter(classificacao =>
          ALERTAS.includes(normalize(classificacao.toLowerCase()))
        )
        .join('; ')
      const partesObj = tipoPartes.reduce((obj, tipo) => {
        const tipoOriginal = tipo.split(' ').join('_')
        const partes = item.partes
          .filter(parte => parte.tipo === tipoOriginal)
          .map(parte => parte.nome)
          .join('; ')
          .substr(0, dataSubstring)

        obj[tipo] = partes
        return obj
      }, {})

      const scorePf = scores?.parental?.[nup]?.toFixed(2) || '-'
      const scorePj = scores?.societario?.[nup]?.toFixed(2) || '-'

      const processosVinculados = (vinculadosMap[nup] || [])
        .map(item => probableVinculados.find(p => p.nup === item))
        .filter(Boolean)

      const classes = item.classes || []
      const filteredClasses =
        classes.length > 2
          ? classes.slice(0, 2).join('; ') + ' ...'
          : classes.join('; ')

      return {
        id,
        alertas:
          alertas.length > 0 ? (
            <div data-tip={alertas}>
              <AiFillAlert />
            </div>
          ) : (
            <div />
          ),
        rawAlertas: alertas,
        nup: item.nup,
        estado: item.estado,
        justica: fixJustica(item.justica),
        classes: filteredClasses,
        assuntos:
          item.assuntos.length > dataSubstring
            ? item.assuntos.substr(0, dataSubstring) + '...'
            : item.assuntos,
        valor: item.valor || '-',
        ...partesObj,
        scorePf,
        scorePj,
        processosVinculados,
        actions: (
          <button
            type='button'
            className='btn btn-primary btn-sm'
            style={{ width: '100px' }}
            onClick={() => clickItemProcesso(item)}
          >
            Ver detalhes
          </button>
        )
      }
    })
  }, [
    data,
    tipoPartes,
    scores,
    vinculadosMap,
    probableVinculados,
    clickItemProcesso
  ])

  const nonExpandable = useMemo(() => {
    return (mappedData || [])
      .filter(item => item.processosVinculados.length === 0)
      .map(item => item.id)
  }, [mappedData])

  const expandRow = {
    expandColumnRenderer: ({ expanded, expandable }) => {
      if (expanded) {
        return <b>-</b>
      }
      if (!expandable) {
        return null
      }

      return <b>+</b>
    },
    showExpandColumn: true,
    nonExpandable,
    renderer: row => {
      const processosVinculados = row.processosVinculados || []

      const mappedProcessosVinculados = processosVinculados.map(item => {
        const nup = item.nup.replace(/\D/g, '')
        const id = hashObject(item)

        const partesObj = tipoPartes.reduce((obj, tipo) => {
          const partes = item.partes
            .filter(parte => parte.tipo === tipo)
            .map(parte => parte.nome)
            .join('; ')
            .substr(0, dataSubstring)

          obj[tipo] = partes
          return obj
        }, {})

        const scorePf = scores?.parental?.[nup]?.toFixed(2) || '-'
        const scorePj = scores?.societario?.[nup]?.toFixed(2) || '-'

        const classes = item.classes || []
        const filteredClasses =
          classes.length > 2
            ? classes.slice(0, 2).join('; ') + ' ...'
            : classes.join('; ')

        return {
          id,
          nup: item.nup,
          estado: item.estado,
          justica: fixJustica(item.justica),
          classes: filteredClasses,
          assuntos:
            item.assuntos.length > dataSubstring
              ? item.assuntos.substr(0, dataSubstring) + '...'
              : item.assuntos,
          valor: item.valor || '-',
          ...partesObj,
          scorePf,
          scorePj,
          actions: (
            <button
              type='button'
              className='btn btn-primary btn-sm'
              style={{ width: '100px' }}
              onClick={() => clickItemProcesso(item)}
            >
              Ver detalhes
            </button>
          )
        }
      })
      return (
        <>
          {mappedProcessosVinculados.map(item => {
            const keys = Object.keys(item).filter(
              key => !['id', 'processosVinculados'].includes(key)
            )

            return (
              <tr key={item.id}>
                <td />
                {keys.map(key => (
                  <td key={key} style={{ textAlign: 'center' }}>
                    {item[key]}
                  </td>
                ))}
              </tr>
            )
          })}
        </>
      )
    }
  }

  return (
    <>
      <ReactTooltip />
      <ToolkitProvider
        keyField='id'
        search
        columns={columns}
        data={mappedData}
        expandRow={expandRow}
      >
        {props => (
          <>
            <div className='col-12 text-right'>
              <SearchBar {...props.searchProps} placeholder='Pesquisar' />
            </div>
            <div className='col-12'>
              <Table enablePagination {...props.baseProps} />
            </div>
          </>
        )}
      </ToolkitProvider>
    </>
  )
}

export default DBJusTable
