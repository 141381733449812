// Action Types
export const Types = {
  RESET_RELATEDS: 'RELATEDS/RESET',
  SET_RELATEDS: 'RELATEDS/SET_RELATEDS',
  SET_ERROR_RELATEDS: 'RELATEDS/SET_ERROR_RELATEDS'
}

// Reducer
export const initialState = {
  data: {
    relateds: [],
    relatedsKnownFiliais: [],
    relatedsMissingDoc: [],
    target: {}
  },
  error: false,
  loaded: false
}

export default function reducer (state = initialState, action) {
  switch (action.type) {
    case Types.SET_RELATEDS:
      return {
        ...state,
        data: action.payload,
        loaded: true,
        error: false
      }
    case Types.SET_ERROR_RELATEDS:
      return { ...state, error: true }
    case Types.RESET_RELATEDS:
      return {
        ...initialState
      }
    default:
      return state
  }
}

// Action Creators
export function resetRelateds () {
  return {
    type: Types.RESET_RELATEDS
  }
}

export function setRelateds (data) {
  return {
    type: Types.SET_RELATEDS,
    payload: data
  }
}

export function setErrorRelateds () {
  return {
    type: Types.SET_ERROR_RELATEDS
  }
}
