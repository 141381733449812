import React from 'react'

import { Sidebar, Content } from '../../../components'

export default function RF () {
  return (
    <div className='container-fluid h-100'>
      <div className='row justify-content-between h-100'>
        <Sidebar />
        <Content title='Receita Federal' subtitle='Placeholder' />
      </div>
    </div>
  )
}
