import {
  resolveProgressStatus,
  isError,
  isLoaded
} from '../../lib/progressStatus'

// Action Types
export const Types = {
  RESET: 'DISCREDITING_MEDIA_THEME/RESET',
  SET_S3_PATH: 'DISCREDITING_MEDIA_THEME/SET_S3_PATH',
  SET_ERROR: 'DISCREDITING_MEDIA_THEME/SET_ERROR'
}

// Reducer
const initialState = {
  discreditingNews: {
    s3Path: undefined,
    error: false,
    loaded: false
  }
}

export default function reducer (state = initialState, action) {
  switch (action.type) {
    case Types.SET_S3_PATH: {
      const statusDiscreditingNews = resolveProgressStatus(
        action.payload.discreditingNews
      )

      return {
        ...state,
        discreditingNews: {
          ...state.discreditingNews,
          s3Path: action.payload.discreditingNews,
          loaded: isLoaded(statusDiscreditingNews),
          error: isError(statusDiscreditingNews)
        }
      }
    }
    case Types.SET_ERROR:
      return {
        ...state,
        discreditingNews: {
          ...state.discreditingNews,
          error: action.payload,
          loaded: true
        }
      }
    case Types.RESET:
      return initialState
    default:
      return state
  }
}

// Action Creators
export function setErrorDiscreditingMedia (data) {
  return {
    type: Types.SET_ERROR,
    payload: data
  }
}

export function setS3PathDiscreditingMedia ({ discreditingNews }) {
  return {
    type: Types.SET_S3_PATH,
    payload: { discreditingNews }
  }
}

export function resetDiscreditingMediaTheme () {
  return {
    type: Types.RESET
  }
}
