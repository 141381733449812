import {
  resolveProgressStatus,
  isError,
  isLoaded
} from '../../lib/progressStatus'

// Action Types
export const Types = {
  RESET: 'PATRIMONIO_THEME/RESET',
  SET_S3_PATH: 'PATRIMONIO_THEME/SET_S3_PATH',
  SET_ERROR: 'PATRIMONIO_THEME/SET_ERROR'
}

// Reducer
const initialState = {
  aeronaves: {
    s3Path: undefined,
    error: false,
    loaded: false
  },
  ibama: {
    s3Path: undefined,
    error: false,
    loaded: false
  },
  sigef: {
    s3Path: undefined,
    error: false,
    loaded: false
  },
  sncr: {
    s3Path: undefined,
    error: false,
    loaded: false
  }
}

export default function reducer (state = initialState, action) {
  switch (action.type) {
    case Types.SET_S3_PATH: {
      const statusAeronaves = resolveProgressStatus(action.payload.aeronaves)
      const statusSncr = resolveProgressStatus(action.payload.sncr)
      const statusSigef = resolveProgressStatus(action.payload.sigef)
      const statusIbama = resolveProgressStatus(action.payload.ibama)
      const statusInpi = resolveProgressStatus(action.payload.inpi)

      return {
        ...state,
        aeronaves: {
          ...state.aeronaves,
          s3Path: action.payload.aeronaves,
          loaded: isLoaded(statusAeronaves),
          error: isError(statusAeronaves)
        },
        sigef: {
          ...state.sigef,
          s3Path: action.payload.sigef,
          loaded: isLoaded(statusSigef),
          error: isError(statusSigef)
        },
        ibama: {
          ...state.ibama,
          s3Path: action.payload.ibama,
          loaded: isLoaded(statusIbama),
          error: isError(statusIbama)
        },
        sncr: {
          ...state.sncr,
          s3Path: action.payload.sncr,
          loaded: isLoaded(statusSncr),
          error: isError(statusSncr)
        },
        inpi: {
          ...state.inpi,
          s3Path: action.payload.inpi,
          loaded: isLoaded(statusInpi),
          error: isError(statusInpi)
        }
      }
    }
    case Types.SET_ERROR:
      return {
        ...state,
        aeronaves: {
          ...state.aeronaves,
          s3Path: action.payload.aeronaves,
          error: true,
          loaded: true
        },
        ibama: {
          ...state.ibama,
          s3Path: action.payload.ibama,
          error: true,
          loaded: true
        },
        sncr: {
          ...state.sncr,
          s3Path: action.payload.sncr,
          error: true,
          loaded: true
        },
        inpi: {
          ...state.inpi,
          s3Path: action.payload.inpi,
          error: true,
          loaded: true
        }
      }
    case Types.RESET:
      return initialState
    default:
      return state
  }
}

// Action Creators
export function setErrorPatrimonio (data) {
  return {
    type: Types.SET_ERROR,
    payload: data
  }
}

export function setS3PathPatrimonio ({ aeronaves, sncr, sigef, ibama, inpi }) {
  return {
    type: Types.SET_S3_PATH,
    payload: { aeronaves, sncr, sigef, ibama, inpi }
  }
}

export function resetPatrimonioTheme () {
  return {
    type: Types.RESET
  }
}
