import { combineReducers } from 'redux'

import grafoReducer from './grafo'
import googleMapsReducer from './googleMaps'
import matchesReducer from './matches'

import offshoreThemeReducer from '../store/offshoreTheme'
import operacionaisThemeReducer from '../store/operacionaisTheme'
import logsulThemeReducer from '../store/logsulTheme'
import dadosBasicosThemeReducer from '../store/dadosBasicosTheme'
import patrimonioThemeReducer from '../store/patrimonioTheme'
import globalStepThemeReducer from '../store/globalStep'
import endividamentoThemeReducer from '../store/endividamentoTheme'
import juridicoThemeReducer from '../store/juridicoTheme'
import docReducer from '../store/doc'
import progressStatusReducer from '../store/progressStatus'
import relatedsReducer from '../store/relateds'
import bandeiraAmarelaThemeReducer from '../store/bandeiraAmarelaTheme'
import spreadThemeReducer from '../store/spreadTheme'
import matchGrupoSocietarioThemeReducer from '../store/matchGrupoSocietarioTheme'
import visitasThemeReducer from '../store/visitasTheme'
import riscoJuridicoThemeReducer from '../store/riscoJuridicoTheme'
import discreditingMediaThemeReduce from '../store/discreditingMediaTheme'

import sulbrasilReducer from '../store/sulbrasil'

const rootReducer = combineReducers({
  grafo: grafoReducer,
  googleMaps: googleMapsReducer,
  matches: matchesReducer,

  offshoreTheme: offshoreThemeReducer,
  dadosBasicosTheme: dadosBasicosThemeReducer,
  operacionaisTheme: operacionaisThemeReducer,
  patrimonioTheme: patrimonioThemeReducer,
  endividamentoTheme: endividamentoThemeReducer,
  globalStep: globalStepThemeReducer,
  bandeiraAmarelaTheme: bandeiraAmarelaThemeReducer,
  juridicoTheme: juridicoThemeReducer,
  spreadTheme: spreadThemeReducer,
  visitasTheme: visitasThemeReducer,
  logsulTheme: logsulThemeReducer,
  doc: docReducer,
  progressStatus: progressStatusReducer,
  matchGrupoSocietarioTheme: matchGrupoSocietarioThemeReducer,
  relateds: relatedsReducer,
  riscoJuridicoTheme: riscoJuridicoThemeReducer,
  discreditingMediaTheme: discreditingMediaThemeReduce,

  sulbrasil: sulbrasilReducer
})

export default rootReducer
