import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ProgressBar } from 'react-step-progress-bar'
import ReactTooltip from 'react-tooltip'

import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs'
import 'react-step-progress-bar/styles.css'

import { factoryDate, formatDate } from './../utils'

const PAGE_SIZE = 5

const DetailsTimeline = props => {
  const { timeline } = props
  const [arrayPageActual, setArrayPageActual] = useState(1)
  const [arrayTotalPages, setArrayTotalPages] = useState(1)

  const arrayPorcentage = useMemo(() => {
    const total = parseFloat((arrayPageActual / arrayTotalPages) * 100).toFixed(
      0
    )
    if (arrayPageActual === arrayTotalPages) {
      return 100
    } else if (arrayPageActual === 1) {
      return 0
    } else {
      return parseInt(total)
    }
  }, [arrayPageActual, arrayTotalPages])

  const arrayPaginate = useCallback(
    pageNumber => {
      const internalTimeline = timeline || []
      const max = Math.min(pageNumber * PAGE_SIZE, internalTimeline.length)
      const min = Math.max(0, (pageNumber - 1) * PAGE_SIZE)

      return (timeline || []).slice(min, max)
    },
    [timeline]
  )

  useEffect(() => {
    setArrayPageActual(1)

    const totalPagesTemp = parseFloat(
      (timeline || []).length / PAGE_SIZE
    ).toFixed(0)

    setArrayTotalPages(parseInt(totalPagesTemp))
  }, [timeline])

  const paginateGo = useCallback(
    type => {
      if (type === 'foward') {
        if (arrayPageActual < arrayTotalPages) {
          setArrayPageActual(arrayPageActual + 1)
        }
      } else {
        if (arrayPageActual > 0) {
          setArrayPageActual(arrayPageActual - 1)
        }
      }
      arrayPaginate(arrayPageActual)
    },
    [arrayPageActual, arrayTotalPages, arrayPaginate]
  )

  return (
    <div className='row justify-content-start timeline-base col-12 no-gutters'>
      <ReactTooltip />

      <div className='timeline-content col-12 row justify-content-start no-gutters'>
        {arrayPaginate(arrayPageActual).map((item, key) => {
          return (
            <div className='timeline-item col-auto' key={key}>
              <div className='timeline-container'>
                <div className='timeline-classificacao'>
                  {item.classificacao || '-'}
                </div>
                <div className='timeline-desc'>{item.content}</div>
                <div className='timeline-date'>
                  {formatDate(factoryDate(item.date))}
                </div>
              </div>
              <div className='timeline-line'>
                {/* <AiOutlineDash size={20} /> */}
                {/* <span className="timeline-circle" /> */}
                {/* <AiOutlineDash size={20} /> */}
              </div>
            </div>
          )
        })}
      </div>

      <div className='timeline-toolbar col-12 row justify-content-center no-gutters'>
        <div className='col-10' style={{ marginTop: '30px' }}>
          <ProgressBar
            percent={arrayPorcentage}
            filledBackground='linear-gradient(to right, #00b07d, #9aca3c)'
          />
        </div>
        <div className='col-2 text-center' style={{ marginTop: '10px' }}>
          <div className='btn-group' role='group'>
            <button
              className='btn btn-outline-dark btnTimeline'
              data-tip='Voltar Andamentos'
              onClick={() => paginateGo('back')}
              disabled={arrayPageActual === 1}
            >
              <BsArrowLeftShort size={20} />
            </button>

            <button
              className='btn btn-outline-dark btnTimeline'
              data-tip='Avançar Andamentos'
              onClick={() => paginateGo('foward')}
              disabled={arrayPageActual === arrayTotalPages}
            >
              <BsArrowRightShort size={20} />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DetailsTimeline
