const questionnaires = [
  {
    question:
      'Quadro societário alterado ou empresas constituídas em prazo inferior a 1 ano.',
    id: 'quadroSocietarioAlterado',
    answers: [
      { text: 'Sim', value: 'sim' },
      { text: 'Não', value: 'nao' }
    ]
  },
  {
    question:
      'É representada de forma contumaz por terceiros, sem vínculo societário ou empregatício.',
    id: 'representadaContumaz',
    answers: [
      { text: 'Sim', value: 'sim' },
      { text: 'Não', value: 'nao' }
    ]
  },
  {
    question:
      'Os beneficiários finais não podem ser identificados ou a identificação é difícil ou onerosa.',
    id: 'beneficiariosFinais',
    answers: [
      { text: 'Sim', value: 'sim' },
      { text: 'Não', value: 'nao' }
    ]
  },
  {
    question:
      'A carteira de clientes é composta majoritariamente por entes da administração pública.',
    id: 'carteiraClientes',
    answers: [
      { text: 'Sim', value: 'sim' },
      { text: 'Não', value: 'nao' }
    ]
  },
  {
    question:
      'Mais de 70% das operações não são creditadas na sua própria conta corrente.',
    id: 'operacoesNaoCreditadas',
    answers: [
      { text: 'Sim', value: 'sim' },
      { text: 'Não', value: 'nao' }
    ]
  },
  {
    question:
      'A diligência para coleta de informações não pode ser completada.',
    id: 'diligenciaInformacoes',
    answers: [
      { text: 'Sim', value: 'sim' },
      { text: 'Não', value: 'nao' }
    ]
  },
  {
    question:
      'O quadro societário tem participação de pessoas politicamente expostas - PPE ou a elas relacionadas.',
    id: 'quadroSocietarioPPE',
    answers: [
      { text: 'Sim', value: 'sim' },
      { text: 'Não', value: 'nao' }
    ]
  },
  {
    question:
      'Empresas localizadas fora da praça em que é sediada a empresa de fomento num raio superior a 200 km de distância.',
    id: 'empresasLocalizadas',
    answers: [
      { text: 'Sim', value: 'sim' },
      { text: 'Não', value: 'nao' }
    ]
  },
  {
    question:
      'Empresa de qualquer forma relacionada a pessoa enquadrada em qualquer das condições previstas no art. 1º da Resolução do COAF no. 15, de 28.3.2007. (Bin Laden)',
    id: 'empresasRelacionadas',
    answers: [
      { text: 'Sim', value: 'sim' },
      { text: 'Não', value: 'nao' }
    ]
  },
  {
    question:
      'Representada ou tem participação acionária de pessoa domiciliada em jurisdição com deficiências estratégicas de prevenção à lavagem de dinheiro e ao financiamento do terrorismo ou região considerada de tributação favorecida.',
    id: 'lavagemDinheiro',
    answers: [
      { text: 'Sim', value: 'sim' },
      { text: 'Não', value: 'nao' }
    ]
  },
  {
    question:
      'Empresa cujo quadro societário seja composto por pessoa jurídica.',
    id: 'quadroSocietarioPessoaJuridica',
    answers: [
      { text: 'Sim', value: 'sim' },
      { text: 'Não', value: 'nao' }
    ]
  },
  {
    question: 'Empresas constituídas entre 1 a 3 anos.',
    id: 'empresasConstituidas',
    answers: [
      { text: 'Sim', value: 'sim' },
      { text: 'Não', value: 'nao' }
    ]
  },
  {
    question:
      'Empresas em que mais de 30% e menos de 70% de suas operações não são creditadas em sua própria conta corrente.',
    id: 'operacoesNaoCreditadas2',
    answers: [
      { text: 'Sim', value: 'sim' },
      { text: 'Não', value: 'nao' }
    ]
  }
]

export default questionnaires
