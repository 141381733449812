import { takeLatest } from 'redux-saga/effects'

import { Types as GrafoTypes } from '../reducers/grafo'
import { Types as DocTypes } from '../store/doc'
import { Types as GoogleMapsTypes } from '../reducers/googleMaps'
import { Types as MatchesTypes } from '../reducers/matches'

import { Types as LogSulThemeTypes } from '../store/logsulTheme'
import { Types as PatrimonioThemeTypes } from '../store/patrimonioTheme'
import { Types as DadosBasicosThemeTypes } from '../store/dadosBasicosTheme'
import { Types as OperacionaisThemeTypes } from '../store/operacionaisTheme'
import { Types as SulBrasilTypes } from '../store/sulbrasil'
import { Types as OffshoreThemeTypes } from '../store/offshoreTheme'
import { Types as EndividamentoThemeTypes } from '../store/endividamentoTheme'
import { Types as JuridicoThemeTypes } from '../store/juridicoTheme'
import { Types as GlobalStepTypes } from '../store/globalStep'
import { Types as BandeiraAmarelaThemeTypes } from '../store/bandeiraAmarelaTheme'
import { Types as SpreadThemeTypes } from '../store/spreadTheme'
import { Types as MatchGrupoSocietarioThemeTypes } from '../store/matchGrupoSocietarioTheme'
import { Types as VisitasThemeTypes } from '../store/visitasTheme'
import { Types as DiscreditingMediaThemeTypes } from '../store/discreditingMediaTheme'
import { Types as RiscoJuridicoThemeTypes } from '../store/riscoJuridicoTheme'

import { fetchAddress, fetchGoogleMaps } from './googleMaps'
import { fetchGrafoData } from './grafo'
import { listenerGerarDocumento, listenerSaveDocx } from './doc'
import { fetchGlobalStepName, startGlobalStep } from './globalStep'
import { listenerProgressStatus } from './progressStatus'
import { fetchRelateds } from './relateds'
import {
  changeReportStatusSulBrasil,
  generateReportSulBrasil,
  setStreetViewSulBrasil,
  listenerFinish
} from './sulbrasil'

function * mySaga () {
  // EVENTOS CONSULTA DOCUMENTO
  yield takeLatest(GlobalStepTypes.TROCAR_DOCUMENTO, startGlobalStep)

  // EVENTO DE FIM DE PROCESSAMENTO
  yield takeLatest(GlobalStepTypes.FINISHED, listenerFinish)

  // LEITURA DO NOME DO TARGET
  yield takeLatest(DadosBasicosThemeTypes.SET_S3_PATH, fetchGlobalStepName)

  // RELATEDS
  yield takeLatest(DadosBasicosThemeTypes.SET_S3_PATH, fetchRelateds)

  // GOOGLE MAPS TARGET
  yield takeLatest(GoogleMapsTypes.SET_ADDRESS, fetchGoogleMaps)
  yield takeLatest(DadosBasicosThemeTypes.SET_ERROR, fetchAddress)
  yield takeLatest(DadosBasicosThemeTypes.SET_S3_PATH, fetchAddress)

  // IMAGEM DO STREET VIEW FORMULÁRIO
  yield takeLatest(GoogleMapsTypes.SET_IMAGES, setStreetViewSulBrasil)

  // EVENTOS DO GRAFO
  yield takeLatest(GrafoTypes.SET_SELECTED_NODES, fetchGrafoData)
  yield takeLatest(GrafoTypes.SET_SELECTED_NODES, fetchGrafoData)

  // EVENTO PROGRESS STATUS
  yield takeLatest(GlobalStepTypes.FINISHED, listenerProgressStatus)
  yield takeLatest(GlobalStepTypes.SET_ERROR, listenerProgressStatus)
  yield takeLatest(GrafoTypes.SET_LINK, listenerProgressStatus)
  yield takeLatest(GrafoTypes.SET_ERROR_LINK, listenerProgressStatus)

  yield takeLatest(GoogleMapsTypes.SET_IMAGES, listenerProgressStatus)
  yield takeLatest(GoogleMapsTypes.SET_ERROR_IMAGES, listenerProgressStatus)
  yield takeLatest(MatchesTypes.SET_DATA, listenerProgressStatus)
  yield takeLatest(MatchesTypes.SET_ERROR, listenerProgressStatus)

  yield takeLatest(
    MatchGrupoSocietarioThemeTypes.SET_S3_PATH,
    listenerProgressStatus
  )
  yield takeLatest(
    MatchGrupoSocietarioThemeTypes.SET_ERROR,
    listenerProgressStatus
  )
  yield takeLatest(OperacionaisThemeTypes.SET_S3_PATH, listenerProgressStatus)
  yield takeLatest(OperacionaisThemeTypes.SET_ERROR, listenerProgressStatus)
  yield takeLatest(RiscoJuridicoThemeTypes.SET_S3_PATH, listenerProgressStatus)
  yield takeLatest(RiscoJuridicoThemeTypes.SET_ERROR, listenerProgressStatus)
  yield takeLatest(LogSulThemeTypes.SET_S3_PATH, listenerProgressStatus)
  yield takeLatest(LogSulThemeTypes.SET_ERROR, listenerProgressStatus)
  yield takeLatest(PatrimonioThemeTypes.SET_S3_PATH, listenerProgressStatus)
  yield takeLatest(PatrimonioThemeTypes.SET_ERROR, listenerProgressStatus)
  yield takeLatest(DadosBasicosThemeTypes.SET_S3_PATH, listenerProgressStatus)
  yield takeLatest(DadosBasicosThemeTypes.SET_ERROR, listenerProgressStatus)
  yield takeLatest(OffshoreThemeTypes.SET_S3_PATH, listenerProgressStatus)
  yield takeLatest(OffshoreThemeTypes.SET_ERROR, listenerProgressStatus)
  yield takeLatest(EndividamentoThemeTypes.SET_S3_PATH, listenerProgressStatus)
  yield takeLatest(EndividamentoThemeTypes.SET_ERROR, listenerProgressStatus)
  yield takeLatest(SpreadThemeTypes.SET_S3_PATH, listenerProgressStatus)
  yield takeLatest(SpreadThemeTypes.SET_ERROR, listenerProgressStatus)
  yield takeLatest(VisitasThemeTypes.SET_S3_PATH, listenerProgressStatus)
  yield takeLatest(VisitasThemeTypes.SET_ERROR, listenerProgressStatus)
  yield takeLatest(JuridicoThemeTypes.FINISHED, listenerProgressStatus)
  yield takeLatest(
    BandeiraAmarelaThemeTypes.SET_S3_PATH,
    listenerProgressStatus
  )
  yield takeLatest(BandeiraAmarelaThemeTypes.SET_ERROR, listenerProgressStatus)
  yield takeLatest(
    DiscreditingMediaThemeTypes.SET_ERROR,
    listenerProgressStatus
  )
  yield takeLatest(
    DiscreditingMediaThemeTypes.SET_S3_PATH,
    listenerProgressStatus
  )

  // GERAR DOCUMENTO DOCX
  yield takeLatest(DocTypes.GERAR_DOCUMENTO, listenerGerarDocumento)
  yield takeLatest(DocTypes.SAVE_DOCX, listenerSaveDocx)

  // GERAR RELATÓRIO SULBRASIL
  yield takeLatest(SulBrasilTypes.GERAR_RELATORIO, generateReportSulBrasil)

  // MUDA O STATUS DE SALVO QUANDO EDITA O FORMULÁRIO
  yield takeLatest(
    SulBrasilTypes.SET_SCORE_COMPORTAMENTAL,
    changeReportStatusSulBrasil
  )
  yield takeLatest(SulBrasilTypes.SET_SCORE_PLD, changeReportStatusSulBrasil)
  yield takeLatest(
    SulBrasilTypes.SET_SCORE_PROSPECCAO,
    changeReportStatusSulBrasil
  )
  yield takeLatest(SulBrasilTypes.SET_FATURAMENTO, changeReportStatusSulBrasil)
  yield takeLatest(SulBrasilTypes.SET_IMAGENS, changeReportStatusSulBrasil)
  yield takeLatest(
    SulBrasilTypes.SET_DADOS_OPERACIONAIS,
    changeReportStatusSulBrasil
  )
}

export default mySaga
