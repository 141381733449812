import React from 'react'

import { logout } from '../../utils'

import * as styles from './style.module.scss'
import logo from '../../assets/img/logo/boa-nota/full_black.svg'

import iconLogout from '../../assets/img/icons/logout.svg'

export default function Header () {
  return (
    <header className={styles.container}>
      <div className='d-flex flex-row justify-content-center px-4 py-2'>
        <div className='d-flex flex-grow-1 align-items-center'>
          <img src={logo} alt='Logo Boa nota' height={40} />
        </div>
        <div className='d-flex align-items-center'>
          <button className='btn' onClick={() => logout()}>
            <img src={iconLogout} alt='Logout' height={25} />
          </button>
        </div>
      </div>
      <Divisor />
    </header>
  )
}

const Divisor = () => {
  return <div className={styles.divisor} />
}
