import React from 'react'
import { Carousel } from 'react-bootstrap'

export default function GoogleMapsImages ({ data, width }) {
  const internalWidth = width || '460px'

  return (
    <Carousel style={{ maxWidth: internalWidth }}>
      {data.images.map((item, index) => {
        return (
          <Carousel.Item key={index}>
            <img
              alt='Imagem do google maps'
              className='d-block'
              style={{ maxWidth: internalWidth, objectFit: 'contain' }}
              src={item}
            />
          </Carousel.Item>
        )
      })}
    </Carousel>
  )
}
