import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'

import { Table } from '../../../../../components'
import { onSortDocument } from '../../../../../components/Table/sort'

import {
  CEP_FORMATTER,
  formatDocument,
  hashObject,
  isValid,
  onlyNumbers,
  PHONE_FORMATTER
} from '../../../../../utils'

const { SearchBar } = Search

const tableSchema = {
  telefones: {
    cpf: [
      {
        dataField: 'ddd',
        text: 'DDD',
        sort: true
      },
      {
        dataField: 'numero',
        text: 'Número',
        sort: true
      },
      {
        dataField: 'operadora',
        text: 'Operadora',
        sort: true
      },
      {
        dataField: 'isVinculatedFacebook',
        text: 'Vinculado ao Facebook',
        sort: true
      },
      {
        dataField: 'isVinculatedWhatsapp',
        text: 'Vinculado ao WhatsApp',
        sort: true
      },
      {
        dataField: 'isBlockedProcon',
        text: 'Bloqueado no Procon',
        sort: true
      },
      {
        dataField: 'movel',
        text: 'Móvel',
        sort: true
      }
    ],
    cnpj: [
      {
        dataField: 'ddd',
        text: 'DDD',
        sort: true
      },
      {
        dataField: 'numero',
        text: 'Número',
        sort: true
      },
      {
        dataField: 'movel',
        text: 'Móvel',
        sort: true
      }
    ]
  },
  enderecos: {
    cpf: [
      {
        dataField: 'name',
        text: 'Nome',
        sort: true
      },
      {
        dataField: 'document',
        text: 'Doc.',
        sort: true,
        sortFunc: onSortDocument,
        searchable: false
      },
      {
        dataField: 'logradouro',
        text: 'Logradouro',
        sort: true
      },
      {
        dataField: 'numero',
        text: 'Número'
      },
      {
        dataField: 'bairro',
        text: 'Bairro',
        sort: true
      },
      {
        dataField: 'municipio',
        text: 'Município',
        sort: true
      },
      {
        dataField: 'uf',
        text: 'UF',
        sort: true
      },
      {
        dataField: 'cep',
        text: 'CEP',
        sort: true
      },
      {
        dataField: 'geolocalizacao',
        text: 'Geolocalização',
        sort: true
      },
      {
        dataField: 'rawDocumento',
        hidden: true
      }
    ],
    cnpj: [
      {
        dataField: 'name',
        text: 'Nome',
        sort: true
      },
      {
        dataField: 'document',
        text: 'Doc.',
        sort: true,
        sortFunc: onSortDocument,
        searchable: false
      },
      {
        dataField: 'logradouro',
        text: 'Logradouro',
        sort: true
      },
      {
        dataField: 'numero',
        text: 'Número'
      },
      {
        dataField: 'bairro',
        text: 'Bairro',
        sort: true
      },
      {
        dataField: 'municipio',
        text: 'Município',
        sort: true
      },
      {
        dataField: 'uf',
        text: 'UF',
        sort: true
      },
      {
        dataField: 'cep',
        text: 'CEP',
        sort: true
      },
      {
        dataField: 'geolocalizacao',
        text: 'Geolocalização',
        sort: true
      },
      {
        dataField: 'rawDocumento',
        hidden: true
      }
    ]
  },
  emails: {
    cpf: [
      {
        dataField: 'name',
        text: 'Nome',
        sort: true
      },
      {
        dataField: 'document',
        text: 'Doc.',
        sort: true,
        sortFunc: onSortDocument,
        searchable: false
      },
      {
        dataField: 'email',
        text: 'Email',
        sort: true
      },
      {
        dataField: 'isFacebook',
        text: 'Vinculado ao Facebook',
        sort: true
      },
      {
        dataField: 'isLinkedin',
        text: 'Vinculado ao LinkedIn',
        sort: true
      },
      {
        dataField: 'isValidated',
        text: 'Email validado',
        sort: true
      },
      {
        dataField: 'domain',
        text: 'Dominío',
        sort: true
      },
      {
        dataField: 'rawDocumento',
        hidden: true
      }
    ],
    cnpj: [
      {
        dataField: 'name',
        text: 'Nome',
        sort: true
      },
      {
        dataField: 'document',
        text: 'Doc.',
        sort: true,
        sortFunc: onSortDocument,
        searchable: false
      },
      {
        dataField: 'email',
        text: 'Email',
        sort: true
      },
      {
        dataField: 'domain',
        text: 'Dominío',
        sort: true
      },
      {
        dataField: 'rawDocumento',
        hidden: true
      }
    ]
  }
}

export default function Contatos ({ isCPF, data, error }) {
  const [selectedTable, setSelectedTable] = useState('enderecos')

  if (error) {
    return <div>Erro no carregamento dos dados</div>
  }

  const documentTypeSelector = isCPF ? 'cpf' : 'cnpj'

  const telefones = isCPF
    ? data?.telefones || []
    : [
        data?.dadosBasicos?.telefone1,
        data?.dadosBasicos?.telefone2,
        data?.dadosBasicos?.fax
      ]
        .filter(Boolean)
        .filter((current, index, array) => array.indexOf(current) === index)
        .map(item => {
          const value = item || ''
          const ddd = value.slice(0, 2)
          const numero = value.slice(2, 10)
          const firstNumber = parseInt(value.slice(2, 3))
          const isMobile = isNaN(firstNumber)
            ? '-'
            : firstNumber >= 6 // https://pt.stackoverflow.com/questions/14343/como-diferenciar-tipos-de-telefone
            ? 'Sim'
            : 'Não'

          return {
            ddd,
            numero,
            movel: isMobile
          }
        }) || []

  const enderecos = data?.enderecos || []

  const emails = data?.emails || []

  if (enderecos.length === 0 && telefones.length === 0 && emails.length === 0) {
    return <div />
  }

  const tableData =
    selectedTable === 'telefones'
      ? telefones.filter(item => item.numero)
      : selectedTable === 'enderecos'
      ? enderecos.filter(item => item.logradouro)
      : emails.filter(item => item.email)

  const selectedColumns = tableSchema[selectedTable][documentTypeSelector]
  const columns = selectedColumns.map(item => ({
    ...item,
    align: 'center',
    headerAlign: 'center'
  }))

  const mappedData = (tableData || []).map((item, index) => {
    const id = hashObject({ item, index })

    if (selectedTable === 'enderecos') {
      return {
        ...item,
        id,
        document: item.document ? (
          <a
            href={`?documento=${onlyNumbers(item.document)}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            {formatDocument(item.document)}
          </a>
        ) : (
          '-'
        ),
        rawDocumento: formatDocument(item.document),
        logradouro:
          item.tipoLogradouro + ' ' + item.logradouro || 'Desconhecido',
        cep: CEP_FORMATTER(item.cep),
        geolocalizacao: isValid(item.geo?.lat)
          ? `Lat: ${item.geo?.lat} - Lng: ${item.geo?.long}`
          : 'Desconhecida'
      }
    }

    if (selectedTable === 'telefones') {
      if (isCPF) {
        return {
          id,
          ddd: item.ddd,
          numero: PHONE_FORMATTER(`${item.numero}`),
          isBlockedProcon: item.isBlockedProcon ? 'Sim' : 'Não',
          isVinculatedFacebook: item.isVinculatedFacebook ? 'Sim' : 'Não',
          isVinculatedWhatsapp: item.isVinculatedWhatsapp ? 'Sim' : 'Não',
          operadora: item.operadora,
          movel: item.isMobile ? 'Sim' : 'Não'
        }
      }

      return {
        id,
        ddd: item.ddd,
        numero: PHONE_FORMATTER(`${item.numero}`),
        movel: item.movel
      }
    }

    if (selectedTable === 'emails') {
      if (isCPF) {
        return {
          id,
          name: item.name,
          document: item.document ? (
            <a
              href={`?documento=${onlyNumbers(item.document)}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              {formatDocument(item.document)}
            </a>
          ) : (
            '-'
          ),
          rawDocumento: formatDocument(item.document),
          email: item.email,
          isFacebook: item.isFacebook ? 'Sim' : 'Não',
          isLinkedin: item.isLinkedin ? 'Sim' : 'Não',
          isValidated: item.isValidated ? 'Sim' : 'Não',
          domain: item.domain
        }
      }

      return {
        id,
        name: item.name,
        document: item.document ? (
          <a
            href={`?documento=${onlyNumbers(item.document)}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            {formatDocument(item.document)}
          </a>
        ) : (
          '-'
        ),
        rawDocumento: formatDocument(item.document),
        email: item.email,
        domain: (item.email || '').split('@')?.[1] || ''
      }
    }

    throw new Error('Invalid table')
  })

  return (
    <ToolkitProvider keyField='id' data={mappedData} columns={columns} search>
      {props => (
        <>
          <div className='row mb-2'>
            <div className='col-12 col-lg-6 d-flex flex-row gap-3 mb-3'>
              <Form.Check
                type='radio'
                label='Endereços'
                onChange={() => setSelectedTable('enderecos')}
                checked={selectedTable === 'enderecos'}
                disabled={error}
              />
              <Form.Check
                type='radio'
                label='Telefones'
                onChange={() => setSelectedTable('telefones')}
                checked={selectedTable === 'telefones'}
                disabled={error}
              />
              <Form.Check
                type='radio'
                label='Emails'
                onChange={() => setSelectedTable('emails')}
                checked={selectedTable === 'emails'}
                disabled={error}
              />
            </div>
            <div className='col-12 col-lg-6 text-lg-right'>
              <SearchBar {...props.searchProps} placeholder='Pesquisar' />
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <Table {...props.baseProps} enablePagination />
            </div>
          </div>
        </>
      )}
    </ToolkitProvider>
  )
}
