import Pica from 'pica'
import jo from 'jpeg-autorotate'
import Axios from 'axios'

const resizerMode = {
  js: true,
  wasm: true,
  cib: true,
  ww: true
}

const opts = []

Object.keys(resizerMode).forEach(function (k) {
  if (resizerMode[k]) opts.push(k)
})

const pica = Pica({
  features: opts
})

export const readFile = async file => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()

    fileReader.onload = event => resolve(event.target.result)
    fileReader.onabort = event => reject(event)
    fileReader.onerror = event => reject(event)
    fileReader.readAsDataURL(file)
  })
}

export const base64ToImage = async (base64, width, height) => {
  const image = new Image()
  image.src = base64

  if (width) {
    image.width = width
  }

  if (height) {
    image.heigh = height
  }

  return new Promise((resolve, reject) => {
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', err => reject(err))
  })
}

function resolveDimensions (dimensions) {
  const aspectRatio = dimensions.width / dimensions.height

  if (aspectRatio >= 1) {
    const width = Math.min(dimensions.width, 768)
    const height = width / aspectRatio
    return { width, height }
  } else {
    const height = Math.min(dimensions.height, 768)
    const width = height * aspectRatio
    return { width, height }
  }
}

async function canvasToJpegBase64 (canvas) {
  const blob = await pica.toBlob(canvas, 'image/jpeg')
  return readFile(blob)
}

export const compressImageAndAutoResize = async image => {
  const { width, height } = image

  const newDimensions = resolveDimensions({ width, height })
  const canvas = document.createElement('canvas')

  canvas.width = newDimensions.width
  canvas.height = newDimensions.height

  await pica.resize(image, canvas)

  return canvasToJpegBase64(canvas)
}

export const base64MimeType = encoded => {
  if (typeof encoded !== 'string') {
    return null
  }

  const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/)

  if (mime && mime.length) {
    return mime[1]
  } else {
    return null
  }
}

export const autoRotate = async image => {
  const type = base64MimeType(image.src)

  if (type !== 'image/jpeg') {
    return image
  }

  const fileBuffer = Buffer.from(
    image.src.replace(/^data:.+;base64,/, ''),
    'base64'
  )
  return jo
    .rotate(fileBuffer, {})
    .then(({ buffer, dimensions }) => {
      const decoded = 'data:image/jpeg;base64,' + buffer.toString('base64')
      return base64ToImage(decoded, dimensions.width, dimensions.height)
    })
    .catch(() => {
      return image
    })
}

export const fetchBase64 = async url => {
  return Axios.get(url, {
    responseType: 'arraybuffer'
  }).then(response => Buffer.from(response.data, 'binary').toString('base64'))
}
