import React from 'react'
import { BiBuildings } from 'react-icons/bi'
import { useSelector } from 'react-redux'

import {
  CNPJ_FORMATTER,
  resolveNaturezaJuridica,
  RETURN_DATA2
} from '../../utils'

export default function RenderOthers ({ selectedNode, onClick, dictKey }) {
  const { group, label, metadata } = selectedNode.node
  const values = useSelector(state => state.grafo.values)
  const data = values[dictKey]

  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })

  const imagensDoGoogle = data?.googleImages

  return (
    <div className='grafoDetailsRoot'>
      <div className='grafoDetailsHeader'>
        <div className='grafoDetailsIcon'>
          <BiBuildings
            size={40}
            style={
              group === 'INAPTA'
                ? { color: 'red' }
                : group === 'BAIXADA'
                ? { color: '#00000050' }
                : { color: '#001e64' }
            }
          />
        </div>
        <div className='grafoDetailsHeaderContent'>
          <h2
            style={
              group === 'inapta'
                ? { color: 'red' }
                : group === 'BAIXADA'
                ? { color: '#00000050' }
                : { color: '#001e64' }
            }
          >
            {label}
          </h2>
          <h6
            className='grafoDetailsCNPJ'
            style={
              group === 'inapta'
                ? { color: 'red' }
                : group === 'BAIXADA'
                ? { color: '#00000050' }
                : { color: '#001e64' }
            }
          >
            {CNPJ_FORMATTER(metadata.cnpj)}
          </h6>
        </div>
      </div>
      <div className='grafoPJ'>
        <div className='grafoDetailsContent'>
          <span className='label'>Capital Social</span>
          <span className='value'>
            {formatter.format(data.empresa.capitalSocial)}
          </span>
        </div>

        <div className='grafoDetailsContent'>
          <span className='label'>Nome Fantasia</span>
          <span className='value'>{data.estabelecimento.nomeFantasia}</span>
        </div>

        <div className='grafoDetailsContent'>
          <span className='label'>Razão Social</span>
          <span className='value'>{data.empresa.razaoSocial}</span>
        </div>

        <div className='grafoDetailsContent'>
          <span className='label'>CNAE Fiscal</span>
          <span className='value'>
            {data.estabelecimento.cnaeFiscalPrincipal}
          </span>
        </div>

        <div className='grafoDetailsContent'>
          <span className='label'>Início das Atividades</span>
          <span className='value'>
            {RETURN_DATA2(data.estabelecimento.dataInicioAtividade)}
          </span>
        </div>

        <div className='grafoDetailsContent'>
          <span className='label'>Porte da Empresa</span>
          <span className='value'>{data.empresa.porteEmpresa}</span>
        </div>

        <div className='grafoDetailsContent'>
          <span className='label'>Cód. Natureza Jurídica</span>
          <span className='value'>
            {resolveNaturezaJuridica(data.empresa.naturezaJuridica)
              ? `${data.empresa.naturezaJuridica} - ${resolveNaturezaJuridica(
                  data.empresa.naturezaJuridica
                )} `
              : null}
          </span>
        </div>

        <div className='grafoDetailsDivisor'>
          <span>Endereço</span>
        </div>

        <div className='grafoDetailsContent'>
          <span className='endereco'>
            {(data.estabelecimento.tipoLogradouro ?? '') +
              ' ' +
              data.estabelecimento.logradouro +
              ', ' +
              data.estabelecimento.numero +
              ' - ' +
              data.estabelecimento.bairro +
              ', ' +
              data.estabelecimento.uf}
          </span>
        </div>
        <div className='grafoDetailsDivisor'>
          <span>Recuperação Judicial</span>
          <p>{data?.isRj ? 'Em recuperação judicial' : 'Nada consta'}</p>
        </div>
        <div className='grafoDetailsDivisor'>
          <span>Imagem do Google Maps</span>
          <div className='col-12 d-flex flex-column align-items-center justify-content-center'>
            {imagensDoGoogle?.error ? (
              <p>Erro ao carregar as imagens</p>
            ) : (
              <button
                className='btn btn-primary'
                onClick={() => onClick(imagensDoGoogle?.data?.images)}
              >
                Ver em tela cheia
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
