import axios from 'axios'

import { toCamelCase } from '../../utils'

import { GOOGLE_MAPS_BASE_URL } from '../../config/env'

export const consultarImagens = async ({
  tipoLogradouro,
  logradouro,
  numero,
  municipio,
  estado,
  cep
}) => {
  const url = `${GOOGLE_MAPS_BASE_URL}/google_maps`

  const { data } = await axios.get(url, {
    params: {
      tipo_logradouro: tipoLogradouro,
      logradouro,
      numero,
      municipio,
      uf: estado,
      cep
    }
  })

  const formattedData = toCamelCase(data)
  return formattedData
}
