export const BOANOTA_PROVIDER_NAME = 'BoanotaStateMachine'
export const BOANOTA_TOAST_ID = BOANOTA_PROVIDER_NAME

export const BASIC_INFO_PROVIDER_NAME = 'BasicInfoStateMachine'
export const BASIC_INFO_TOAST_ID = BASIC_INFO_PROVIDER_NAME

export const ENDIVIDAMENTO_PROVIDER_NAME = 'EndividamentoStateMachine'
export const ENDIVIDAMENTO_TOAST_ID = ENDIVIDAMENTO_PROVIDER_NAME

export const OPERACIONAIS_PROVIDER_NAME = 'OperacionaisStateMachine'
export const OPERACIONAIS_TOAST_ID = OPERACIONAIS_PROVIDER_NAME

export const BANDEIRA_AMARELA_PROVIDER_NAME = 'BandeiraAmarelaStateMachine'
export const BANDEIRA_AMARELA_TOAST_ID = BANDEIRA_AMARELA_PROVIDER_NAME

export const OFFSHORE_PROVIDER_NAME = 'OffshoreStateMachine'
export const OFFSHORE_TOAST_ID = OFFSHORE_PROVIDER_NAME

export const PATRIMONIO_PROVIDER_NAME = 'PatrimonioStateMachine'
export const PATRIMONIO_TOAST_ID = PATRIMONIO_PROVIDER_NAME

export const JURIDICO_PROVIDER_NAME = 'JuridicoFetcherStateMachine'
export const JURIDICO_TOAST_ID = JURIDICO_PROVIDER_NAME

export const RISCO_JURIDICO_PROVIDER_NAME = 'RiscoJuridicoStateMachine'
export const RISCO_JURIDICO_TOAST_ID = RISCO_JURIDICO_PROVIDER_NAME

export const DISCREDITING_MEDIA_PROVIDER_NAME = 'DiscreditingMediaStateMachine'
export const DISCREDITING_MEDIA_TOAST_ID = JURIDICO_PROVIDER_NAME

// TODO: remover
export const DOCX_PROVIDER_NAME = 'DocxGeneratorStateMachine'
export const DOCX_TOAST_ID = DOCX_PROVIDER_NAME

export const SPREAD_PROVIDER_NAME = 'SpreadStateMachine'
export const SPREAD_TOAST_ID = SPREAD_PROVIDER_NAME

export const VISITAS_PROVIDER_NAME = 'VisitasStateMachine'
export const VISITAS_TOAST_ID = VISITAS_PROVIDER_NAME

export const LOGSUL_PROVIDER_NAME = 'LogsulStateMachine'
export const LOGSUL_TOAST_ID = LOGSUL_PROVIDER_NAME

export const MATCH_GRUPO_SOCIETARIO_PROVIDER_NAME =
  'MatchGrupoSocietarioStateMachine'
export const MATCH_GRUPO_SOCIETARIO_TOAST_ID = MATCH_GRUPO_SOCIETARIO_PROVIDER_NAME

export const listAllProviders = () => [
  BOANOTA_PROVIDER_NAME,
  BASIC_INFO_PROVIDER_NAME,
  ENDIVIDAMENTO_PROVIDER_NAME,
  BANDEIRA_AMARELA_PROVIDER_NAME,
  OPERACIONAIS_PROVIDER_NAME,
  OFFSHORE_PROVIDER_NAME,
  PATRIMONIO_PROVIDER_NAME,
  JURIDICO_PROVIDER_NAME,
  RISCO_JURIDICO_PROVIDER_NAME,
  DISCREDITING_MEDIA_PROVIDER_NAME,
  DOCX_PROVIDER_NAME,
  SPREAD_PROVIDER_NAME,
  VISITAS_PROVIDER_NAME,
  LOGSUL_PROVIDER_NAME,
  MATCH_GRUPO_SOCIETARIO_PROVIDER_NAME
]
