import React from 'react'
import { isValid } from '../../utils'

import * as styles from './style.module.scss'

export default function Field ({ label, value, className }) {
  return (
    <div className={`${className || ''}`}>
      <div className={`font-weight-bold ${styles.label}`}>{label}</div>
      <div className={`${styles.value}`}>
        {isValid(value) ? (
          value
        ) : (
          <span style={{ color: '#00000050', textDecoration: 'line-through' }}>
            sem informação
          </span>
        )}
      </div>
    </div>
  )
}
