import React, { useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { useSelector } from 'react-redux'

import { RiArrowUpSLine, RiArrowDownSLine } from 'react-icons/ri'

import * as styles from './style.module.scss'

const CustomWindow = ({ title, subtitle, name, children, defaultOpen }) => {
  const [hide, setHide] = useState(!defaultOpen)

  const documento = useSelector(state => state.globalStep.targetDocument)

  return (
    <div className={`my-4 ${styles.rootContainer}`}>
      <Header
        title={title}
        subtitle={subtitle}
        opened={!hide}
        onToggle={() => setHide(prev => !prev)}
        disabled={!documento}
      />
      <ReactTooltip />
      {!hide && <div className='container-fluid py-2'>{children}</div>}
    </div>
  )
}

const Header = ({ title, subtitle, opened, onToggle, disabled }) => {
  return (
    <div className={`px-3 py-2 d-flex flex-row ${styles.headerContainer}`}>
      <div className='d-flex flex-column flex-grow-1'>
        <span className={`flex-grow-1 ${styles.title}`}>{title}</span>
        <span className={`flex-grow-1 ${styles.subtitle}`}>{subtitle}</span>
      </div>
      <div className='d-flex flex-row justify-content-center'>
        <button className='btn' disabled={disabled} onClick={() => onToggle()}>
          {opened ? (
            <RiArrowUpSLine color='#0045c8' />
          ) : (
            <RiArrowDownSLine color='#0045c8' />
          )}
        </button>
      </div>
    </div>
  )
}

export default CustomWindow
