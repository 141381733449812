import React from 'react'

import * as styles from './style.module.scss'

export default function Main ({ children }) {
  return (
    <div className={`d-flex flex-grow-1 flex-column py-4 ${styles.container}`}>
      {children}
    </div>
  )
}
