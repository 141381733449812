import { Form } from 'react-bootstrap'

export default function Menu ({ selected, onChange }) {
  return (
    <div className='row mb-2'>
      <div className='col-12 col-lg-6 d-flex flex-row gap-3 mb-3'>
        <Form.Check
          type='radio'
          label='Target'
          onChange={() => onChange(0)}
          checked={selected === 0}
        />
        <Form.Check
          type='radio'
          label='Pessoa Física'
          onChange={() => onChange(1)}
          checked={selected === 1}
        />
        <Form.Check
          type='radio'
          label='Pessoa Jurídica'
          onChange={() => onChange(2)}
          checked={selected === 2}
        />
      </div>
    </div>
  )
}
