import React, { useRef } from 'react'
import { Carousel } from 'react-bootstrap'

import { FaUpload } from 'react-icons/fa'
import { AiFillCloseCircle } from 'react-icons/ai'

import * as styles from './style.module.scss'
import {
  autoRotate,
  base64ToImage,
  compressImageAndAutoResize,
  readFile
} from '../../../../../../../lib/images'

export default function ImagePicker ({
  onChange,
  onError,
  data,
  multiple,
  imageType = 'image/png, image/jpeg',
  height = 400,
  width = undefined
}) {
  const ref = useRef()

  const handleImage = async event => {
    const files = event.target.files

    if (!multiple) {
      const file = files.item(0)
      const encodedImage = await readFile(file)
      const compressedImage = await base64ToImage(encodedImage)
        .then(autoRotate)
        .then(compressImageAndAutoResize)
      return { name: file.name, content: compressedImage }
    }

    const array = []
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i)
      const encodedImage = await readFile(file)
      const compressedImage = await base64ToImage(encodedImage)
        .then(autoRotate)
        .then(compressImageAndAutoResize)
      array.push({ name: file.name, content: compressedImage })
    }

    return array
  }

  const handleClick = event => {
    event.stopPropagation()
    if (ref.current) {
      ref.current.click()
    }
  }

  const handlePaste = async event => {
    const mimes = imageType.split(',').map(mime => mime.trim())
    const items = (event.clipboardData || event.originalEvent.clipboardData)
      .items
    const file = Array.from(items)
      .filter(item => mimes.includes(item.type))
      .map(item => item.getAsFile())?.[0]

    if (!file) {
      return
    }

    const encodedImage = await readFile(file)
    const compressedImage = await base64ToImage(encodedImage)
      .then(autoRotate)
      .then(compressImageAndAutoResize)
    const item = { name: file.name, content: compressedImage }

    if (onChange) {
      if (!multiple) {
        onChange([item])
      } else {
        onChange([...(data || []), item])
      }
    }
  }

  const handleRemoveImage = () => {
    onChange(null)
  }

  const containerClass = data ? styles.containerWithImage : styles.container

  const renderInput = () => {
    return (
      <div className='d-flex flex-column align-items-center justify-content-center m-4'>
        <FaUpload size={30} className='mb-3' />
        <button
          type='button'
          className='btn btn-primary btn-sm'
          onClick={handleClick}
        >
          Selecione {multiple ? 'as imagens' : 'uma imagem'}
        </button>
      </div>
    )
  }

  const renderImage = () => {
    if (Array.isArray(data) && data.length > 0) {
      return (
        <Carousel controls={false}>
          {data.map(({ content, name }, index) => {
            return (
              <Carousel.Item key={index}>
                <div className='position-relative'>
                  <div
                    className='position-absolute d-flex flex-column'
                    style={{ right: 0, top: 0 }}
                  >
                    <AiFillCloseCircle
                      size={16}
                      className='text-danger cursor-pointer'
                      onClick={handleRemoveImage}
                    />
                  </div>
                  <img
                    className={`rounded ${styles.image}`}
                    src={content}
                    alt={name}
                    height={height}
                    width={width}
                  />
                </div>
              </Carousel.Item>
            )
          })}
        </Carousel>
      )
    } else {
      const { name, content } = data || {}
      return (
        <div className='position-relative'>
          <div
            className='position-absolute d-flex flex-column'
            style={{ right: 0, top: 0 }}
          >
            <AiFillCloseCircle
              size={16}
              className='text-danger cursor-pointer'
              onClick={handleRemoveImage}
            />
          </div>
          <img
            className={`rounded ${styles.image}`}
            src={content}
            alt={name}
            height={height}
            width={width}
          />
        </div>
      )
    }
  }

  return (
    <div
      className={`rounded bg-light text-primary ${containerClass}`}
      onPaste={handlePaste}
    >
      {data ? renderImage() : renderInput()}
      <input
        type='file'
        multiple={multiple}
        ref={ref}
        style={{ display: 'none' }}
        accept={imageType}
        onChange={async event => {
          try {
            const imageData = await handleImage(event)
            onChange(imageData)
          } catch (err) {
            console.error(err)
            onError(err.message || 'Erro ao carregar imagem')
          }
        }}
      />
    </div>
  )
}
