export const Types = {
  RESET_MATCHES: 'matches/RESET',
  SET_ERROR: 'matches/SET_ERROR',
  SET_DATA: 'matches/SET_DATA'
}

const INITIAL_VALUE = {
  data: [],
  loaded: false,
  error: false
}

const matchesReducer = (state = INITIAL_VALUE, action) => {
  if (action.type === Types.SET_DATA) {
    return {
      ...state,
      data: action.payload,
      loaded: true,
      error: false
    }
  } else if (action.type === Types.RESET_MATCHES) {
    return INITIAL_VALUE
  } else if (action.type === Types.SET_ERROR) {
    return {
      ...state,
      error: true
    }
  }

  return {
    ...state
  }
}

export default matchesReducer
