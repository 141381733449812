import { useState } from 'react'

import {
  DBJus,
  DBJusTable,
  CustomWindow,
  JuridicoToolbar
} from '../../../../../components'

const VISUALIZATION_TYPES = {
  TABLE: 'table',
  CARD: 'cards'
}

export default function Processos ({
  data,
  onSelectProcesso,
  scores,
  probableVinculados,
  vinculadosMap
}) {
  const [visualization, setVisualization] = useState(VISUALIZATION_TYPES.TABLE)
  const [showPartes, setShowPartes] = useState(true)
  const [showScores, setShowScores] = useState(true)

  return (
    <CustomWindow title='Processos' defaultOpen>
      <div className='row'>
        <JuridicoToolbar
          visualization={visualization}
          showPartes={showPartes}
          onChangeVisualization={newVisualization =>
            setVisualization(newVisualization)
          }
          data={data.map(processo => {
            const nup = processo.nup.replace(/\D/g, '')
            const processosVinculados = (vinculadosMap[nup] || [])
              .map(item => probableVinculados.find(p => p.nup === item))
              .filter(Boolean)
              .map(i => i.nup)

            const scoreParental = scores?.parental?.[nup]
              ? scores.parental[nup]
              : 0
            const scoreSocietario = scores?.societario?.[nup]
              ? scores.societario[nup]
              : 0

            return {
              ...processo,
              'processos vinculados': processosVinculados.join(', '),
              'score pf': (100 * scoreParental).toFixed(2),
              'score pj': (100 * scoreSocietario).toFixed(2)
            }
          })}
          onChangeShowPartes={newPartes => setShowPartes(newPartes)}
          showScores={showScores}
          onChangeShowScores={newShowScores => setShowScores(newShowScores)}
          showCsvButton
        />
      </div>
      <div className='row'>
        {visualization === VISUALIZATION_TYPES.CARD &&
          data?.length > 0 &&
          data.map((processo, key) => {
            const nup = processo.nup.replace(/\D/g, '')
            const processosVinculados = (vinculadosMap[nup] || [])
              .map(item => probableVinculados.find(p => p.nup === item))
              .filter(Boolean)
              .map(i => {
                const iNup = i.nup.replace(/\D/g, '')
                const scoreParental = scores?.parental?.[iNup] || 0
                const scoreSocietario = scores?.societario?.[iNup] || 0

                return { ...i, scoreParental, scoreSocietario }
              })
            return (
              <DBJus
                data={processo}
                key={`${nup}-${key}`}
                showPartes={showPartes}
                showScores={showScores}
                score_parental={
                  scores?.parental && scores.parental[nup]
                    ? scores.parental[nup]
                    : 0
                }
                score_societario={
                  scores?.societario && scores.societario[nup]
                    ? scores.societario[nup]
                    : 0
                }
                processosVinculados={processosVinculados}
                onClick={onSelectProcesso}
              />
            )
          })}
        {visualization === VISUALIZATION_TYPES.TABLE && (
          <DBJusTable
            data={data}
            clickItemProcesso={onSelectProcesso}
            probableVinculados={probableVinculados}
            vinculadosMap={vinculadosMap}
            scores={scores}
            showScores={showScores}
            showPartes={showPartes}
          />
        )}
      </div>
    </CustomWindow>
  )
}
