import { PROGRESS_TYPES } from '../../../../../lib/progressStatus'

import { hashObject } from '../../../../../utils'

export default function Select ({ options, type, disabled, value, onChange }) {
  const filteredOptions = options
    .filter(option => option.type === type)
    .sort((a, b) => a.name.localeCompare(b.name))
  const allFailed =
    filteredOptions.length > 0 &&
    filteredOptions.every(option => option.status === PROGRESS_TYPES.ERROR)
  return (
    <select
      className='form-control w-100'
      disabled={disabled || allFailed}
      value={value ? `${value.document}-${value.type}` : 'default'}
      onChange={event => {
        const [document, type] = event.target.value?.split('-') || [null, null]
        if (!document || !type) {
          return
        }

        const selected = options.find(
          item => item.document === document && item.type === type
        )

        onChange(selected)
      }}
    >
      {allFailed ? (
        <option value='failed'>ENTRADA [Falhou]</option>
      ) : (
        [
          <option key='default' value={null} disabled={disabled}>
            SELECIONE UM DOCUMENTO
          </option>
        ].concat(
          filteredOptions.map((item, key) => {
            const id = hashObject({ item, key })
            const isDisabled = [
              PROGRESS_TYPES.ERROR,
              PROGRESS_TYPES.PENDING
            ].includes(item.status)
            const isFailed = PROGRESS_TYPES.ERROR === item.status
            const isLoading = PROGRESS_TYPES.PENDING === item.status
            return (
              <option
                key={id}
                value={`${item.document}-${item.type}`}
                disabled={isDisabled}
              >
                {isLoading ? '[CARREGANDO]' : isFailed ? '[FALHOU]' : ''}{' '}
                {item.name} - {item.document}
              </option>
            )
          })
        )
      )}
    </select>
  )
}
