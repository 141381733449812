import React from 'react'

export default function Loading () {
  return (
    <div className='d-flex w-100 h-100 flex-1 justify-content-center align-items-center'>
      <div
        className='spinner-border text-primary'
        style={{ width: '3rem', height: '3rem' }}
        role='status'
      >
        <span className='sr-only'>Loading...</span>
      </div>
    </div>
  )
}
