import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { fetchDadosBasicosFromS3 } from '../../../../lib/s3Theme'

import { selectMinData } from '../../../../redux/selectors/dadosBasicos'

import '../../../../assets/sass/cards.scss'
import {
  formatDocument,
  formatWithTimezone,
  isPending,
  parseDate
} from '../../../../utils'

import {
  Content,
  CustomWindow,
  GoogleMapsImages,
  GoogleMapsResponsive,
  ResponsivePlot,
  Loading
} from '../../../../components'

import {
  RamoDeAtividade,
  DadosBasicosCNPJ,
  DadosBasicosCPF,
  Socios as SociosComponent,
  Funcionarios as FuncionariosComponent,
  Empresas as EmpresasComponent,
  PessoasFisicasRelacionadas as PessoasFisicasRelacionadasComponent,
  ParentesSocios as ParentesSociosComponent,
  Parentes as ParentesComponent,
  Contatos as ContatosComponent
} from './components'

const DadosBasicos = () => {
  const googleMaps = useSelector(state => state.googleMaps)
  const { isCPF, targetName, targetDocument } = useSelector(selectMinData)

  const dadosBasicosTheme = useSelector(state => state.dadosBasicosTheme)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    dadosBasicos: { data: [], loaded: false, error: false }
  })

  useEffect(() => {
    if (!dadosBasicosTheme.loaded) {
      return
    }

    setLoading(true)
    fetchDadosBasicosFromS3({
      payload: dadosBasicosTheme
    })
      .then(dadosBasicos => {
        setData(prev => ({ ...prev, dadosBasicos }))
      })
      .catch(error => {
        toast.error(error.message)
        console.error(error)
        setData(prev => ({
          ...prev,
          dadosBasicos: { data: [], loaded: false, error: true }
        }))
      })
      .finally(() => {
        setLoading(false)
      })
  }, [dadosBasicosTheme, isCPF])

  const isStepPending = targetDocument && isPending(dadosBasicosTheme)

  const renderPJ = () => {
    return (
      <>
        <CustomWindow
          title='Ramo de Atividade'
          name='dadosBasicos.ramoDeAtividade'
        >
          <RamoDeAtividade
            error={data?.dadosBasicos.error}
            data={data?.dadosBasicos?.data?.dadosBasicos || {}}
          />
        </CustomWindow>
        <Funcionarios isCPF={isCPF} dadosBasicos={data.dadosBasicos} />
        <Socios isCPF={isCPF} dadosBasicos={data?.dadosBasicos} />
        <ParentesSocios isCPF={isCPF} dadosBasicos={data.dadosBasicos} />
      </>
    )
  }

  const renderPF = () => {
    return (
      <>
        <Parentes isCPF={isCPF} dadosBasicos={data?.dadosBasicos} />
      </>
    )
  }

  return (
    <Content
      title={`Dados Básicos ${targetName ? `- ${targetName}` : ''}`}
      subtitle='Dados principais retirados da receita federal e fontes internas'
    >
      {loading || isStepPending ? (
        <Loading />
      ) : (
        <>
          <CustomWindow
            title={targetDocument ? targetName : 'Dados Básicos'}
            subtitle={`${
              targetDocument ? (isCPF ? 'CPF:' : 'CNPJ:') : ''
            } ${formatDocument(targetDocument)}`}
            name='dadosBasicos.dadosBasicos'
          >
            {isCPF ? (
              <DadosBasicosCPF dadosCadastrais={data?.dadosBasicos} />
            ) : (
              <DadosBasicosCNPJ dadosCadastrais={data?.dadosBasicos} />
            )}
          </CustomWindow>
          <Contatos isCPF={isCPF} dadosBasicos={data?.dadosBasicos} />
          {isCPF ? renderPF() : renderPJ()}
          <PessoasFisicasRelacionadas dadosBasicos={data.dadosBasicos} />
          <Empresas isCPF={isCPF} dadosBasicos={data.dadosBasicos} />
          {googleMaps.isLoadedImages && <GoogleMaps googleMaps={googleMaps} />}
        </>
      )}
    </Content>
  )
}

const Contatos = React.memo(({ isCPF, dadosBasicos }) => {
  if (!dadosBasicos?.loaded) {
    return null
  }

  return (
    <CustomWindow
      title='Contatos'
      name='dadosBasicos.telefones'
      subtitle='Dados retirados do Indrema Datalake e Receita Federal.'
      showNote
    >
      <ContatosComponent
        isCPF={isCPF}
        data={dadosBasicos.data}
        error={dadosBasicos.error}
      />
    </CustomWindow>
  )
})

const Funcionarios = React.memo(({ isCPF, dadosBasicos }) => {
  if (isCPF) {
    return null
  }

  const allFuncionarios = (dadosBasicos.data?.funcionarios?.funcionarios || [])
    .concat(dadosBasicos.data?.funcionarios?.exfuncionarios || [])
    .map(funcionario => {
      const hasAnoMesDesligamento = funcionario.anoMesDesligamento
      const dataAdmissao = formatWithTimezone(
        new Date(funcionario.dataAdmissao),
        'yyyy-MM'
      )
      const anoMesDesligamento = hasAnoMesDesligamento
        ? formatWithTimezone(
            parseDate(funcionario.anoMesDesligamento, 'MM-yyyy'),
            'yyyy-MM'
          )
        : null

      return {
        ...funcionario,
        dataAdmissao,
        anoMesDesligamento
      }
    })
  const allDates = Array.from(
    new Set(
      allFuncionarios
        .map(funcionario => {
          const hasAnoMesDesligamento = funcionario.anoMesDesligamento
          const dataAdmissao = funcionario.dataAdmissao
          if (hasAnoMesDesligamento) {
            const anoMesDesligamento = funcionario.anoMesDesligamento
            return [dataAdmissao, anoMesDesligamento]
          }

          return dataAdmissao
        })
        .flat()
    )
  ).sort((a, b) => a.localeCompare(b))

  const funcionariosAtivosPorMes = allDates.map(date => {
    const funcionariosAtivos = allFuncionarios.filter(funcionario => {
      const hasAnoMesDesligamento = funcionario.anoMesDesligamento
      const dataAdmissao = funcionario.dataAdmissao
      if (hasAnoMesDesligamento) {
        const anoMesDesligamento = funcionario.anoMesDesligamento
        return dataAdmissao <= date && anoMesDesligamento >= date
      }

      return dataAdmissao <= date
    }).length

    return {
      date,
      qtd: funcionariosAtivos
    }
  })

  const chartData = [
    {
      name: 'Qtd. Funcionários',
      type: 'bar',
      x: funcionariosAtivosPorMes.map(item => parseDate(item.date, 'yyyy-MM')),
      y: funcionariosAtivosPorMes.map(item => item.qtd)
    }
  ]

  return (
    <CustomWindow
      title='Funcionários'
      subtitle='Dados retirados do Indrema Datalake'
      name='dadosBasicos.funcionarios'
      showNote
    >
      <FuncionariosComponent
        data={dadosBasicos.data?.funcionarios}
        error={dadosBasicos.error}
      />
      <ResponsivePlot
        data={chartData}
        layout={{
          hovermode: 'x unified',
          legend: {
            orientation: 'h',
            y: -0.3,
            x: 0.5,
            xanchor: 'center',
            yanchor: 'top'
          },
          margin: {
            l: 70,
            r: 0,
            b: 60,
            t: 30
          },
          xaxis: {
            title: 'Data'
          },
          yaxis: {
            title: 'Qtd. Funcionários'
          }
        }}
      />
    </CustomWindow>
  )
})

const Socios = React.memo(({ isCPF, dadosBasicos }) => {
  if (isCPF) {
    return null
  }

  return (
    <CustomWindow
      title='Sócios'
      subtitle='Dados retirados da Receita Federal'
      name='dadosBasicos.socios'
    >
      <SociosComponent data={dadosBasicos.data} error={dadosBasicos.error} />
    </CustomWindow>
  )
})

const Empresas = React.memo(({ isCPF, dadosBasicos }) => {
  return (
    <CustomWindow
      title='Empresas'
      subtitle='Dados retirados da Receita Federal e Indrema Datalake'
      name='dadosBasicos.empresas'
    >
      <EmpresasComponent isCPF={isCPF} dadosBasicos={dadosBasicos} />
    </CustomWindow>
  )
})

const PessoasFisicasRelacionadas = React.memo(({ dadosBasicos }) => {
  return (
    <CustomWindow
      title='Pessoas físicas relacionadas'
      subtitle='Dados retirados do Indrema Datalake'
      name='dadosBasicos.pessoasFisicasRelacionadas'
    >
      <PessoasFisicasRelacionadasComponent
        data={dadosBasicos.data}
        error={dadosBasicos.error}
      />
    </CustomWindow>
  )
})

const ParentesSocios = React.memo(({ isCPF, dadosBasicos }) => {
  if (isCPF) {
    return null
  }

  return (
    <CustomWindow
      title='Parentes dos sócios'
      subtitle='Dados retirados da Receita Federal e Indrema Datalake'
      name='dadosBasicos.parentesSocios'
    >
      <ParentesSociosComponent
        data={dadosBasicos.data}
        error={dadosBasicos.error}
      />
    </CustomWindow>
  )
})

const Parentes = React.memo(({ isCPF, dadosBasicos }) => {
  const parentes = dadosBasicos.data?.pessoasFisicasRelacionadas?.parentes

  if (!isCPF) {
    return null
  }

  return (
    <CustomWindow
      title='Parentes'
      subtitle='Dados retirados do Indrema Datalake. Esse campo é aproximado por uso de inteligência artificial.'
      name='dadosBasicos.parentes'
    >
      <ParentesComponent data={parentes} error={dadosBasicos.error} />
    </CustomWindow>
  )
})

const GoogleMaps = React.memo(({ googleMaps }) => {
  return (
    <CustomWindow title='Google Maps' name='dadosBasicos.googleMaps'>
      <div className='row'>
        <div className='col-12 mb-4 d-flex justify-content-center'>
          <GoogleMapsImages data={googleMaps} />
        </div>
      </div>
      <div className='row'>
        <div className='col-12 d-flex justify-content-center'>
          <GoogleMapsResponsive
            query={googleMaps.address}
            height='600px'
            width='460px'
          />
        </div>
      </div>
    </CustomWindow>
  )
})

export default DadosBasicos
