import { toast } from 'react-toastify'
import { call, put } from 'redux-saga/effects'

import { fetchThemeFromS3 } from '../../lib/s3Theme'

import { setRelateds, setErrorRelateds } from '../store/relateds'

export function * fetchRelateds (action) {
  try {
    const s3Path = action.payload

    const { relateds } = yield call(fetchThemeFromS3, {
      s3Path,
      selectedFields: ['relateds']
    })
    yield put(setRelateds(relateds.data))
  } catch (err) {
    console.error(err)
    yield put(setErrorRelateds())
    toast.error('Erro ao consultar grau de relacionamentos', {
      position: toast.POSITION.BOTTOM_RIGHT,
      toastId: 'relateds-fetch-error'
    })
  }
}
