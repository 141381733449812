import React, { Fragment, useRef } from 'react'

import {
  FaFile,
  FaFileAlt,
  FaFileImage,
  FaFileInvoice,
  FaFilePdf,
  FaUpload
} from 'react-icons/fa'
import { AiFillCloseCircle } from 'react-icons/ai'

import * as styles from './style.module.scss'

import { readFile } from '../../../../lib/images'

export default function FilePicker ({
  onChange,
  onError,
  data,
  multiple,
  className,
  fileType = '*',
  height = 400,
  width = undefined
}) {
  const ref = useRef()

  const handleFile = async event => {
    const files = event.target.files

    if (!multiple) {
      const file = files.item(0)
      const encodedFile = await readFile(file)
      return { name: file.name, content: encodedFile, type: file.type }
    }

    const array = []
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i)
      const encodedFile = await readFile(file)
      array.push({ name: file.name, content: encodedFile, type: file.type })
    }

    return [...(data || []), ...array]
  }

  const handleClick = event => {
    event.stopPropagation()
    if (ref.current) {
      ref.current.click()
    }
  }

  const handleRemoveFile = index => {
    if (onChange) {
      if (Array.isArray(data)) {
        const newData = data.filter((_, i) => i !== index)
        onChange(newData)
      } else {
        onChange(null)
      }
    }
  }

  const containerClass = data ? styles.containerWithFile : styles.container

  const renderInput = () => {
    return (
      <div className='d-flex flex-column align-items-center justify-content-center m-4'>
        <FaUpload size={30} className='mb-3' />
        <button
          type='button'
          className='btn btn-primary btn-sm'
          onClick={handleClick}
        >
          Selecione {multiple ? 'os arquivos' : 'o arquivo'}
        </button>
      </div>
    )
  }

  const renderFiles = () => {
    const arrayData = Array.isArray(data) ? data : [data]

    const resolveType = ({ type, extension }) => {
      if (type.startsWith('image/')) {
        return 'image'
      } else if (type === 'application/pdf' || extension === 'pdf') {
        return 'pdf'
      } else if (
        [
          'cpe',
          'cpr',
          'rem',
          'ret',
          '2pe',
          '2re',
          '2pr',
          '2rr',
          'pag'
        ].includes(extension)
      ) {
        return 'invoice'
      } else if (type === 'text/plain' || extension === 'txt') {
        return 'txt'
      } else {
        return 'unknown'
      }
    }

    const Icon = ({ type, ...rest }) => {
      let InternalIcon = null
      switch (type) {
        case 'image':
          InternalIcon = FaFileImage
          break
        case 'pdf':
          InternalIcon = FaFilePdf
          break
        case 'txt':
          InternalIcon = FaFileAlt
          break
        case 'invoice':
          InternalIcon = FaFileInvoice
          break
        default:
          InternalIcon = FaFile
          break
      }
      return <InternalIcon {...rest} />
    }
    return (
      <div className='container-fluid my-4'>
        {arrayData.map(({ content, name, type }, index) => {
          const extension = name.split('.').pop()
          return (
            <Fragment key={index.toString()}>
              <hr className='mb-2 mt-2' />
              <div className='row'>
                <div className='col-2 px-1 d-flex flex-column justify-content-center align-items-start pl-3'>
                  <Icon size={25} type={resolveType({ type, extension })} />
                </div>
                <div className='col-8 px-1'>{name}</div>

                <div className='col-2 px-1 d-flex flex-column justify-content-center align-items-end pr-3'>
                  <AiFillCloseCircle
                    size={16}
                    className='text-danger cursor-pointer'
                    onClick={() => handleRemoveFile(index)}
                  />
                </div>
              </div>
            </Fragment>
          )
        })}
      </div>
    )
  }

  return (
    <div
      className={
        `rounded bg-light text-primary ${containerClass} ` + (className || '')
      }
      onClick={data ? undefined : handleClick}
    >
      {renderInput()}
      {data && renderFiles()}
      <input
        type='file'
        multiple={multiple}
        ref={ref}
        style={{ display: 'none' }}
        accept={fileType}
        onChange={async event => {
          try {
            const fileData = await handleFile(event)
            onChange(fileData)
          } catch (err) {
            console.error(err)
            onError(err.message || 'Erro ao carregar arquivo')
          }
        }}
      />
    </div>
  )
}
