import { toast } from 'react-toastify'
import { all, call, put, select } from 'redux-saga/effects'
import Swal from 'sweetalert2'

import { StaticS3Client } from '../../lib/s3'
import { fetchBase64 } from '../../lib/images'

import { formatList, getIdToken, hashObject, resolveScope } from '../../utils'
import {
  SCHEMA_SCORE_COMPORTAMENTAL,
  SCHEMA_SCORE_PLD,
  SCHEMA_SCORE_PROSPECCAO,
  validateForm
} from '../../utils/form'

import { BUCKET_NAME, REPORT_BASE_URL } from '../../config/env'
import { selectMinData } from '../selectors/dadosBasicos'
import {
  setDefaultStreetViewImageSulBrasil,
  setFormularioSalvoSulBrasil
} from '../store/sulbrasil'
import API from '../../services/api'
import { setErrorSpread } from '../store/spreadTheme'
import { setErrorVisitas } from '../store/visitasTheme'
import { setErrorLogsul } from '../store/logsulTheme'

const mapScore = score => {
  return score.reduce((obj, item) => {
    obj[item.id] = item.value
    return obj
  }, {})
}

export function * generateReportSulBrasil (action) {
  const toastId = 'sulbrasil-gerando-relatorio'

  const dataScorePld = yield select(
    state => state.sulbrasil.formulario.scorePld.form
  )
  const dataScoreComportamental = yield select(
    state => state.sulbrasil.formulario.scoreComportamental.form
  )
  const dataScoreProspeccao = yield select(
    state => state.sulbrasil.formulario.scoreProspeccao.form
  )

  const mappedScorePld = mapScore(dataScorePld)
  const mappedScoreComportamental = mapScore(dataScoreComportamental)
  const mappedScoreProspeccao = mapScore(dataScoreProspeccao)

  const isValidPld = yield call(() =>
    validateForm({ schema: SCHEMA_SCORE_PLD, data: mappedScorePld })
      .then(() => true)
      .catch(() => false)
  )
  const isValidComportamental = yield call(() =>
    validateForm({
      schema: SCHEMA_SCORE_COMPORTAMENTAL,
      data: mappedScoreComportamental
    })
      .then(() => true)
      .catch(() => false)
  )
  const isValidProspeccao = yield call(() =>
    validateForm({
      schema: SCHEMA_SCORE_PROSPECCAO,
      data: mappedScoreProspeccao
    })
      .then(() => true)
      .catch(() => false)
  )

  if (!isValidPld || !isValidComportamental || !isValidProspeccao) {
    const scores = [
      { name: 'PLD', isValid: isValidPld },
      { name: 'Comportamental', isValid: isValidComportamental },
      { name: 'Prospecção', isValid: isValidProspeccao }
    ]
      .filter(score => !score.isValid)
      .map(score => score.name)
    const title =
      'Você não preencheu todos os campos do(s) score(s) ' +
      formatList(scores, null, 'long', 'conjunction')

    const { isConfirmed } = yield call(openFormAlert, {
      title,
      text: 'Deseja continuar mesmo assim?'
    })

    if (!isConfirmed) {
      return
    }
  }

  try {
    const accessToken = yield call(getIdToken)
    const { targetDocument } = yield select(selectMinData)
    const s3Instance = yield call(StaticS3Client.getInstance, { accessToken })
    const aggregateFileReport = yield call(getAggregatedFileReport, {
      s3Instance,
      targetDocument
    })

    if (aggregateFileReport) {
      const { isConfirmed } = yield call(openFormAlert, {
        title: 'Deseja apagar os dados do relatório atual?',
        text:
          'Ao gerar o comentário você pode apagar as informações já preenchidas do relatório atual ou manter o que já está preenchido. Caso o relatório já tenha sido arquivado a versão arquivada não será alterada ou apagada.',
        focusDeny: true
      })
      toast('Gerando relatório...', {
        toastId
      })
      if (isConfirmed) {
        const field = getCommentField()
        const newField = getBackupCommentField()
        yield call(API.comments.move, {
          document: targetDocument,
          field,
          newField
        })
      }
    } else {
      toast('Gerando relatório...', {
        toastId
      })
    }

    const metadata = yield select(state => state.globalStep.metadata)
    if (!metadata.finishedAt) {
      toast.error('Aguarde o término do carregamento')
      return
    }

    const forms = yield select(state => state.sulbrasil.formulario)

    const formsOutputData = yield call(writeForm, {
      forms: {
        ...forms,
        imagens: {
          ...forms.imagens,
          defaultStreetView: undefined,
          streetView:
            forms.imagens.streetView || forms.imagens.defaultStreetView || []
        }
      },
      s3Instance,
      targetDocument
    })
    const aggregatedContent = yield call(readAggregatedFile, {
      s3Instance,
      targetDocument
    })

    const filteredAggregatedPath = Object.fromEntries(
      Object.entries(aggregatedContent).filter(
        ([_, { key }]) => typeof key === 'string'
      )
    )

    const mappedPaths = Object.fromEntries(
      Object.entries(filteredAggregatedPath).map(
        ([keyObj, { key, versionId }]) => {
          return [keyObj, { key: `${key}_relatorio.json`, versionId }]
        }
      )
    )

    mappedPaths.forms = {
      key: formsOutputData.key,
      version_id: formsOutputData.versionId
    }

    yield call(copyAllFiles, {
      s3Instance,
      originalPaths: filteredAggregatedPath,
      targetPaths: mappedPaths
    })

    yield call(writeAggregatedFile, {
      s3Instance,
      targetDocument,
      data: mappedPaths
    })

    yield put(setFormularioSalvoSulBrasil(true))
    toast.dismiss(toastId)

    yield call(openPopup, { targetDocument })
  } catch (err) {
    console.error(err)
    toast.error('Erro ao gerar relatório', {
      position: toast.POSITION.BOTTOM_RIGHT
    })
  } finally {
    toast.dismiss(toastId)
  }
}

const openFormAlert = async ({ title, text, focusDeny }) => {
  const { isConfirmed } = await Swal.fire({
    title,
    text,
    showConfirmButton: true,
    showDenyButton: true,
    allowOutsideClick: false,
    showCloseButton: false,
    icon: 'warning',
    confirmButtonText: 'Sim',
    denyButtonText: 'Não',
    focusDeny
  })

  return { isConfirmed }
}

async function getAggregatedFileReport ({ s3Instance, targetDocument }) {
  try {
    const bucket = BUCKET_NAME
    const key = await generateAggregatedFileKey({ targetDocument })

    const output = await s3Instance.readFile({
      bucket,
      key
    })
    return output
  } catch (err) {
    if (err.Code === 'AccessDenied') {
      return undefined
    }
    throw err
  }
}

const openPopup = async ({ targetDocument }) => {
  const link = `${REPORT_BASE_URL}/?documento=${targetDocument}`

  const { isConfirmed } = await Swal.fire({
    title: 'Relatório gerado com sucesso',
    text: 'Clique no botão abaixo para ir para o relatório!',
    showConfirmButton: true,
    showCloseButton: true,
    icon: 'success',
    confirmButtonText: 'Ir para o relatório'
  })

  if (isConfirmed) {
    window.open(link, '_blank')
  }
}

async function writeForm ({ forms, s3Instance, targetDocument }) {
  const bucket = BUCKET_NAME
  const key = `boanota/sulbrasil/applications/relatorio_cadastro/formulario_plataforma/document=${targetDocument}/json`

  const output = await s3Instance.writeFile({
    bucket,
    key,
    data: forms,
    contentType: 'application/json'
  })

  return { bucket, key, versionId: output.VersionId }
}

const getAggregatedFileKey = async ({ targetDocument }) => {
  const internalScope = await resolveScope()
  const key = `boanota/${internalScope}/applications/relatorio_cadastro/resource=aggregate/document=${targetDocument}/json`

  return key
}

const generateAggregatedFileKey = async ({ targetDocument }) => {
  const internalScope = await resolveScope()
  const key = `boanota/${internalScope}/applications/relatorio_cadastro/resource=aggregate/document=${targetDocument}/relatorio.json`

  return key
}

const getCommentField = () => {
  return `all-undefined`
}

const getBackupCommentField = () => {
  return `all-backup-undefined`
}

async function readAggregatedFile ({ s3Instance, targetDocument }) {
  const bucket = BUCKET_NAME
  const key = await getAggregatedFileKey({ targetDocument })

  const data = await s3Instance.readFile({ bucket, key })
  return data
}

async function writeAggregatedFile ({ s3Instance, targetDocument, data }) {
  const bucket = BUCKET_NAME
  const key = await generateAggregatedFileKey({ targetDocument })

  const output = await s3Instance.writeFile({
    bucket,
    key,
    data,
    contentType: 'application/json'
  })
  return { bucket, key, versionId: output.VersionId }
}

export function * changeReportStatusSulBrasil (action) {
  yield put(setFormularioSalvoSulBrasil(false))
}

export function * setStreetViewSulBrasil (action) {
  const { images, geolocation } = action.payload

  const base64Images = yield all(images.map(url => call(fetchBase64, url)))
  yield put(
    setDefaultStreetViewImageSulBrasil({
      geolocation,
      images: base64Images.map(imageData => ({
        content: 'data:image/jpg;base64,' + imageData,
        name: hashObject(imageData)
      }))
    })
  )
}

async function copyAllFiles ({ s3Instance, originalPaths, targetPaths }) {
  const bucket = BUCKET_NAME

  const paths = Object.entries(originalPaths).map(([keyObj, value]) => {
    return { from: `${bucket}/${value.key}`, to: targetPaths[keyObj].key }
  })

  await Promise.allSettled(
    paths.map(({ from, to }) =>
      s3Instance.copyFile({
        bucket,
        sourceKey: from,
        destinationKey: to,
        contentType: 'application/json'
      })
    )
  )
}

export function * listenerFinish (action) {
  const { isCPF } = yield select(selectMinData)

  if (!isCPF) {
    return
  }

  yield put(setErrorSpread(true))
  yield put(setErrorLogsul(true))
  yield put(setErrorVisitas(true))
}
