import { call, put, select } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'

import { exportDoc } from '../../components'
import { selectMinData } from '../selectors/dadosBasicos'
import { setFileNameDocumento, setExecutionArnDocumento } from '../store/doc'

class CantExportError extends Error {
  name = 'CantExportError'
}

class CantExportWarning extends Error {
  name = 'CantExportWarning'
}

export function * listenerGerarDocumento (action) {
  const queueUrl = yield select(state => state.globalStep.queueUrl)
  const googleMaps = yield select(state => state.googleMaps)
  const { targetDocument, isCPF } = yield select(selectMinData)

  const state = yield select(state => ({
    relateds: state.relateds,
    globalStep: state.globalStep,
    offshoreTheme: state.offshoreTheme,
    dadosBasicosTheme: state.dadosBasicosTheme,
    patrimonioTheme: state.patrimonioTheme,
    endividamentoTheme: state.endividamentoTheme,
    bandeiraAmarelaTheme: state.bandeiraAmarelaTheme,
    juridicoTheme: state.juridicoTheme,
    spreadTheme: state.spreadTheme,
    visitasTheme: state.visitasTheme,
    logsulTheme: state.logsulTheme,
    riscoJuridicoTheme: state.riscoJuridicoTheme,
    matchGrupoSocietarioTheme: state.matchGrupoSocietarioTheme,
    discreditingMediaTheme: state.discreditingMediaTheme
  }))

  // TODO: operacionaisTheme

  const canExport = () => {
    if (!targetDocument) {
      throw new CantExportError('Sem documento selecionado')
    }

    if (!state.dadosBasicosTheme.loaded || state.dadosBasicosTheme.error) {
      if (state.dadosBasicosTheme.error) {
        throw new CantExportWarning('Dados básicos falharam')
      }

      throw new CantExportWarning('Dados básicos ainda não carregaram')
    }

    if (!isCPF && (!googleMaps.isLoadedImages || googleMaps.errorImages)) {
      if (googleMaps.errorImages) {
        throw new CantExportError(
          'Ocorreu um erro ao carregar as imagens do google'
        )
      }

      throw new CantExportWarning('Ainda não carregou as imagens do google')
    }

    if (!queueUrl) {
      throw new CantExportError(
        'Ops, um parâmetro está faltando, tente pesquisar novamente'
      )
    }

    // Essa deve ser a última verificação para não bloquear geração do dossier
    // e não bloquear a exibição de outro toast
    if (
      state.offshoreTheme.offshoreLeaks.error ||
      !state.offshoreTheme.offshoreLeaks.loaded
    ) {
      if (!state.offshoreTheme.offshoreLeaks.loaded) {
        toast.warning('Ainda não carregou dados offshore', {
          toastId: 'export-warning-loading',
          position: toast.POSITION.BOTTOM_RIGHT
        })
      } else {
        toast.warning('Erro ao carregar offshore', {
          toastId: 'export-error',
          position: toast.POSITION.BOTTOM_RIGHT
        })
      }
    }

    if (
      state.patrimonioTheme.sncr.error ||
      !state.patrimonioTheme.sncr.loaded ||
      state.patrimonioTheme.aeronaves.error ||
      !state.patrimonioTheme.aeronaves.loaded
    ) {
      if (
        !state.patrimonioTheme.sncr.loaded ||
        !state.patrimonioTheme.aeronaves.loaded
      ) {
        toast.warning('Ainda não carregou dados patrimonial', {
          toastId: 'export-warning-loading',
          position: toast.POSITION.BOTTOM_RIGHT
        })
      } else {
        toast.warning('Erro ao carregar patrimonial', {
          toastId: 'export-error',
          position: toast.POSITION.BOTTOM_RIGHT
        })
      }
    }

    if (
      state.endividamentoTheme.cheque.error ||
      !state.endividamentoTheme.cheque.loaded ||
      state.endividamentoTheme.cndt.error ||
      !state.endividamentoTheme.cndt.loaded ||
      state.endividamentoTheme.pgfn.error ||
      !state.endividamentoTheme.pgfn.loaded ||
      state.endividamentoTheme.protesto.error ||
      !state.endividamentoTheme.protesto.loaded
    ) {
      if (
        !state.endividamentoTheme.cheque.loaded ||
        !state.endividamentoTheme.cndt.loaded ||
        !state.endividamentoTheme.pgfn.loaded ||
        !state.endividamentoTheme.protesto.loaded
      ) {
        toast.warning('Ainda não carregou dados endividamento', {
          toastId: 'export-warning-loading',
          position: toast.POSITION.BOTTOM_RIGHT
        })
      } else {
        toast.warning('Erro ao carregar endividamento', {
          toastId: 'export-error',
          position: toast.POSITION.BOTTOM_RIGHT
        })
      }
    }

    return true
  }

  try {
    canExport()
    const { name: fileName, executionArn } = yield call(exportDoc, {
      state,
      queueUrl
    })

    if (executionArn) {
      yield put(setExecutionArnDocumento(executionArn))
    }

    if (fileName) {
      yield put(setFileNameDocumento(fileName))
    }
  } catch (err) {
    const message = err.message || 'Erro ao exportar'
    if (err.name === 'CantExportError') {
      toast.warning(message, {
        toastId: 'export-error',
        position: toast.POSITION.BOTTOM_RIGHT
      })
    } else if (err.name === 'CantExportWarning') {
      toast.warning(message, {
        toastId: 'export-warning-loading',
        position: toast.POSITION.BOTTOM_RIGHT
      })
    } else {
      toast.error(message, {
        toastId: 'export-error',
        position: toast.POSITION.BOTTOM_RIGHT
      })
    }
  }
}

export function * listenerSaveDocx (action) {
  const { link } = action.payload

  try {
    const text =
      'Se o download não iniciar em 5 segundos, clique no botão abaixo para realizar o download!'

    try {
      yield call(automaticDownload, { link })
    } catch (err) {
      console.error(err)
    }

    yield call(downloadFileFromPresignedUrl, { link, text })
  } catch (err) {
    const message = err.message || 'Erro ao exportar'
    if (err.name === 'CantExportError') {
      toast.warning(message, {
        toastId: 'erro-salvar-dossier',
        position: toast.POSITION.BOTTOM_RIGHT
      })
    } else {
      toast.error(message, {
        toastId: 'erro-salvar-dossier',
        position: toast.POSITION.BOTTOM_RIGHT
      })
    }
  }
}

const automaticDownload = async ({ link }) => {
  const TOAST_ID_DOCX = 'gerando-dossie'

  window.open(link, '_blank')
  toast.dismiss(TOAST_ID_DOCX)
  toast.success('Dossiê gerado com sucesso!', {
    position: toast.POSITION.BOTTOM_RIGHT,
    toastId: 'dossie-gerado'
  })
}

const downloadFileFromPresignedUrl = async ({ link, text }) => {
  const TOAST_ID_DOCX = 'gerando-dossie'
  toast.dismiss(TOAST_ID_DOCX)
  const { isConfirmed } = await Swal.fire({
    title: 'Documento gerado',
    text: text || 'Clique no botão abaixo para baixar o documento gerado!',
    showConfirmButton: true,
    showCloseButton: true,
    icon: 'success',
    confirmButtonText: 'Baixar'
  })

  if (isConfirmed) {
    window.open(link, '_blank')
  }
}
