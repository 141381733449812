import React, { useMemo } from 'react'
import { FiSearch } from 'react-icons/fi'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'

import { Header, Main, Footer, Table, Loading } from '../../components'
import {
  formatCurrency,
  formatPartialCnpj,
  formatPartialCpf,
  hashObject
} from '../../utils'
import { onSortCurrency, onSortDocument } from '../../components/Table/sort'

import jsonConsolidado from '../../mocks/open-operations_consolidado.json'
import jsonNormal from '../../mocks/open-operations_normal.json'

const { SearchBar } = Search

export default function InvestmentFundAnalysis () {
  return (
    <>
      <Header />
      <Main>
        <div className='d-flex flex-grow-1 flex-row my-4'>
          <main className='d-flex flex-grow-1'>
            <Content />
          </main>
        </div>
      </Main>
      <Footer />
    </>
  )
}

const formatDocument = event => {
  const input = event.target.value
  const cleanedInput = input.replace(/\D/g, '')

  const formatter =
    cleanedInput.length <= 11 ? formatPartialCpf : formatPartialCnpj

  return formatter(cleanedInput)
}

const formatCnpj = event => {
  const input = event.target.value
  const cleanedInput = input.replace(/\D/g, '')

  const formatter = formatPartialCnpj

  return formatter(cleanedInput)
}

const Content = () => {
  const [cnpjFundo, setCnpjFundo] = React.useState('')
  const [docCedente, setDocCedente] = React.useState('')
  const [docSacado, setDocSacado] = React.useState('')
  const [consolidar, setConsolidar] = React.useState(false)
  const [tableType, setTableType] = React.useState('none')
  const [loading, setLoading] = React.useState(false)

  const columns = [
    {
      dataField: 'documento',
      text: 'Documento',
      sort: true,
      sortFunc: onSortDocument,
      searchable: false
    },
    {
      dataField: 'nome',
      sort: true,
      text: 'Nome'
    },
    {
      dataField: 'totalAReceber',
      text: 'Total a receber',
      sort: true,
      sortFunc: onSortCurrency,
      searchable: false
    },
    {
      dataField: 'rawDocumento',
      hidden: true
    },
    {
      dataField: 'rawTotalAReceber',
      hidden: true
    }
  ].map(item => ({
    ...item,
    align: 'center',
    headerAlign: 'center'
  }))

  const mappedData = useMemo(
    () =>
      (tableType === 'none'
        ? []
        : tableType === 'normal'
        ? jsonNormal
        : jsonConsolidado
      ).map(item => ({
        id: hashObject(item),
        ...item,
        rawDocumento: item.documento,
        totalAReceber: formatCurrency(item.total_a_receber),
        rawTotalAReceber: item.total_a_receber
      })),
    [tableType]
  )

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-12 text-center'>
          <h1 className='h1'>Análise dos Fundos</h1>
        </div>
      </div>
      <div className='row'>
        <div className='col-8'>
          <div className='form-group'>
            <label htmlFor='fundo'>CNPJ do Fundo</label>
            <input
              type='text'
              className='form-control'
              id='fundo'
              placeholder='Digite aqui o CNPJ'
              value={cnpjFundo}
              onChange={event => {
                setCnpjFundo(formatCnpj(event))
              }}
            />
          </div>
        </div>
        <div className='col-2 d-flex align-items-center mt-2'>
          <div className='form-check'>
            <input
              className='form-check-input'
              type='checkbox'
              value=''
              id='consolidar'
              checked={consolidar}
              onClick={() => setConsolidar(prev => !prev)}
            />
            <label
              className='form-check-label'
              for='consolidar'
              style={{ marginTop: 2 }}
            >
              Consolidar
            </label>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-4'>
          <div className='form-group'>
            <label htmlFor='fundo'>Doc. do Cedente</label>
            <input
              type='text'
              className='form-control'
              id='cedente'
              placeholder='Digite aqui o doc. do cedente'
              value={docCedente}
              onChange={event => {
                setDocCedente(formatDocument(event))
              }}
            />
          </div>
        </div>
        <div className='col-4'>
          <div className='form-group'>
            <label htmlFor='fundo'>Doc. do Sacado</label>
            <input
              type='text'
              className='form-control'
              id='sacado'
              placeholder='Digite aqui o doc. do sacado'
              value={docSacado}
              onChange={event => {
                setDocSacado(formatDocument(event))
              }}
            />
          </div>
        </div>
        <div className='col-2 d-flex align-items-center mt-3'>
          <button
            className='btn btn-primary'
            onClick={() => {
              const nextTableType = consolidar ? 'consolidado' : 'normal'
              setLoading(true)
              setTimeout(() => {
                setTableType(nextTableType)
                setLoading(false)
              }, 5 * 1000)
            }}
          >
            <FiSearch size={20} /> consultar
          </button>
        </div>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <ToolkitProvider
          keyField='id'
          data={mappedData}
          columns={columns}
          search
        >
          {props => (
            <>
              <div className='row mb-2 mt-5 text-right'>
                <div className='col-12'>
                  <SearchBar {...props.searchProps} placeholder='Filtrar' />
                </div>
              </div>
              <div className='row mt-1'>
                <div className='col-12'>
                  <Table {...props.baseProps} enablePagination />
                </div>
              </div>
            </>
          )}
        </ToolkitProvider>
      )}
    </div>
  )
}
