export const matchFuncionarios = ({
  funcionarios,
  exFuncionarios,
  match,
  tipoMatch
}) => {
  if (tipoMatch === 'only') {
    return match.data
  }

  const merged = funcionarios.data.concat(exFuncionarios.data).sort((a, b) => {
    const documentoEmpresaSort = a.documentoEmpresa.localeCompare(
      b.documentoEmpresa
    )
    const nomeSort = a.nome.localeCompare(b.nome)

    return documentoEmpresaSort || nomeSort
  })

  return merged
}
