import React from 'react'
import { Route } from 'react-router-dom'

import Header from './Header'
import Main from './Main'
import Footer from './Footer'
import Sidebar from './Sidebar'
import SubHeader from './SubHeader'

const DossieRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (
        <WrappedComponent>
          <Component {...props} />
        </WrappedComponent>
      )}
    />
  )
}

const WrappedComponent = ({ children }) => {
  return (
    <>
      <Header />
      <Main>
        <SubHeader />
        <div className='d-flex flex-grow-1 flex-row my-4'>
          <Sidebar />
          <main className='d-flex flex-grow-1'>{children}</main>
        </div>
      </Main>
      <Footer />
    </>
  )
}

export const HubRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (
        <>
          <Header />
          <Main>
            <div className='d-flex flex-grow-1 flex-row my-4'>
              <main className='d-flex flex-grow-1'>
                <Component {...props} />
              </main>
            </div>
          </Main>
          <Footer />
        </>
      )}
    />
  )
}

export default DossieRoute
