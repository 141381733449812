import { Types } from '../reducers/matches'

export const resetMatches = () => ({
  type: Types.RESET_MATCHES
})

export const setMatchesError = () => ({
  type: Types.SET_ERROR
})

export const setMatchesData = data => ({
  type: Types.SET_DATA,
  payload: data
})
