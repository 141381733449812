import { createSelector } from 'reselect'

const getGlobalStep = state => state.globalStep

export const selectMinData = createSelector(getGlobalStep, globalStep => {
  const targetDocument = globalStep?.targetDocument
  const isCPF = targetDocument?.length === 11

  const targetName = globalStep.targetName
  return {
    targetName: targetName,
    targetDocument,
    isCPF
  }
})
