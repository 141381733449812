import { put, select } from 'redux-saga/effects'
import { toast } from 'react-toastify'

import { Types as PatrimonioThemeTypes } from '../store/patrimonioTheme'
import { Types as DadosBasicosThemeTypes } from '../store/dadosBasicosTheme'
import { Types as OffshoreThemeTypes } from '../store/offshoreTheme'
import { Types as EndividamentoThemeTypes } from '../store/endividamentoTheme'
import { Types as LogSulThemeTypes } from '../store/logsulTheme'
import { Types as JuridicoThemeTypes } from '../store/juridicoTheme'
import { Types as OperacionaisThemeTypes } from '../store/operacionaisTheme'
import { Types as BandeiraAmarelaThemeTypes } from '../store/bandeiraAmarelaTheme'
import { Types as SpreadThemeTypes } from '../store/spreadTheme'
import { Types as VisitasThemeTypes } from '../store/visitasTheme'
import { Types as RiscoJuridicoThemeTypes } from '../store/riscoJuridicoTheme'
import { Types as MatchGrupoSocietarioThemeTypes } from '../store/matchGrupoSocietarioTheme'
import { Types as GlobalStepTypes } from '../store/globalStep'
import { Types as DiscreditingMediaThemeTypes } from '../store/discreditingMediaTheme'

import { Types as GrafoTypes } from '../reducers/grafo'
import { Types as GoogleMapsTypes } from '../reducers/googleMaps'

import { setStatusProgressStatus } from '../store/progressStatus'

import { PROGRESS_TYPES, resolveProgressStatus } from '../../lib/progressStatus'

export function * listenerProgressStatus ({ type, payload }) {
  try {
    switch (type) {
      case GlobalStepTypes.SET_ERROR:
      case GlobalStepTypes.FINISHED: {
        const globalStepStatus = yield select(
          state => state.progressStatus.dataStatus.globalStep
        )
        const dataStatus = yield select(
          state => state.progressStatus.dataStatus
        )
        const array = Object.entries(dataStatus)
          .filter(
            ([key, value]) =>
              [PROGRESS_TYPES.PENDING, null].includes(value) &&
              key !== 'globalStep'
          )
          .map(([key]) => [key, PROGRESS_TYPES.ERROR])

        const newDataStatus = {
          ...Object.fromEntries(array),
          globalStep:
            globalStepStatus === PROGRESS_TYPES.ERROR
              ? PROGRESS_TYPES.ERROR
              : type === GlobalStepTypes.FINISHED
              ? PROGRESS_TYPES.LOADED
              : PROGRESS_TYPES.ERROR
        }
        yield put(setStatusProgressStatus(newDataStatus))
        break
      }
      case PatrimonioThemeTypes.SET_S3_PATH: {
        const aeronavesPatrimonioTheme = resolveProgressStatus(
          payload.aeronaves
        )
        const sncrPatrimonioTheme = resolveProgressStatus(payload.sncr)
        const sigefPatrimonioTheme = resolveProgressStatus(payload.sigef)
        const ibamaPatrimonioTheme = resolveProgressStatus(payload.ibama)
        const inpiPatrimonioTheme = resolveProgressStatus(payload.inpi)

        yield put(
          setStatusProgressStatus({
            aeronavesPatrimonioTheme,
            sigefPatrimonioTheme,
            ibamaPatrimonioTheme,
            sncrPatrimonioTheme,
            inpiPatrimonioTheme
          })
        )
        break
      }
      case PatrimonioThemeTypes.SET_ERROR:
        yield put(
          setStatusProgressStatus({
            aeronavesPatrimonioTheme: PROGRESS_TYPES.ERROR,
            sigefPatrimonioTheme: PROGRESS_TYPES.ERROR,
            ibamaPatrimonioTheme: PROGRESS_TYPES.ERROR,
            sncrPatrimonioTheme: PROGRESS_TYPES.ERROR,
            inpiPatrimonioTheme: PROGRESS_TYPES.ERROR
          })
        )
        break
      case SpreadThemeTypes.SET_ERROR:
        yield put(
          setStatusProgressStatus({
            spreadTheme: PROGRESS_TYPES.ERROR
          })
        )
        break
      case LogSulThemeTypes.SET_S3_PATH: {
        const logsulTheme = resolveProgressStatus(payload)

        yield put(setStatusProgressStatus({ logsulTheme }))
        break
      }
      case LogSulThemeTypes.SET_ERROR:
        yield put(
          setStatusProgressStatus({
            logsulTheme: PROGRESS_TYPES.ERROR
          })
        )
        break
      case SpreadThemeTypes.SET_S3_PATH: {
        const spreadTheme = resolveProgressStatus(payload)

        yield put(
          setStatusProgressStatus({
            spreadTheme
          })
        )
        break
      }
      case OperacionaisThemeTypes.SET_S3_PATH: {
        const operacionaisTheme = resolveProgressStatus(payload)
        yield put(setStatusProgressStatus({ operacionaisTheme }))
        break
      }
      case OperacionaisThemeTypes.SET_ERROR:
        yield put(
          setStatusProgressStatus({ operacionaisTheme: PROGRESS_TYPES.ERROR })
        )
        break
      case VisitasThemeTypes.SET_ERROR:
        yield put(
          setStatusProgressStatus({
            visitasTheme: PROGRESS_TYPES.ERROR
          })
        )
        break
      case VisitasThemeTypes.SET_S3_PATH: {
        const visitasTheme = resolveProgressStatus(payload)

        yield put(
          setStatusProgressStatus({
            visitasTheme
          })
        )
        break
      }
      case RiscoJuridicoThemeTypes.SET_ERROR:
        yield put(
          setStatusProgressStatus({
            riscoJuridicoTheme: PROGRESS_TYPES.ERROR
          })
        )
        break
      case RiscoJuridicoThemeTypes.SET_S3_PATH: {
        const riscoJuridicoTheme = resolveProgressStatus(payload)

        yield put(
          setStatusProgressStatus({
            riscoJuridicoTheme
          })
        )
        break
      }
      case DadosBasicosThemeTypes.SET_S3_PATH: {
        const dadosBasicosTheme = resolveProgressStatus(payload)
        yield put(
          setStatusProgressStatus({
            dadosBasicosTheme
          })
        )
        break
      }
      case DadosBasicosThemeTypes.SET_ERROR:
        yield put(
          setStatusProgressStatus({
            dadosBasicosTheme: PROGRESS_TYPES.ERROR
          })
        )
        break
      case BandeiraAmarelaThemeTypes.SET_S3_PATH: {
        const trabalhoEscravoBandeiraAmarela = resolveProgressStatus(
          payload.trabalhoEscravo
        )
        const pepBandeiraAmarela = resolveProgressStatus(payload.pep)
        const acordosLenienciaBandeiraAmarela = resolveProgressStatus(
          payload.acordosLeniencia
        )
        const autoInfracaoBandeiraAmarela = resolveProgressStatus(
          payload.autoInfracao
        )
        const cepimBandeiraAmarela = resolveProgressStatus(payload.cepim)
        const ofacBandeiraAmarela = resolveProgressStatus(payload.ofac)
        const termoApreensaoBandeiraAmarela = resolveProgressStatus(
          payload.termoApreensao
        )
        const termoEmbargoBandeiraAmarela = resolveProgressStatus(
          payload.termoEmbargo
        )
        const termoSuspensaoBandeiraAmarela = resolveProgressStatus(
          payload.termoSuspensao
        )
        const cadastroExpulsoesBandeiraAmarela = resolveProgressStatus(
          payload.cadastroExpulsoes
        )
        const cnepBandeiraAmarela = resolveProgressStatus(payload.cnep)
        const ceisBandeiraAmarela = resolveProgressStatus(payload.ceis)
        const ceafBandeiraAmarela = resolveProgressStatus(payload.ceaf)

        yield put(
          setStatusProgressStatus({
            trabalhoEscravoBandeiraAmarela,
            pepBandeiraAmarela,
            acordosLenienciaBandeiraAmarela,
            autoInfracaoBandeiraAmarela,
            cepimBandeiraAmarela,
            ofacBandeiraAmarela,
            termoApreensaoBandeiraAmarela,
            termoEmbargoBandeiraAmarela,
            termoSuspensaoBandeiraAmarela,
            cadastroExpulsoesBandeiraAmarela,
            cnepBandeiraAmarela,
            ceisBandeiraAmarela,
            ceafBandeiraAmarela
          })
        )
        break
      }
      case BandeiraAmarelaThemeTypes.SET_ERROR:
        yield put(
          setStatusProgressStatus({
            trabalhoEscravoBandeiraAmarela: PROGRESS_TYPES.ERROR,
            pepBandeiraAmarela: PROGRESS_TYPES.ERROR,
            acordosLenienciaBandeiraAmarela: PROGRESS_TYPES.ERROR,
            autoInfracaoBandeiraAmarela: PROGRESS_TYPES.ERROR,
            cepimBandeiraAmarela: PROGRESS_TYPES.ERROR,
            ofacBandeiraAmarela: PROGRESS_TYPES.ERROR,
            termoApreensaoBandeiraAmarela: PROGRESS_TYPES.ERROR,
            termoEmbargoBandeiraAmarela: PROGRESS_TYPES.ERROR,
            termoSuspensaoBandeiraAmarela: PROGRESS_TYPES.ERROR,
            cadastroExpulsoesBandeiraAmarela: PROGRESS_TYPES.ERROR,
            cnepBandeiraAmarela: PROGRESS_TYPES.ERROR,
            ceisBandeiraAmarela: PROGRESS_TYPES.ERROR,
            ceafBandeiraAmarela: PROGRESS_TYPES.ERROR
          })
        )
        break
      case MatchGrupoSocietarioThemeTypes.SET_S3_PATH: {
        const email = resolveProgressStatus(payload.email)
        yield put(
          setStatusProgressStatus({
            matchGrupoSocietarioTheme: email
          })
        )
        break
      }
      case MatchGrupoSocietarioThemeTypes.SET_ERROR:
        yield put(
          setStatusProgressStatus({
            matchGrupoSocietarioTheme: PROGRESS_TYPES.ERROR
          })
        )
        break
      case OffshoreThemeTypes.SET_S3_PATH: {
        const dataLeaksOffshore = resolveProgressStatus(payload.offshoreLeaks)
        const rdeOffshore = resolveProgressStatus(payload.rde)

        yield put(setStatusProgressStatus({ dataLeaksOffshore, rdeOffshore }))
        break
      }
      case OffshoreThemeTypes.SET_ERROR:
        yield put(
          setStatusProgressStatus({
            dataLeaksOffshore: PROGRESS_TYPES.ERROR,
            rdeOffshore: PROGRESS_TYPES.ERROR
          })
        )
        break
      case DiscreditingMediaThemeTypes.SET_S3_PATH: {
        const discreditingNewsTheme = resolveProgressStatus(
          payload.discreditingNews
        )
        yield put(
          setStatusProgressStatus({
            discreditingNewsTheme
          })
        )
        break
      }
      case DiscreditingMediaThemeTypes.SET_ERROR: {
        yield put(
          setStatusProgressStatus({
            discreditingNews: PROGRESS_TYPES.ERROR
          })
        )
        break
      }
      case EndividamentoThemeTypes.SET_S3_PATH: {
        const chequeEndividamentoTheme = resolveProgressStatus(payload.cheque)
        const cndtEndividamentoTheme = resolveProgressStatus(payload.cndt)
        const pgfnEndividamentoTheme = resolveProgressStatus(payload.pgfn)
        const comprotEndividamentoTheme = resolveProgressStatus(payload.comprot)
        const protestoEndividamentoTheme = resolveProgressStatus(
          payload.protesto
        )

        yield put(
          setStatusProgressStatus({
            chequeEndividamentoTheme,
            cndtEndividamentoTheme,
            pgfnEndividamentoTheme,
            comprotEndividamentoTheme,
            protestoEndividamentoTheme
          })
        )
        break
      }
      case EndividamentoThemeTypes.SET_ERROR:
        yield put(
          setStatusProgressStatus({
            chequeEndividamentoTheme: PROGRESS_TYPES.ERROR,
            cndtEndividamentoTheme: PROGRESS_TYPES.ERROR,
            pgfnEndividamentoTheme: PROGRESS_TYPES.ERROR,
            comprotEndividamentoTheme: PROGRESS_TYPES.ERROR,
            protestoEndividamentoTheme: PROGRESS_TYPES.ERROR
          })
        )
        break
      case JuridicoThemeTypes.FINISHED: {
        const relateds = yield select(state => state.juridicoTheme.relateds)
        const relatedsWithError = relateds.filter(
          related => related.status === PROGRESS_TYPES.ERROR
        )
        const juridicoTheme =
          relatedsWithError.length === 0
            ? PROGRESS_TYPES.LOADED
            : relateds.length === relatedsWithError
            ? PROGRESS_TYPES.ERROR
            : PROGRESS_TYPES.PARTIAL_ERROR

        yield put(
          setStatusProgressStatus({
            juridicoTheme
          })
        )
        break
      }

      case GrafoTypes.SET_LINK:
        yield put(setStatusProgressStatus({ linkGrafo: PROGRESS_TYPES.LOADED }))
        break
      case GrafoTypes.SET_ERROR_LINK:
        yield put(setStatusProgressStatus({ linkGrafo: PROGRESS_TYPES.ERROR }))
        break
      case GoogleMapsTypes.SET_IMAGES:
        yield put(
          setStatusProgressStatus({ googleMaps: PROGRESS_TYPES.LOADED })
        )
        break
      case GoogleMapsTypes.SET_ERROR_IMAGES:
        yield put(setStatusProgressStatus({ googleMaps: PROGRESS_TYPES.ERROR }))
        break
      default:
        console.warn(`listenerProgressStatus: Tipo não esperado ${type}`)
    }
  } catch (err) {
    console.error(`${type}:${err.message}`, err)
    toast.error(`${type}:${err.message}`)
  }
}
