import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as styles from './style.module.scss'

import questionnaires from '../../../../../../questionnaires/comportamental'

import { selectMinData } from '../../../../../../redux/selectors/dadosBasicos'
import { setScoreComportamentalSulBrasil } from '../../../../../../redux/store/sulbrasil'

import { PROGRESS_TYPES } from '../../../../../../lib/progressStatus'

import { hashObject } from '../../../../../../utils'
import { useSulbarsilVisitas } from '../../../../../../utils/hooks'

import { Loading } from '../../../../../../components'

const computeScore = ({ values, defaultValues }) => {
  const mergedValues = Object.entries(values).map(([key, value]) => [
    key,
    value ?? defaultValues[key]
  ])

  const array = mergedValues.sort((a, b) => {
    const aIndex = questionnaires.findIndex(item => item.id === a[0])
    const bIndex = questionnaires.findIndex(item => item.id === b[0])
    return aIndex - bIndex
  })

  const arrayValues = array
    .map(([, value]) => value)
    .filter(value => typeof value === 'number')

  if (arrayValues.length !== questionnaires.length) {
    return 'Informações insuficientes para o cálculo de risco'
  }

  const total = arrayValues.reduce((acc, value) => acc + value, 0)

  if (total <= 5) {
    return 'Risco Baixo'
  } else if (total > 10) {
    return 'Risco Alto'
  } else {
    return 'Risco Moderado'
  }
}

export default function ScoreComportamental () {
  const { targetDocument } = useSelector(selectMinData)
  const form = useSelector(
    state => state.sulbrasil.formulario.scoreComportamental.form
  )
  const { data: dadosVisitas, status: statusVisitas } = useSulbarsilVisitas()
  const dispatch = useDispatch()

  const values = useMemo(() => {
    return (form || []).reduce((obj, item) => {
      obj[item.id] = item.value
      return obj
    }, {})
  }, [form])

  const defaultValues = useMemo(() => {
    const parsePreFaturamento = () => {
      const temPreFaturamento = dadosVisitas?.preFaturamento === 'Sim'
      const naoTemPreFaturamento = dadosVisitas?.preFaturamento === 'Não'
      const diasPreFaturamento =
        typeof dadosVisitas?.preFaturamentoDias === 'string'
          ? parseInt(dadosVisitas?.preFaturamentoDias)
          : undefined

      if (naoTemPreFaturamento) {
        return 0
      }

      if (
        !temPreFaturamento ||
        !diasPreFaturamento ||
        Number.isNaN(diasPreFaturamento)
      ) {
        return undefined
      }

      if (diasPreFaturamento <= 3) {
        return 1
      } else if (diasPreFaturamento <= 8) {
        return 2
      } else if (diasPreFaturamento <= 15) {
        return 3
      } else {
        return 4
      }
    }

    const parseTransporte = () => {
      switch (dadosVisitas?.sistemaEntrega) {
        case 'FOB':
          return 0
        case 'CIF':
          return 3
        default:
          return undefined
      }
    }

    const preFaturamento = parsePreFaturamento()
    const transporte = parseTransporte()
    return {
      preFaturamento,
      transporte
    }
  }, [dadosVisitas])

  const result = useMemo(() => {
    return computeScore({ values, defaultValues })
  }, [values, defaultValues])

  if (!targetDocument) {
    return <div />
  }

  if ([statusVisitas].some(status => status === PROGRESS_TYPES.PENDING)) {
    return <Loading />
  }

  const onChange = nextObj => {
    const nextValues = { ...values, ...nextObj }

    const formValues = questionnaires.reduce((obj, { id }) => {
      obj[id] = nextValues[id] ?? defaultValues[id]
      return obj
    }, {})

    const result = computeScore({ values: formValues, defaultValues })
    dispatch(setScoreComportamentalSulBrasil({ form: formValues, result }))
  }

  return (
    <>
      <div>
        {questionnaires.map((questionnaire, questionIndex) => {
          const name = questionnaire.id
          const questionKey = hashObject({
            key: 'comportamental',
            questionnaire,
            questionIndex
          })
          return (
            <div className='my-4' key={questionKey}>
              <div className={styles.question}>
                {questionIndex + 1} - {questionnaire.question}
              </div>
              <div className='d-flex gap-4 mt-2'>
                {questionnaire.answers.map((answer, answerIndex) => {
                  const id = hashObject({
                    questionKey,
                    answer,
                    questionIndex,
                    answerIndex
                  })
                  return (
                    <div className='form-check' key={id}>
                      <input
                        className='form-check-input'
                        type='radio'
                        name={id}
                        id={id}
                        value={answer.value}
                        onChange={event => {
                          if (onChange) {
                            onChange({
                              [name]: parseInt(event.target.value)
                            })
                          }
                        }}
                        checked={
                          (values?.[name] ?? defaultValues[name]) ===
                          answer.value
                        }
                      />
                      <label className='form-check-label' htmlFor={id}>
                        {answer.text}
                      </label>
                    </div>
                  )
                })}
              </div>
              <hr />
            </div>
          )
        })}
      </div>
      <div>
        <div className='h4 text-center'>{result}</div>
      </div>
    </>
  )
}
