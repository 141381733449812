import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'

import { FiSearch } from 'react-icons/fi'

import searchDocument from '../../lib/searchDocument'

import * as styles from './style.module.scss'

import { getCurrentUser, isCPF } from '../../utils'
import { EXCLUSIVE_CONTENTS, StaticScopeMetadata } from '../../lib/scope'

export default function SubHeader () {
  const dispatch = useDispatch()
  const history = useHistory()

  const [data, setData] = useState({
    documento: ''
  })

  const handleSearch = async () => {
    const { documento } = data
    const isCpf = isCPF(documento)

    const hasSerasaPermission =
      currentReport === EXCLUSIVE_CONTENTS.FEATURES.REPORT_PAGE
    const outputSerasa = hasSerasaPermission
      ? await askSerasa()
      : { isConfirmed: false, isDismissed: false }
    const outputPartners = !isCpf
      ? await askLookForPartners()
      : { isConfirmed: true, isDismissed: false }

    if (outputSerasa.isDismissed || outputPartners.isDismissed) {
      return
    }

    const user = await getCurrentUser()

    searchDocument({
      documento,
      enableSerasa: outputSerasa.isConfirmed,
      enablePartners: outputPartners.isConfirmed,
      user,
      dispatch
    })
    const hasFormPermission = StaticScopeMetadata.getAvailablePages().includes(
      EXCLUSIVE_CONTENTS.PAGES.FORMULARIO
    )

    if (hasFormPermission) {
      const basePath =
        StaticScopeMetadata.getAvailableApps().length > 1 ? '/dossie' : ''
      history.push(`${basePath}/formulario`)
    }
  }

  const currentReport = StaticScopeMetadata.getAvailableFeatures()?.report

  return (
    <div
      className={`pl-3 mr-3 d-flex flex-row align-items-center ${styles.container}`}
    >
      <div className={`flex-grow-1 ${styles.header}`}>Dossiê 2.0</div>
      <div>
        <div className={styles.input}>
          <input
            type='string'
            placeholder='Nova Pesquisa'
            onChange={event =>
              setData(prev => ({ ...prev, documento: event.target.value }))
            }
            value={data.documento}
            onKeyDown={e => {
              if (e.code === 'Enter') {
                handleSearch()
              }
            }}
          />
          <button className='btn' type='button' onClick={() => handleSearch()}>
            <FiSearch color='#0045c8' size={20} />
          </button>
        </div>
      </div>
    </div>
  )
}

const askSerasa = async () => {
  let isConfirmed = false
  let isDismissed = true

  const output = await Swal.fire({
    title: 'Você deseja utilizar as informações do Serasa?',
    text:
      'A consulta que utilize as informações do Serasa possuem um custo adicional que é cobrado com base no número de pessoas relacionadas',
    icon: 'warning',
    showDenyButton: true,
    focusDeny: true, // foca por padrão no botão negativo para que não faça a busca no Serasa se a pessoa digitar o número do documento e apertar ENTER
    confirmButtonColor: '#3085d6',
    denyButtonColor: '#d33',
    confirmButtonText: 'Utilizar',
    denyButtonText: 'Não utilizar'
  })

  isConfirmed = output.isConfirmed
  isDismissed = output.isDismissed

  return {
    isConfirmed,
    isDismissed
  }
}

const askLookForPartners = async () => {
  let isConfirmed = false
  let isDismissed = true

  const output = await Swal.fire({
    title: 'Você deseja buscar familiares dos sócios?',
    text:
      'Ao buscar por familiares dos sócios, você poderá encontrar informações como empresas familiares',
    icon: 'warning',
    showDenyButton: true,
    focusDeny: true, // foca por padrão no botão negativo para que não faça a busca por familiares se a pessoa digitar o número do documento e apertar ENTER
    confirmButtonColor: '#3085d6',
    denyButtonColor: '#d33',
    confirmButtonText: 'Buscar',
    denyButtonText: 'Não buscar'
  })

  isConfirmed = output.isConfirmed
  isDismissed = output.isDismissed

  return {
    isConfirmed,
    isDismissed
  }
}
