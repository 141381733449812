// Action Types
export const Types = {
  GERAR_DOCUMENTO: 'docs/GERAR_DOCUMENTO',
  SET_LOADING: 'docs/SET_LOADING',
  SET_ERROR: 'docs/SET_ERROR',
  SET_DOCUMENTO: 'docs/SET_DOCUMENTO',
  SET_FILE_NAME: 'docs/SET_FILE_NAME',
  SET_EXECUTION_ARN: 'docs/SET_EXECUTION_ARN',
  SAVE_DOCX: 'docs/SAVE_DOCX',
  RESET: 'docs/RESET'
}

export const INITIAL_STATE = {
  executionArn: undefined,
  loading: false,
  error: false,
  filename: undefined
}

export default function reducer (state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_EXECUTION_ARN:
      return {
        ...state,
        executionArn: action.payload
      }
    case Types.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case Types.SET_ERROR:
      return {
        ...state,
        ERROR: action.payload
      }
    case Types.SET_FILE_NAME:
      return {
        ...state,
        filename: action.payload
      }

    case Types.RESET:
      return INITIAL_STATE
    default:
      return state
  }
}

export const resetDocx = () => ({
  type: Types.RESET
})

export const saveDocx = ({ bucket, key, link }) => ({
  type: Types.SAVE_DOCX,
  payload: { bucket, key, link }
})

export const gerarDocumento = () => ({
  type: Types.GERAR_DOCUMENTO
})

export const setLoadingDocumento = data => ({
  type: Types.SET_LOADING,
  payload: data
})

export const setFileNameDocumento = data => ({
  type: Types.SET_FILE_NAME,
  payload: data
})

export const setExecutionArnDocumento = data => ({
  type: Types.SET_EXECUTION_ARN,
  payload: data
})
