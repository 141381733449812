import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { differenceInMonths, differenceInYears } from 'date-fns'

import * as styles from './style.module.scss'

import questionnaires from '../../../../../../questionnaires/prospeccao'
import { selectMinData } from '../../../../../../redux/selectors/dadosBasicos'

import { PROGRESS_TYPES } from '../../../../../../lib/progressStatus'

import {
  useDadosBasicos,
  useMatchGrupoSocietario
} from '../../../../../../utils/hooks'
import { hashObject } from '../../../../../../utils'

import { Loading } from '../../../../../../components'
import { setScoreProspeccaoSulBrasil } from '../../../../../../redux/store/sulbrasil'

const NATUREZA_JURIDICA_EIRELI = ['2305', '2313']
const NATUREZA_JURIDICA_MEI_EI = ['2135']

const computeScore = ({ values, defaultValues }) => {
  const mergedValues = Object.entries(values).map(([key, value]) => [
    key,
    value ?? defaultValues[key]
  ])

  const array = mergedValues.sort((a, b) => {
    const aIndex = questionnaires.findIndex(item => item.id === a[0])
    const bIndex = questionnaires.findIndex(item => item.id === b[0])
    return aIndex - bIndex
  })
  const arrayValues = array
    .map(([, value]) => value)
    .filter(value => typeof value === 'number')

  if (arrayValues.length !== questionnaires.length) {
    return 'Informações insuficientes para o cálculo de risco'
  }

  const total = arrayValues.reduce((acc, value) => acc + value, 0)

  if (total <= 5) {
    return 'Risco Baixo'
  } else if (total > 10) {
    return 'Risco Alto'
  } else {
    return 'Risco Moderado'
  }
}

export default function ScoreProspeccao () {
  const dispatch = useDispatch()
  const { targetDocument, isCPF } = useSelector(selectMinData)
  const { data: dadosBasicos, status: statusDadosBasicos } = useDadosBasicos()
  const {
    data: { email: matchesEmail },
    status: statusMatchGrupoSocietario
  } = useMatchGrupoSocietario(['email'])
  const form = useSelector(
    state => state.sulbrasil.formulario.scoreProspeccao.form
  )

  const values = useMemo(() => {
    return (form || []).reduce((obj, item) => {
      obj[item.id] = item.value
      return obj
    }, {})
  }, [form])

  const defaultValues = useMemo(() => {
    if (isCPF) {
      return {
        empresaEireli: undefined,
        entradaRecenteSocioEmpresa: undefined,
        fundacaoInferior5Anos: undefined,
        enderecoEletronico: undefined
      }
    }

    const today = new Date()
    const socios = dadosBasicos?.socios?.receitaFederal || []
    const maxDataEntrada = new Date(
      Math.max.apply(
        null,
        socios.map(socio => new Date(socio.dataEntradaSociedade))
      )
    )
    const dataAberturaEmpresa = new Date(
      dadosBasicos?.dadosBasicos?.datas.dataInicioAtividade || Infinity
    )

    const perguntaEireliOrMeiOrEi = dadosBasicos?.dadosBasicos?.codigos
      ?.naturezaJuridica
      ? [NATUREZA_JURIDICA_EIRELI, NATUREZA_JURIDICA_MEI_EI]
          .flat()
          .includes(dadosBasicos?.dadosBasicos?.codigos?.naturezaJuridica)
        ? 2
        : 0
      : undefined

    const targetEmail = dadosBasicos?.dadosBasicos?.correioEletronico || ''
    const targetRaizCnpj = (targetDocument || '').slice(0, 8)

    const filteredEmailsByCnpjRaiz = Object.entries(matchesEmail.data)
      .map(([key, items]) => {
        const filteredItems = items.filter(item =>
          (item.cnpj || '').startsWith(targetRaizCnpj)
        )
        return [key, filteredItems]
      })
      .filter(([, items]) => items.length > 0)
      .map(([email, _]) => email)

    const perguntaSameEmail =
      filteredEmailsByCnpjRaiz
        .concat(targetEmail)
        .filter(Boolean) // remove empty strings
        .filter(email => (matchesEmail.data || {})[email]?.length > 0).length > // remove emails that are not in the matches
      0

    let perguntaEmail
    if (perguntaSameEmail) {
      perguntaEmail = 3
    } else {
      perguntaEmail = 0
    }

    let perguntaDataUltimoSocio
    if (differenceInYears(today, maxDataEntrada) > 5) {
      // > 5
      perguntaDataUltimoSocio = 0
    } else if (differenceInYears(today, maxDataEntrada) >= 3) {
      // >=3 years and <=5 years
      perguntaDataUltimoSocio = 1
    } else if (differenceInYears(today, maxDataEntrada) >= 1) {
      // >=1 year and <3 years
      perguntaDataUltimoSocio = 2
    } else if (differenceInMonths(today, maxDataEntrada) >= 7) {
      // >=7 months  and <12 months
      perguntaDataUltimoSocio = 3
    } else if (differenceInMonths(today, maxDataEntrada) < 7) {
      // < 7 months
      perguntaDataUltimoSocio = 4
    }

    const perguntaAberturaEmAte5Anos = dataAberturaEmpresa
      ? differenceInYears(today, dataAberturaEmpresa) <= 5
        ? 4
        : 2
      : undefined

    return {
      empresaEireli: perguntaEireliOrMeiOrEi,
      entradaRecenteSocioEmpresa: perguntaDataUltimoSocio,
      fundacaoInferior5Anos: perguntaAberturaEmAte5Anos,
      enderecoEletronico: perguntaEmail
    }
  }, [dadosBasicos, matchesEmail, targetDocument, isCPF])

  const result = useMemo(() => {
    return computeScore({ values, defaultValues })
  }, [values, defaultValues])

  if (!targetDocument) {
    return <div />
  }

  if (
    [statusDadosBasicos, statusMatchGrupoSocietario].some(
      status => status === PROGRESS_TYPES.PENDING
    )
  ) {
    return <Loading />
  }

  const onChange = nextObj => {
    const nextValues = { ...values, ...nextObj }

    const formValues = questionnaires.reduce((obj, { id }) => {
      obj[id] = nextValues[id] ?? defaultValues[id]
      return obj
    }, {})

    const result = computeScore({ values: formValues, defaultValues })
    dispatch(setScoreProspeccaoSulBrasil({ form: formValues, result }))
  }

  return (
    <>
      <div>
        {questionnaires.map((questionnaire, questionIndex) => {
          const name = questionnaire.id
          const questionKey = hashObject({
            key: 'prospeccao',
            questionnaire,
            questionIndex
          })

          return (
            <div className='my-4' key={questionKey}>
              <div className={styles.question}>
                {questionIndex + 1} - {questionnaire.question}
              </div>
              <div className='d-flex gap-4 mt-2'>
                {questionnaire.answers.map((answer, answerIndex) => {
                  const id = hashObject({
                    questionKey,
                    answer,
                    questionIndex,
                    answerIndex
                  })
                  return (
                    <div className='form-check' key={id}>
                      <input
                        className='form-check-input'
                        type='radio'
                        name={id}
                        id={id}
                        value={answer.value}
                        onChange={event => {
                          if (onChange) {
                            onChange({
                              [name]: parseInt(event.target.value)
                            })
                          }
                        }}
                        checked={
                          (values?.[name] ?? defaultValues[name]) ===
                          answer.value
                        }
                      />
                      <label className='form-check-label' htmlFor={id}>
                        {answer.text}
                      </label>
                    </div>
                  )
                })}
              </div>
              <hr />
            </div>
          )
        })}
      </div>
      <div>
        <div className='h4 text-center'>{result}</div>
      </div>
    </>
  )
}
