import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import { useSelector } from 'react-redux'

import { CustomWindow, Table } from '../../../../../components'
import mapWithRelationship from '../../../../../lib/detectRelationship'
import { selectRelateds } from '../../../../../redux/selectors/relateds'
import {
  factoryDate,
  formatDate,
  formatDocument,
  hashObject,
  isCypheredDocument,
  isPending,
  onlyNumbers,
  resolveBoolean
} from '../../../../../utils'

const { SearchBar } = Search

export default function Pep ({ data, error, loaded }) {
  const relateds = useSelector(selectRelateds)

  const name = 'bandeiraAmarela.pep'

  if (isPending({ error, loaded })) {
    return (
      <CustomWindow title='PEP' name={name}>
        <div />
      </CustomWindow>
    )
  }

  if (error) {
    return (
      <CustomWindow title='PEP' name={name} defaultOpen>
        <div>Ops! Aconteceu um erro ao carregar os PEPs</div>
      </CustomWindow>
    )
  }

  return (
    <CustomWindow
      title='PEP'
      name={name}
      subtitle='Somente os PEP são exibidos'
    >
      <ToolkitProvider
        keyField='id'
        search
        columns={[
          { dataField: 'name', text: 'Nome' },
          {
            dataField: 'grau',
            text: 'Grau'
          },
          { dataField: 'document', text: 'CPF/CNPJ', searchable: false },
          { dataField: 'rawDocument', text: '', hidden: true },
          { dataField: 'primeiraOcorrencia', text: 'Primeira Ocorrência' },
          { dataField: 'ultimaOcorrencia', text: 'Última Ocorrência' },
          { dataField: 'orgao', text: 'Órgão' },
          { dataField: 'funcao', text: 'Função' }
        ].map(item => ({ ...item, align: 'center', headerAlign: 'center' }))}
        data={mapWithRelationship({
          array: data || [],
          documentKey: 'document',
          nameKey: 'name',
          relateds
        })
          .filter(item => {
            const isPep = (item.data || []).length > 0
            return isPep
          })
          .map((item, key) => {
            const id = hashObject({ item, key })

            const data = item.data || []
            const isPep = data.length > 0
            const mappedData = data.map(item => ({
              ...item,
              date: factoryDate(item.dataInicioExercicio)
            }))
            const mostRecentDate = Math.max.apply(
              null,
              mappedData.map(item => item.date)
            )
            const mostRecentItem = mappedData.find(
              item => item.date.getTime() === mostRecentDate
            )

            const {
              descricaoFuncao: funcao,
              nomeOrgao: orgao,
              dataInicioExercicio,
              dataFimExercicio
            } = mostRecentItem || {}
            return {
              ...item,
              id,
              primeiraOcorrencia: dataInicioExercicio
                ? formatDate(factoryDate(dataInicioExercicio))
                : '-',
              ultimaOcorrencia: dataFimExercicio
                ? formatDate(factoryDate(dataFimExercicio))
                : '-',
              isPep: resolveBoolean(isPep),
              grau: item.relationship,
              funcao: funcao || '-',
              orgao: orgao || '-',
              document: isCypheredDocument(item.document) ? (
                item.document
              ) : (
                <a
                  href={`?documento=${onlyNumbers(item.document)}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {formatDocument(item.document)}
                </a>
              )
            }
          })}
      >
        {props => (
          <>
            <div className='row'>
              <div className='col-12 text-lg-right'>
                <SearchBar {...props.searchProps} placeholder='Pesquisar' />
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <Table enablePagination {...props.baseProps} />
              </div>
            </div>
          </>
        )}
      </ToolkitProvider>
    </CustomWindow>
  )
}
