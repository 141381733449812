import React, { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { GrLike, GrDislike } from 'react-icons/gr'
import Popup from 'reactjs-popup'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import { toast } from 'react-toastify'

import 'reactjs-popup/dist/index.css'

import { selectMinData } from '../../../redux/selectors/dadosBasicos'
import { selectRelateds } from '../../../redux/selectors/relateds'

import { Content, CustomWindow, Loading, Table } from '../../../components'
import {
  onSortDate,
  onSortDocument,
  onSortNumber
} from '../../../components/Table/sort'

import {
  PHONE_FORMATTER,
  hashObject,
  formatDocument,
  onlyNumbers,
  resolveBoolean,
  formatCurrency,
  isPending
} from '../../../utils'

import { fetchEndividamentoFromS3 } from '../../../lib/s3Theme'
import mapWithRelationship from '../../../lib/detectRelationship'

const { SearchBar } = Search

const isEmpty = value => {
  return typeof value === 'undefined' || value === null
}

export default function Endividamento () {
  const endividamentoTheme = useSelector(state => state.endividamentoTheme)
  const { targetName, targetDocument } = useSelector(selectMinData)

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    cheque: { data: { target: [], group: [] }, loaded: false, error: false },
    cndt: { data: { target: [], group: [] }, loaded: false, error: false },
    comprot: { data: { target: [], group: [] }, loaded: false, error: false },
    pgfnFgts: { data: { target: [], group: [] }, loaded: false, error: false },
    pgfnPrevidenciario: {
      data: { target: [], group: [] },
      loaded: false,
      error: false
    },
    pgfnNaoPrevidenciario: {
      data: { target: [], group: [] },
      loaded: false,
      error: false
    },
    protesto: { data: { target: [], group: [] }, loaded: false, error: false }
  })

  const [grupoSelecinadoData, setGrupoSelecionadoData] = useState()
  const [visiblePopup, setVisiblePopup] = useState(false)
  const [visiblePopupTitulos, setVisiblePopupTitulos] = useState(false)
  const [cartorioSelecionado, setCartorioSelecionado] = useState(false)
  const [origin, setOrigin] = useState(undefined)

  const isStepPending =
    targetDocument &&
    (isPending(endividamentoTheme.cheque) ||
      isPending(endividamentoTheme.cndt) ||
      isPending(endividamentoTheme.pgfn) ||
      isPending(endividamentoTheme.comprot) ||
      isPending(endividamentoTheme.protesto))

  useEffect(() => {
    if (
      isPending(endividamentoTheme.cheque) ||
      isPending(endividamentoTheme.cndt) ||
      isPending(endividamentoTheme.pgfn) ||
      isPending(endividamentoTheme.comprot) ||
      isPending(endividamentoTheme.protesto)
    ) {
      return
    }

    setLoading(true)

    fetchEndividamentoFromS3({
      chequePayload: endividamentoTheme.cheque,
      cndtPayload: endividamentoTheme.cndt,
      pgfnPayload: endividamentoTheme.pgfn,
      comprotPayload: endividamentoTheme.comprot,
      protestoPayload: endividamentoTheme.protesto
    })
      .then(endividamento => {
        setData(endividamento)
      })
      .catch(error => {
        toast.error(error.message)
        console.error(error)
        setData({
          cheque: {
            data: { target: [], group: [] },
            loaded: false,
            error: true
          },
          cndt: { data: { target: [], group: [] }, loaded: false, error: true },
          comprot: {
            data: { target: [], group: [] },
            loaded: false,
            error: true
          },
          pgfnFgts: {
            data: { target: [], group: [] },
            loaded: false,
            error: true
          },
          pgfnPrevidenciario: {
            data: { target: [], group: [] },
            loaded: false,
            error: true
          },
          pgfnNaoPrevidenciario: {
            data: { target: [], group: [] },
            loaded: false,
            error: true
          },
          protesto: {
            data: { target: [], group: [] },
            loaded: false,
            error: true
          }
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [endividamentoTheme])

  const targetData = useMemo(() => {
    const chequeData = data.cheque.data.target
    const cndtData = data.cndt.data.target
    const fgtsData = data.pgfnFgts.data.target
    const naoPrevidenciarioData = data.pgfnNaoPrevidenciario.data.target
    const previdenciarioData = data.pgfnPrevidenciario.data.target
    const protestoData = data.protesto.data.target
    const comprotData = data.comprot.data.target

    const filteredFgts = fgtsData.filter(item => item.isInDebt)
    const filteredNaoPrevidenciario = naoPrevidenciarioData.filter(
      item => item.isInDebt
    )
    const filteredPrevidenciario = previdenciarioData.filter(
      item => item.isInDebt
    )

    const totalPgfn = {
      error:
        data.pgfnFgts.error ||
        data.pgfnPrevidenciario.error ||
        data.pgfnNaoPrevidenciario.error,
      loaded:
        data.pgfnFgts.loaded ||
        data.pgfnPrevidenciario.loaded ||
        data.pgfnNaoPrevidenciario.loaded,
      value: [filteredFgts, filteredNaoPrevidenciario, filteredPrevidenciario]
        .flat()
        .reduce((acc, item) => {
          return acc + parseFloat(item.valorConsolidado)
        }, 0)
    }

    const lengthComprot = (comprotData?.[0]?.processos || []).length

    return {
      comprot: {
        ...data.comprot,
        data: comprotData?.[0],
        total: lengthComprot
      },
      cheque: {
        ...data.cheque,
        data: chequeData?.[0]
      },
      cndt: {
        ...data.cndt,
        data: cndtData?.[0]
      },
      fgts: {
        ...data.pgfnFgts,
        data: filteredFgts
      },
      naoPrevidenciario: {
        ...data.pgfnNaoPrevidenciario,
        data: filteredNaoPrevidenciario
      },
      previdenciario: {
        ...data.pgfnPrevidenciario,
        data: filteredPrevidenciario
      },
      protesto: {
        ...data.protesto,
        data: protestoData?.[0]
      },
      totalPgfn
    }
  }, [data])

  const endividamentoDoGrupo = useMemo(() => {
    const chequeData = data.cheque.data.group
    const cndtData = data.cndt.data.group
    const fgtsData = data.pgfnFgts.data.group
    const naoPrevidenciarioData = data.pgfnNaoPrevidenciario.data.group
    const previdenciarioData = data.pgfnPrevidenciario.data.group
    const protestoData = data.protesto.data.group

    const chequesCnpj = chequeData.map(item => item.cpfCnpj)
    const cndtCnpj = cndtData.map(item => item.cpfCnpj)
    const fgtsCnpj = fgtsData.map(item => item.cpfCnpj)
    const naoPrevidenciarioCnpj = naoPrevidenciarioData.map(
      item => item.cpfCnpj
    )
    const protestoCnpj = protestoData.map(item => item.cpfCnpj)

    const documentos = Array.from(
      new Set([
        ...chequesCnpj,
        ...cndtCnpj,
        ...fgtsCnpj,
        ...naoPrevidenciarioCnpj,
        ...protestoCnpj
      ])
    ).filter(item => item !== targetDocument)

    return documentos
      .map(cpfCnpj => {
        const cheques = chequeData.filter(item => item.cpfCnpj === cpfCnpj)
        const cndts = cndtData.filter(item => item.cpfCnpj === cpfCnpj)
        const fgts = fgtsData.filter(
          item => item.cpfCnpj === cpfCnpj && item.isInDebt
        )
        const naoPrevidenciario = naoPrevidenciarioData.filter(
          item => item.cpfCnpj === cpfCnpj && item.isInDebt
        )
        const previdenciario = previdenciarioData.filter(
          item => item.cpfCnpj === cpfCnpj && item.isInDebt
        )
        const protestos = protestoData.filter(item => item.cpfCnpj === cpfCnpj)

        const nomes = Array.from([
          ...cheques.map(item => item.nomeRazaoSocial),
          ...cndts.map(item => item.name),
          ...cndts.map(item => item.name),
          ...fgts.map(item => item.name),
          ...naoPrevidenciario.map(item => item.name),
          ...previdenciario.map(item => item.name),
          ...protestos.map(item => item.name)
        ])

        return {
          cpfCnpj,
          nome: nomes?.[0] || undefined,
          cheque: {
            ...data.cheque,
            data: cheques?.[0]
          },
          cndt: {
            ...data.cndt,
            data: cndts?.[0]
          },
          fgts: {
            ...data.pgfnFgts,
            data: fgts
          },
          naoPrevidenciario: {
            ...data.pgfnNaoPrevidenciario,
            data: naoPrevidenciario
          },
          previdenciario: {
            ...data.pgfnPrevidenciario,
            data: previdenciario
          },
          protesto: {
            ...data.protesto,
            data: protestos?.[0]
          }
        }
      })
      .sort((a, b) => {
        const compareName = a.nome.localeCompare(b.nome)
        const compareDoc = a.cpfCnpj.localeCompare(b.cpfCnpj)

        return compareName === 0 ? compareDoc : compareName
      })
  }, [data, targetDocument])

  return (
    <>
      <Content
        title={`Endividamento ${targetName ? `- ${targetName}` : ''}`}
        subtitle='Busca por dívidas e direitos contra o Target.'
      >
        {loading || isStepPending ? (
          <Loading />
        ) : (
          <>
            <ResumoEndividamento
              protesto={targetData.protesto}
              cndt={targetData.cndt}
              cheque={targetData.cheque}
              comprot={targetData.comprot}
              totalPgfn={targetData.totalPgfn}
            />
            <div className='row'>
              <div className='col-12'>
                <TabelaPgfn targetData={targetData} />
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
                <Cartorios
                  protesto={targetData.protesto}
                  onClick={item => {
                    setVisiblePopup(false)
                    setCartorioSelecionado(item)
                    setVisiblePopupTitulos(true)
                    setOrigin('target')
                  }}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
                <EndividamentoDoGrupo
                  grupo={endividamentoDoGrupo}
                  onClick={data => {
                    setGrupoSelecionadoData(data)
                    setVisiblePopup(true)
                  }}
                />
                <PopupEndividamentoDoGrupo
                  selecionado={grupoSelecinadoData}
                  open={visiblePopup}
                  onClose={() => setVisiblePopup(false)}
                  onClick={item => {
                    setVisiblePopup(false)
                    setCartorioSelecionado(item)
                    setVisiblePopupTitulos(true)
                    setOrigin('grupo')
                  }}
                />
                <PopupTitulos
                  open={visiblePopupTitulos}
                  onClose={() => {
                    setVisiblePopupTitulos(false)
                    if (origin === 'grupo') {
                      setVisiblePopup(true)
                    }
                  }}
                  selecionado={cartorioSelecionado}
                />
              </div>
            </div>
          </>
        )}
      </Content>
    </>
  )
}

const ResumoEndividamento = ({
  protesto,
  cndt,
  cheque,
  totalPgfn,
  comprot
}) => {
  const name = 'endividamentos.resumoEndividamento'
  const { targetName, targetDocument, isCPF } = useSelector(selectMinData)

  if (
    isPending(protesto) &&
    isPending(comprot) &&
    isPending(cndt) &&
    isPending(cheque)
  ) {
    return (
      <CustomWindow title='Resumo' name={name}>
        <div />
      </CustomWindow>
    )
  }

  if (protesto.error && cndt.error && cheque.error) {
    return (
      <CustomWindow
        title={`Resumo ${targetName ? `- ${targetName}` : ''}`}
        subtitle={`${
          targetDocument ? (isCPF ? 'CPF:' : 'CNPJ:') : ''
        } ${formatDocument(targetDocument)}`}
        name={name}
        defaultOpen
      >
        <div>Ops! Aconteceu um erro ao carregar o resumo.</div>
      </CustomWindow>
    )
  }

  return (
    <CustomWindow
      title={`Resumo ${targetName ? `- ${targetName}` : ''}`}
      subtitle={`${
        targetDocument ? (isCPF ? 'CPF:' : 'CNPJ:') : ''
      } ${formatDocument(targetDocument)}`}
      name={name}
      defaultOpen
    >
      <div className='cardbase-body row justify-content-center'>
        {!protesto.error && (
          <>
            <div className='col-auto boxDash'>
              <div className='label'>Tem protestos?</div>
              <div className='value'>
                {protesto.data?.isInDebt ? (
                  <GrDislike size={45} style={{ color: 'red' }} />
                ) : (
                  <GrLike size={45} style={{ color: 'green' }} />
                )}
                <p style={{ fontSize: 12 }} className='my-0'>
                  {resolveBoolean(protesto.data?.isInDebt)}
                </p>
              </div>
            </div>

            <div className='col-auto boxDash'>
              <div className='label'>Nº de Protestos</div>
              <div className='value'>{protesto.data?.qtdTitulos || 0}</div>
            </div>

            <div className='col-auto boxDash'>
              <div className='label'>Valor Total Protestado</div>
              <div
                className='value'
                data-tip={
                  typeof protesto.data?.totalProtestado === 'number'
                    ? formatCurrency(protesto.data?.totalProtestado)
                    : '-'
                }
              >
                {typeof protesto.data?.totalProtestado === 'number'
                  ? formatCurrency(
                      protesto.data?.totalProtestado,
                      undefined,
                      undefined,
                      {
                        notation: 'compact'
                      }
                    )
                  : '-'}
              </div>
            </div>
          </>
        )}
        {!cndt.error && (
          <>
            <div className='col-auto boxDash'>
              <div className='label'>CNDT</div>
              <div className='value'>
                {cndt.data?.isInDebt ? (
                  <GrDislike size={45} style={{ color: 'green' }} />
                ) : (
                  <GrLike size={45} style={{ color: 'red' }} />
                )}
                <p style={{ fontSize: 12 }} className='my-0'>
                  {cndt.data?.isInDebt ? 'consta' : 'nada consta'}
                </p>
              </div>
            </div>
          </>
        )}
        {!cheque.error && (
          <>
            <div className='col-auto boxDash'>
              <div className='label'>Cheque sem fundo</div>
              <div className='value'>
                {cheque.data?.isInDebt ? (
                  <GrDislike size={45} style={{ color: 'green' }} />
                ) : (
                  <GrLike size={45} style={{ color: 'red' }} />
                )}
                <p style={{ fontSize: 12 }} className='my-0'>
                  {cheque.data?.isInDebt ? 'consta' : 'nada consta'}
                </p>
              </div>
            </div>
          </>
        )}
        {!totalPgfn.error && (
          <>
            <div className='col-auto boxDash'>
              <div className='label'>PGFN</div>
              <div className='value' data-tip={formatCurrency(totalPgfn.value)}>
                {formatCurrency(totalPgfn.value, undefined, undefined, {
                  notation: 'compact'
                })}
              </div>
            </div>
          </>
        )}
        {!comprot.error && (
          <>
            <div className='col-auto boxDash'>
              <div className='label'>Nº de processos Comprot </div>
              <div className='value'>{comprot.total}</div>
            </div>
          </>
        )}
      </div>
    </CustomWindow>
  )
}

const TabelaPgfn = React.memo(({ targetData }) => {
  const { fgts, naoPrevidenciario, previdenciario } = targetData

  const error = fgts.error || naoPrevidenciario.error || previdenciario.error

  const name = 'endividamentos.pgfn'

  if (
    isPending(fgts) ||
    isPending(naoPrevidenciario) ||
    isPending(previdenciario)
  ) {
    return (
      <CustomWindow title='PGFN' name={name}>
        <div />
      </CustomWindow>
    )
  }

  if (error) {
    return (
      <CustomWindow title='PGFN' name={name}>
        <div>Ops! Aconteceu um erro ao carregar as informações pgfn.</div>
      </CustomWindow>
    )
  }

  return (
    <CustomWindow title='PGFN' name={name}>
      {fgts && (
        <ToolkitProvider
          keyField='id'
          search
          columns={[
            {
              dataField: 'valorConsolidado',
              text: 'valor',
              sort: true,
              sortFunc: onSortNumber
            },
            {
              dataField: 'dataInscricao',
              text: 'data insc.',
              sort: true,
              sortFunc: onSortDate
            },
            { dataField: 'entidadeResponsavel', text: 'entidade', sort: true },
            { dataField: 'indicadorAjuizado', text: 'ajuizado', sort: true },
            {
              dataField: 'numeroInscricao',
              text: 'num. inscrição',
              sort: true
            },
            { dataField: 'receitaPrincipal', text: 'receita', sort: true },
            {
              dataField: 'situacaoInscricao',
              text: 'sit. inscrição',
              sort: true
            },
            { dataField: 'tipoDevedor', text: 'tipo', sort: true },
            { dataField: 'tipoPessoa', text: 'pessoa', sort: true },
            {
              dataField: 'tipoSituacaoInscricao',
              text: 'situação inscr.',
              sort: true
            },
            { dataField: 'ufUnidadeResponsavel', text: 'uf', sort: true },
            {
              dataField: 'unidadeInscricao',
              text: 'unidade inscr.',
              sort: true
            },
            {
              dataField: 'unidadeResponsavel',
              text: 'unidade resp.',
              sort: true
            }
          ]}
          data={(fgts.data || []).map((divida, key) => {
            const id = hashObject({ divida, key })
            return {
              id,
              valorConsolidado: divida.valorConsolidado,
              cpfCnpj: divida.cpfCnpj,
              dataInscricao: divida.dataInscricao,
              entidadeResponsavel: divida.entidadeResponsavel,
              indicadorAjuizado: divida.indicadorAjuizado,
              nomeDevedor: divida.nomeDevedor,
              numeroInscricao: divida.numeroInscricao,
              receitaPrincipal: divida.receitaPrincipal,
              situacaoInscricao: divida.situacaoInscricao,
              tipoDevedor: divida.tipoDevedor,
              tipoPessoa: divida.tipoPessoa,
              tipoSituacaoInscricao: divida.tipoSituacaoInscricao,
              ufUnidadeResponsavel: divida.ufUnidadeResponsavel,
              unidadeInscricao: divida.unidadeInscricao,
              unidadeResponsavel: divida.unidadeResponsavel
            }
          })}
        >
          {props => (
            <>
              <div className='text-uppercase font-weight-bold mt-1'>
                Dados Abertos FGTS
              </div>
              <div className='row'>
                <div className='col-12 text-lg-right'>
                  <SearchBar {...props.searchProps} placeholder='Pesquisar' />
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <Table enablePagination {...props.baseProps} />
                </div>
              </div>
            </>
          )}
        </ToolkitProvider>
      )}

      {naoPrevidenciario && (
        <ToolkitProvider
          keyField='id'
          search
          columns={[
            {
              dataField: 'valorConsolidado',
              text: 'valor',
              sort: true,
              sortFunc: onSortNumber
            },
            {
              dataField: 'dataInscricao',
              text: 'data insc.',
              sort: true,
              sortFunc: onSortDate
            },
            { dataField: 'entidadeResponsavel', text: 'entidade', sort: true },
            { dataField: 'indicadorAjuizado', text: 'ajuizado', sort: true },
            {
              dataField: 'numeroInscricao',
              text: 'num. inscrição',
              sort: true
            },
            { dataField: 'receitaPrincipal', text: 'receita', sort: true },
            {
              dataField: 'situacaoInscricao',
              text: 'sit. inscrição',
              sort: true
            },
            { dataField: 'tipoDevedor', text: 'tipo', sort: true },
            { dataField: 'tipoPessoa', text: 'pessoa', sort: true },
            {
              dataField: 'tipoSituacaoInscricao',
              text: 'situação inscr.',
              sort: true
            },
            { dataField: 'ufUnidadeResponsavel', text: 'uf', sort: true },
            {
              dataField: 'unidadeInscricao',
              text: 'unidade inscr.',
              sort: true
            },
            {
              dataField: 'unidadeResponsavel',
              text: 'unidade resp.',
              sort: true
            }
          ]}
          data={naoPrevidenciario.data.map((divida, key) => {
            const id = hashObject({ divida, key })
            return {
              id,
              valorConsolidado: divida.valorConsolidado,
              cpfCnpj: divida.cpfCnpj,
              dataInscricao: divida.dataInscricao,
              entidadeResponsavel: divida.entidadeResponsavel,
              indicadorAjuizado: divida.indicadorAjuizado,
              nomeDevedor: divida.nomeDevedor,
              numeroInscricao: divida.numeroInscricao,
              receitaPrincipal: divida.receitaPrincipal,
              situacaoInscricao: divida.situacaoInscricao,
              tipoDevedor: divida.tipoDevedor,
              tipoPessoa: divida.tipoPessoa,
              tipoSituacaoInscricao: divida.tipoSituacaoInscricao,
              ufUnidadeResponsavel: divida.ufUnidadeResponsavel,
              unidadeInscricao: divida.unidadeInscricao,
              unidadeResponsavel: divida.unidadeResponsavel
            }
          })}
        >
          {props => (
            <>
              <div className='mt-4 text-uppercase font-weight-bold mt-1'>
                Dados Abertos Não Previdenciário
              </div>
              <div className='row'>
                <div className='col-12 text-lg-right'>
                  <SearchBar {...props.searchProps} placeholder='Pesquisar' />
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <Table enablePagination {...props.baseProps} />
                </div>
              </div>
            </>
          )}
        </ToolkitProvider>
      )}

      {previdenciario && (
        <ToolkitProvider
          keyField='id'
          search
          columns={[
            {
              dataField: 'valorConsolidado',
              text: 'valor',
              sort: true,
              sortFunc: onSortNumber
            },
            {
              dataField: 'dataInscricao',
              text: 'data insc.',
              sort: true,
              sortFunc: onSortDate
            },
            { dataField: 'entidadeResponsavel', text: 'entidade', sort: true },
            { dataField: 'indicadorAjuizado', text: 'ajuizado', sort: true },
            {
              dataField: 'numeroInscricao',
              text: 'num. inscrição',
              sort: true
            },
            { dataField: 'receitaPrincipal', text: 'receita', sort: true },
            {
              dataField: 'situacaoInscricao',
              text: 'sit. inscrição',
              sort: true
            },
            { dataField: 'tipoDevedor', text: 'tipo', sort: true },
            { dataField: 'tipoPessoa', text: 'pessoa', sort: true },
            {
              dataField: 'tipoSituacaoInscricao',
              text: 'situação inscr.',
              sort: true
            },
            { dataField: 'ufUnidadeResponsavel', text: 'uf', sort: true },
            {
              dataField: 'unidadeInscricao',
              text: 'unidade inscr.',
              sort: true
            },
            {
              dataField: 'unidadeResponsavel',
              text: 'unidade resp.',
              sort: true
            }
          ]}
          data={previdenciario.data.map((divida, key) => {
            const id = hashObject({ divida, key })
            return {
              id,
              valorConsolidado: divida.valorConsolidado,
              cpfCnpj: divida.cpfCnpj,
              dataInscricao: divida.dataInscricao,
              entidadeResponsavel: divida.entidadeResponsavel,
              indicadorAjuizado: divida.indicadorAjuizado,
              nomeDevedor: divida.nomeDevedor,
              numeroInscricao: divida.numeroInscricao,
              receitaPrincipal: divida.receitaPrincipal,
              situacaoInscricao: divida.situacaoInscricao,
              tipoDevedor: divida.tipoDevedor,
              tipoPessoa: divida.tipoPessoa,
              tipoSituacaoInscricao: divida.tipoSituacaoInscricao,
              ufUnidadeResponsavel: divida.ufUnidadeResponsavel,
              unidadeInscricao: divida.unidadeInscricao,
              unidadeResponsavel: divida.unidadeResponsavel
            }
          })}
        >
          {props => (
            <>
              <div className='mt-4 text-uppercase font-weight-bold mt-1'>
                Dados Abertos Previdenciário
              </div>
              <div className='row'>
                <div className='col-12 text-lg-right'>
                  <SearchBar {...props.searchProps} placeholder='Pesquisar' />
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <Table enablePagination keyField='id' {...props.baseProps} />
                </div>
              </div>
            </>
          )}
        </ToolkitProvider>
      )}
    </CustomWindow>
  )
})

const Cartorios = React.memo(({ protesto, onClick }) => {
  const name = 'endividamentos.cartorios'
  if (isPending(protesto)) {
    return (
      <CustomWindow title='Cartórios' name={name}>
        <div />
      </CustomWindow>
    )
  }

  if (protesto.error) {
    return (
      <CustomWindow title='Cartórios' name={name}>
        <div>Ops! Ocorreu um erro ao carregar os dados dos cartórios.</div>
      </CustomWindow>
    )
  }

  return (
    <CustomWindow title='Cartórios' name={name}>
      <ToolkitProvider
        keyField='id'
        columns={[
          { dataField: 'codigoCartorio', text: '#', sort: true },
          { dataField: 'codigoCidade', text: 'Cod. Cid.', sort: true },
          { dataField: 'endereco', text: 'Endereço', sort: true },
          { dataField: 'nome', text: 'Nome', sort: true },
          { dataField: 'numProtestos', text: 'Protestos', sort: true },
          { dataField: 'telefone', text: 'Telefone', sort: true },
          { dataField: 'uf', text: 'UF', sort: true },
          { dataField: 'actions', text: '' }
        ]}
        data={(protesto?.data?.cartorio || []).map((item, key) => {
          const id = hashObject({ item, key })
          return {
            id,
            codigoCartorio: item.numeroCartorio,
            codigoCidade: item.codIbge,
            endereco: item.endereco,
            nome: item.nomeCartorio,
            numProtestos: item.qtdTitulos ?? 'FALHOU',
            telefone: PHONE_FORMATTER(item.telefone),
            uf: item.uf,
            actions: (
              <button
                className='btn btn-primary btn-sm'
                onClick={() => onClick(item)}
                disabled={item.error}
              >
                Ver títulos
              </button>
            )
          }
        })}
        search
      >
        {props => (
          <>
            <div className='row'>
              <div className='col-12 text-lg-right mt-1'>
                <SearchBar {...props.searchProps} placeholder='Pesquisar' />
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <Table enablePagination {...props.baseProps} />
              </div>
            </div>
          </>
        )}
      </ToolkitProvider>
    </CustomWindow>
  )
})

const EndividamentoDoGrupo = ({ grupo, onClick }) => {
  const relateds = useSelector(selectRelateds)
  const name = 'endividamentos.endividamentoDoGrupo'

  return (
    <CustomWindow title='Endividamento do grupo' name={name}>
      <ToolkitProvider
        keyField='id'
        data={mapWithRelationship({
          array: grupo || [],
          documentKey: 'cpfCnpj',
          nameKey: 'nome',
          relateds
        })
          .map(
            ({
              cpfCnpj,
              fgts,
              naoPrevidenciario,
              previdenciario,
              protesto,
              cheque,
              cndt,
              relationship,
              nome
            }) => ({
              nome,
              doc: cpfCnpj,
              fgts: fgts?.data,
              naoPrevidenciario: naoPrevidenciario?.data,
              previdenciario: previdenciario?.data,
              protesto: protesto?.data,
              cheque: cheque?.data,
              relationship,
              cndt: cndt?.data
            })
          )
          .map(data => {
            return {
              nome: data.nome,
              rawDocumento: formatDocument(data.doc),
              grau: data.relationship,
              doc: data.doc ? (
                <a
                  href={`?documento=${onlyNumbers(data.doc)}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {formatDocument(data.doc)}
                </a>
              ) : (
                '-'
              ),
              temProtestos: isEmpty(data.protesto?.isInDebt)
                ? '-'
                : data.protesto?.isInDebt
                ? 'Sim'
                : 'Não',
              numProtestos: isEmpty(data.protesto?.qtdTitulos)
                ? data.protesto?.isInDebt === false
                  ? 0
                  : '-'
                : data.protesto?.qtdTitulos,
              valorTotalProtestado: isEmpty(data.protesto?.totalProtestado)
                ? '-'
                : data.protesto?.totalProtestado.toFixed(2),
              temCartorios:
                data.protesto?.cartorio?.length > 0 ? (
                  <button
                    style={{
                      all: 'unset',
                      color: 'blue',
                      cursor: 'pointer',
                      textDecoration: 'underline'
                    }}
                    onClick={() => {
                      onClick({
                        type: 'cartorios',
                        data: data.protesto?.cartorio
                      })
                    }}
                  >
                    Sim
                  </button>
                ) : (
                  'Não'
                ),
              chequesSemFundo: isEmpty(data.cheque?.isInDebt)
                ? '-'
                : data.cheque?.isInDebt
                ? 'Sim'
                : 'Não',
              cndt: isEmpty(data.cndt?.isInDebt)
                ? '-'
                : data.cndt?.isInDebt
                ? 'Consta'
                : 'Não consta',
              fgts:
                data.fgts?.length > 0 ? (
                  <button
                    style={{
                      all: 'unset',
                      color: 'blue',
                      cursor: 'pointer',
                      textDecoration: 'underline'
                    }}
                    onClick={() => {
                      onClick({
                        type: 'fgts',
                        data: data.fgts
                      })
                    }}
                  >
                    Sim
                  </button>
                ) : (
                  'Não'
                ),
              naoPrev:
                data.naoPrevidenciario?.length > 0 ? (
                  <button
                    style={{
                      all: 'unset',
                      color: 'blue',
                      cursor: 'pointer',
                      textDecoration: 'underline'
                    }}
                    onClick={() => {
                      onClick({
                        type: 'naoPrevidenciarios',
                        data: data.naoPrevidenciario
                      })
                    }}
                  >
                    Sim
                  </button>
                ) : (
                  'Não'
                ),
              prev:
                data.previdenciario?.length > 0 ? (
                  <button
                    style={{
                      all: 'unset',
                      color: 'blue',
                      cursor: 'pointer',
                      textDecoration: 'underline'
                    }}
                    onClick={() => {
                      onClick({
                        type: 'previdenciarios',
                        data: data.previdenciario
                      })
                    }}
                  >
                    Sim
                  </button>
                ) : (
                  'Não'
                )
            }
          })}
        columns={[
          { dataField: 'nome', text: 'Nome', sort: true },
          {
            dataField: 'doc',
            text: 'DOC',
            searchable: false,
            sort: true,
            sortFunc: onSortDocument
          },
          { dataField: 'rawDocumento', hidden: true },
          {
            dataField: 'grau',
            text: 'Grau',
            sort: true
          },
          { dataField: 'temProtestos', text: 'Protesto', sort: true },
          {
            dataField: 'numProtestos',
            text: 'Nº de protestos',
            sort: true,
            sortFunc: onSortNumber
          },
          {
            dataField: 'valorTotalProtestado',
            text: 'Valor total',
            sort: true,
            sortFunc: onSortNumber
          },
          { dataField: 'temCartorios', text: 'Cartórios', sort: true },
          {
            dataField: 'chequesSemFundo',
            text: 'Cheques sem fundo',
            sort: true
          },
          { dataField: 'cndt', text: 'CNDT', sort: true },
          { dataField: 'fgts', text: 'PGFN.FGTS', sort: true },
          { dataField: 'naoPrev', text: 'PGFN.NAO PREV', sort: true },
          { dataField: 'prev', text: 'PGFN.PREV', sort: true }
        ].map(item => ({ ...item, align: 'center', headerAlign: 'center' }))}
        search
      >
        {props => (
          <>
            <div className='row'>
              <div className='col-12 text-lg-right'>
                <SearchBar {...props.searchProps} placeholder='Pesquisar' />
              </div>
              <div className='col-12'>
                <Table enablePagination {...props.baseProps} />
              </div>
            </div>
          </>
        )}
      </ToolkitProvider>
    </CustomWindow>
  )
}

const PopupEndividamentoDoGrupo = ({ selecionado, open, onClose, onClick }) => {
  return (
    <Popup
      closeOnDocumentClick
      modal
      nested
      contentStyle={{ maxHeight: '90vh', width: '95%', overflowY: 'scroll' }}
      open={open}
      onClose={onClose}
    >
      {selecionado?.type === 'fgts' && (
        <div>
          <div
            className='titleDividas'
            style={{ width: '100%', display: 'flex' }}
          >
            <span style={{ flex: 1 }}>Dados Abertos FGTS</span>
            <button
              style={{
                cursor: 'pointer',
                padding: '2px 5px',
                lineHeight: '20px',
                right: '-10px',
                top: '-10px',
                fontSize: '24px',
                background: '#ffffff',
                borderRadius: '18px',
                border: '1px solid #cfcece'
              }}
              onClick={onClose}
            >
              X
            </button>
          </div>
          <table className='table table-sm table-striped tableDividas'>
            <thead>
              <tr>
                <th scope='col'>valor</th>
                <th scope='col'>doc</th>
                <th scope='col'>data insc.</th>
                <th scope='col'>entidade</th>
                <th scope='col'>ajuizado</th>
                <th scope='col'>devedor</th>
                <th scope='col'>num. inscrição</th>
                <th scope='col'>receita</th>
                <th scope='col'>sit. inscrição</th>
                <th scope='col'>tipo</th>
                <th scope='col'>pessoa</th>
                <th scope='col'>situação inscr.</th>
                <th scope='col'>uf</th>
                <th scope='col'>unidade inscr.</th>
                <th scope='col'>unidade resp.</th>
              </tr>
            </thead>
            <tbody>
              {selecionado.data.map((divida, key) => (
                <tr key={key}>
                  <th scope='row'>{divida.valorConsolidado}</th>
                  <td>{divida.cpfCnpj}</td>
                  <td>{divida.dataInscricao}</td>
                  <td>{divida.entidadeResponsavel}</td>
                  <td>{divida.indicadorAjuizado}</td>
                  <td>{divida.nomeDevedor}</td>
                  <td>{divida.numeroInscricao}</td>
                  <td>{divida.receitaPrincipal}</td>
                  <td>{divida.situacaoInscricao}</td>
                  <td>{divida.tipoDevedor}</td>
                  <td>{divida.tipoPessoa}</td>
                  <td>{divida.tipoSituacaoInscricao}</td>
                  <td>{divida.ufUnidadeResponsavel}</td>
                  <td>{divida.unidadeInscricao}</td>
                  <td>{divida.unidadeResponsavel}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {selecionado?.type === 'cartorios' && (
        <div>
          <div
            className='titleDividas'
            style={{ width: '100%', display: 'flex' }}
          >
            <span style={{ flex: 1 }}>Cartórios</span>
            <button
              style={{
                cursor: 'pointer',
                padding: '2px 5px',
                lineHeight: '20px',
                right: '-10px',
                top: '-10px',
                fontSize: '24px',
                background: '#ffffff',
                borderRadius: '18px',
                border: '1px solid #cfcece'
              }}
              onClick={onClose}
            >
              X
            </button>
          </div>
          <table className='table table-sm table-striped col-12 table-cartorio'>
            <thead>
              <tr>
                <th scope='col'>#</th>
                <th scope='col'>Cod. Cid.</th>
                <th scope='col'>Endereço</th>
                <th scope='col'>Nome</th>
                <th scope='col'>Protestos</th>
                <th scope='col'>Telefone</th>
                <th scope='col'>UF</th>
                <th scope='col' />
              </tr>
            </thead>
            <tbody>
              {(selecionado?.data || []).map((item, key) => {
                const id = hashObject({ item, key })
                return (
                  <tr key={id}>
                    <th scope='row'>{item.numeroCartorio}</th>
                    <td>{item.codIbge}</td>
                    <td>{item.endereco}</td>
                    <td>{item.nomeCartorio}</td>
                    <td className='text-center'>{item.qtdTitulos}</td>
                    <td>{item.telefone}</td>
                    <td>{item.uf}</td>
                    <td>
                      <button
                        className='btn btn-primary btn-sm'
                        onClick={() => onClick(item)}
                      >
                        Ver títulos
                      </button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )}
      {selecionado?.type === 'previdenciarios' && (
        <div>
          <div
            className='titleDividas'
            style={{ width: '100%', display: 'flex' }}
          >
            <span style={{ flex: 1 }}>Dados abertos previdenciários</span>
            <button
              style={{
                cursor: 'pointer',
                padding: '2px 5px',
                lineHeight: '20px',
                right: '-10px',
                top: '-10px',
                fontSize: '24px',
                background: '#ffffff',
                borderRadius: '18px',
                border: '1px solid #cfcece'
              }}
              onClick={onClose}
            >
              X
            </button>
          </div>
          <table className='table table-sm table-striped tableDividas'>
            <thead>
              <tr>
                <th scope='col'>valor</th>
                <th scope='col'>doc</th>
                <th scope='col'>data insc.</th>
                <th scope='col'>entidade</th>
                <th scope='col'>ajuizado</th>
                <th scope='col'>devedor</th>
                <th scope='col'>num. inscrição</th>
                <th scope='col'>receita</th>
                <th scope='col'>sit. inscrição</th>
                <th scope='col'>tipo</th>
                <th scope='col'>pessoa</th>
                <th scope='col'>situação inscr.</th>
                <th scope='col'>uf</th>
                <th scope='col'>unidade inscr.</th>
                <th scope='col'>unidade resp.</th>
              </tr>
            </thead>
            <tbody>
              {selecionado?.data?.map((divida, key) => (
                <tr key={key}>
                  <th scope='row'>{divida.valorConsolidado}</th>
                  <td>{divida.cpfCnpj}</td>
                  <td>{divida.dataInscricao}</td>
                  <td>{divida.entidadeResponsavel}</td>
                  <td>{divida.indicadorAjuizado}</td>
                  <td>{divida.nomeDevedor}</td>
                  <td>{divida.numeroInscricao}</td>
                  <td>{divida.receitaPrincipal}</td>
                  <td>{divida.situacaoInscricao}</td>
                  <td>{divida.tipoDevedor}</td>
                  <td>{divida.tipoPessoa}</td>
                  <td>{divida.tipoSituacaoInscricao}</td>
                  <td>{divida.ufUnidadeResponsavel}</td>
                  <td>{divida.unidadeInscricao}</td>
                  <td>{divida.unidadeResponsavel}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {selecionado?.type === 'naoPrevidenciarios' && (
        <div>
          <div
            className='titleDividas'
            style={{ width: '100%', display: 'flex' }}
          >
            <span style={{ flex: 1 }}>Dados abertos não previdenciários</span>
            <button
              style={{
                cursor: 'pointer',
                padding: '2px 5px',
                lineHeight: '20px',
                right: '-10px',
                top: '-10px',
                fontSize: '24px',
                background: '#ffffff',
                borderRadius: '18px',
                border: '1px solid #cfcece'
              }}
              onClick={onClose}
            >
              X
            </button>
          </div>
          <table className='table table-sm table-striped tableDividas'>
            <thead>
              <tr>
                <th scope='col'>valor</th>
                <th scope='col'>doc</th>
                <th scope='col'>data insc.</th>
                <th scope='col'>entidade</th>
                <th scope='col'>ajuizado</th>
                <th scope='col'>devedor</th>
                <th scope='col'>num. inscrição</th>
                <th scope='col'>receita</th>
                <th scope='col'>sit. inscrição</th>
                <th scope='col'>tipo</th>
                <th scope='col'>pessoa</th>
                <th scope='col'>situação inscr.</th>
                <th scope='col'>uf</th>
                <th scope='col'>unidade inscr.</th>
                <th scope='col'>unidade resp.</th>
              </tr>
            </thead>
            <tbody>
              {selecionado?.data?.map((divida, key) => (
                <tr key={key}>
                  <th scope='row'>{divida.valorConsolidado}</th>
                  <td>{divida.cpfCnpj}</td>
                  <td>{divida.dataInscricao}</td>
                  <td>{divida.entidadeResponsavel}</td>
                  <td>{divida.indicadorAjuizado}</td>
                  <td>{divida.nomeDevedor}</td>
                  <td>{divida.numeroInscricao}</td>
                  <td>{divida.receitaPrincipal}</td>
                  <td>{divida.situacaoInscricao}</td>
                  <td>{divida.tipoDevedor}</td>
                  <td>{divida.tipoPessoa}</td>
                  <td>{divida.tipoSituacaoInscricao}</td>
                  <td>{divida.ufUnidadeResponsavel}</td>
                  <td>{divida.unidadeInscricao}</td>
                  <td>{divida.unidadeResponsavel}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </Popup>
  )
}

const PopupTitulos = ({ selecionado, open, onClose }) => {
  return (
    <Popup
      closeOnDocumentClick
      modal
      nested
      contentStyle={{ maxHeight: '90vh', width: '95%', overflowY: 'scroll' }}
      open={open}
      onClose={onClose}
    >
      <div>
        <div
          className='titleDividas'
          style={{ width: '100%', display: 'flex' }}
        >
          <span style={{ flex: 1 }}>Titulos</span>
          <button
            style={{
              cursor: 'pointer',
              padding: '2px 5px',
              lineHeight: '20px',
              right: '-10px',
              top: '-10px',
              fontSize: '24px',
              background: '#ffffff',
              borderRadius: '18px',
              border: '1px solid #cfcece'
            }}
            onClick={onClose}
          >
            X
          </button>
        </div>
        <table className='table table-sm table-striped col-12 table-cartorio'>
          <thead>
            <tr>
              <th scope='col'>Número chave</th>
              <th scope='col'>Data protesto</th>
              <th scope='col'>Data vencimento</th>
              <th scope='col'>Apresentante</th>
              <th scope='col'>Cedente</th>
              <th scope='col'>Tem anuência?</th>
              <th scope='col'>Valor</th>
            </tr>
          </thead>
          <tbody>
            {(selecionado?.titulos || []).map((item, key) => {
              const id = hashObject({ item, key })
              return (
                <tr key={id}>
                  <th scope='row'>{item.nmChave}</th>
                  <td>{item.dataProtesto}</td>
                  <td>{item.dataVencimento}</td>
                  <td>{item.nomeApresentante}</td>
                  <td>{item.nomeCedente}</td>
                  <td>
                    {item.temAnuencia?.toLowerCase() === 'true' ? 'Sim' : 'Não'}
                  </td>
                  <td>{item.valorProtestado}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </Popup>
  )
}
