const questionnaires = [
  {
    question: 'Pré faturamento',
    id: 'preFaturamento',
    answers: [
      { text: 'Não', value: 0 },
      { text: 'Até 3 Dias', value: 1 },
      { text: 'De 4 a 6 Dias', value: 2 },
      { text: 'De 7 a 15 Dias', value: 3 },
      { text: '> 16 Dias', value: 4 }
    ]
  },
  {
    question: 'Atraso na entrega da mercadoria',
    id: 'atrasoEntregaMercadoria',
    answers: [
      { text: 'Não', value: 0 },
      { text: 'Até 3 Dias', value: 1 },
      { text: 'De 4 a 8 Dias', value: 2 },
      { text: 'De 9 a 15 Dias', value: 3 },
      { text: '> 16 Dias', value: 4 }
    ]
  },
  {
    question: 'Transporte',
    id: 'transporte',
    answers: [
      { text: 'Sacado (FOB)', value: 0 },
      { text: 'Transportadora', value: 1 },
      { text: 'Misto (CIF e FOB)', value: 2 },
      { text: 'Próprio (CIF)', value: 3 }
    ]
  },
  {
    question: 'Telefones incorretos / dificuldade',
    id: 'telefonesIncorretosDificuldade',
    answers: [
      { text: 'Até 3% Da Tranche', value: 0 },
      { text: 'Entre 4% a 10% Da Tranche', value: 2 },
      { text: 'Entre 11% a 25% Da Tranche', value: 3 },
      { text: '> 26% Da Tranche', value: 4 }
    ]
  },
  {
    question: 'Sacado desconhece pedido',
    id: 'sacadoDesconhecePedido',
    answers: [
      { text: 'Não', value: 0 },
      { text: 'Até 5% Da Tranche', value: 2 },
      { text: 'Entre 6% a 10% Da Tranche', value: 3 },
      { text: '> 10% Da Tranche', value: 4 }
    ]
  },
  {
    question: 'Sacado não passa informação',
    id: 'sacadoNaoPassaInformacao',
    answers: [
      { text: 'Até 5% Da Tranche', value: 0 },
      { text: 'Entre 6% a 15% Da Tranche', value: 1 },
      { text: 'Entre 16% a 30% Da Tranche', value: 2 },
      { text: '> 30% Da Tranche', value: 3 }
    ]
  },
  {
    question: 'Índice de liquidez',
    id: 'indiceLiquidez',
    answers: [
      { text: '> 94%', value: 0 },
      { text: 'Entre 93% e 90%', value: 1 },
      { text: 'Entre 89% e 80%', value: 2 },
      { text: 'Entre 79% e 70%', value: 3 },
      { text: '< 70%', value: 4 }
    ]
  },
  {
    question: 'Índice de recompra',
    id: 'indiceRecompra',
    answers: [
      { text: 'Até 2%', value: 0 },
      { text: 'Entre 3% e 7%', value: 1 },
      { text: 'Entre 8% e 10%', value: 2 },
      { text: 'Entre 11% e 15%', value: 3 },
      { text: '> 15%', value: 4 }
    ]
  },
  {
    question: 'Liquidação em cartório',
    id: 'liquidacaoCartorio',
    answers: [
      { text: 'Até 2%', value: 0 },
      { text: 'Entre 3% e 7%', value: 1 },
      { text: 'Entre 8% e 10%', value: 2 },
      { text: 'Entre 11% e 15%', value: 3 },
      { text: '> 15%', value: 4 }
    ]
  },
  {
    question: 'Títulos em atraso',
    id: 'titulosAtraso',
    answers: [
      { text: 'Até 5 Dias', value: 0 },
      { text: 'De 6 a 10 Dias', value: 1 },
      { text: 'De 11 a 14 Dias', value: 2 },
      { text: 'De 15 a 20 Dias', value: 3 },
      { text: '> 20 Dias', value: 4 }
    ]
  },
  {
    question: 'Histórico de apontamentos / restritivos',
    id: 'historicoApontamentosRestritivos',
    answers: [
      { text: 'Não', value: 0 },
      { text: 'Impostos, RJ DEF/HOM', value: 1 },
      { text: 'Bancos', value: 2 },
      { text: 'Fornecedores e/ou Liminar', value: 3 },
      { text: 'Fundos, Pedido de RJ e Falência', value: 4 }
    ]
  },
  {
    question: 'Análise eco-financeira',
    id: 'analiseEcoFinanceira',
    answers: [
      { text: 'Situação Adequada', value: 0 },
      { text: 'Satisfatória', value: 1 },
      { text: 'Ausência de Informs.', value: 2 },
      { text: 'Atenção', value: 3 },
      { text: 'Extrema Atenção', value: 5 }
    ]
  },
  {
    question: 'Consulta parceiros mercado',
    id: 'consultaParceirosMercado',
    answers: [
      { text: 'Liquidez > 90%', value: 0 },
      { text: 'Liquidez entre 80% e 89%', value: 1 },
      { text: 'Liquidez entre 50% e 79%', value: 2 },
      { text: 'Liquidez < 49%', value: 3 }
    ]
  },
  {
    question: 'Setores',
    id: 'setores',
    answers: [
      { text: 'Normais', value: 0 },
      { text: 'Restritos', value: 2 },
      { text: 'Proibidos', value: 4 }
    ]
  },
  {
    question: 'Cancelamento de visita consultor',
    id: 'cancelamentoVisitaConsultor',
    answers: [
      { text: 'Não', value: 0 },
      { text: 'Até 1 Vez', value: 1 },
      { text: 'Até 2 Vezes', value: 2 },
      { text: '> 2 Vezes', value: 4 }
    ]
  },
  {
    question: 'Sacados rejeitados em bôrdero',
    id: 'sacadosRejeitadosBordero',
    answers: [
      { text: 'Até 8%', value: 0 },
      { text: 'Entre 9% e 15%', value: 1 },
      { text: 'Entre 16% e 25%', value: 2 },
      { text: '> 25%', value: 3 }
    ]
  }
]

export default questionnaires
