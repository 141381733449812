import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'

import { Table } from '../../../../../components'
import { onSortDocument } from '../../../../../components/Table/sort'
import {
  formatDocument,
  hashObject,
  onlyNumbers,
  resolveGrauBeneficiario
} from '../../../../../utils'

const { SearchBar } = Search

export default function Beneficiarios ({ data, error }) {
  const [selectedTable, setSelectedTable] = useState('beneficiarios')

  if (error) {
    return <div>Erro no carregamento dos dados</div>
  }

  if (!data?.beneficiarios && !data?.junta) {
    return <div>Dados não encontrados</div>
  }

  const tableData =
    selectedTable === 'beneficiarios' ? data?.beneficiarios : data?.junta

  const columns = [
    {
      dataField: 'nome',
      text: 'Nome',
      sort: true,
      searchable: false
    },
    {
      dataField: 'documento',
      text: 'CPF',
      sort: true,
      sortFunc: onSortDocument,
      searchable: false
    },
    {
      dataField: 'falecido',
      sort: true,
      text: 'Falecido'
    },
    {
      dataField: 'grau',
      sort: true,
      text: 'Grau'
    },
    {
      dataField: 'participacao',
      sort: true,
      text: 'Participação'
    },
    {
      dataField: 'rawDocumento',
      hidden: true
    }
  ].map(item => ({
    ...item,
    align: 'center',
    headerAlign: 'center'
  }))

  const mappedData = (tableData || []).map(item => {
    const id = hashObject(item)

    return {
      id,
      nome: item.nome,
      rawDocumento: formatDocument(item.documento),
      documento: item.documento ? (
        <a
          href={`?documento=${onlyNumbers(item.documento)}`}
          target='_blank'
          rel='noopener noreferrer'
        >
          {formatDocument(item.documento)}
        </a>
      ) : (
        '-'
      ),
      falecido: item.falecido ? 'Sim' : 'Não',
      grau: resolveGrauBeneficiario(item.grau.toString()),
      participacao: item.participacao
    }
  })

  return (
    <ToolkitProvider keyField='id' data={mappedData} columns={columns} search>
      {props => (
        <>
          <div className='row mb-2'>
            <div className='col-12 col-lg-6 d-flex flex-row gap-3 mb-3'>
              <Form.Check
                type='radio'
                label='Beneficiários'
                onChange={() => setSelectedTable('beneficiarios')}
                checked={selectedTable === 'beneficiarios'}
                disabled={error}
              />

              <Form.Check
                type='radio'
                label='Beneficiários Junta'
                onChange={() => setSelectedTable('beneficiariosJunta')}
                checked={selectedTable === 'beneficiariosJunta'}
                disabled={error}
              />
            </div>
            <div className='col-12 col-lg-6 text-lg-right'>
              <SearchBar {...props.searchProps} placeholder='Pesquisar' />
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <Table {...props.baseProps} enablePagination />
            </div>
          </div>
        </>
      )}
    </ToolkitProvider>
  )
}
