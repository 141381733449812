import React from 'react'
import HttpsRedirect from 'react-https-redirect'
import ReactDOM from 'react-dom'
import './assets/sass/root.scss'

import { Provider } from 'react-redux'
import store from './redux/store'
import Root from './root'

ReactDOM.render(
  <HttpsRedirect>
    <Provider store={store}>
      <Root />
    </Provider>
  </HttpsRedirect>,
  document.getElementById('root')
)
