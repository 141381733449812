import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FieldArray, Formik } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { Form, InputGroup } from 'react-bootstrap'
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai'

import '@silevis/reactgrid/styles.css'

import { selectMinData } from '../../../../../../redux/selectors/dadosBasicos'
import { setDadosOperacionaisSulBrasil } from '../../../../../../redux/store/sulbrasil'

import { useSulbarsilVisitas } from '../../../../../../utils/hooks'
import { PROGRESS_TYPES } from '../../../../../../lib/progressStatus'
import { Loading } from '../../../../../../components'

const schema = Yup.object().shape({
  quantidadeDeColaboradores: Yup.number()
    .typeError('Quantidade de colaboradores deve ser um número')
    .required('Quantidade de colaboradores é obrigatório'),
  temConsultoria: Yup.string()
    .oneOf(['sim', 'nao'], 'Tipo de resposta inválida')
    .required('Tem consultoria é obrigatório'),
  descricaoConsultoria: Yup.string().when('temConsultoria', {
    is: 'sim',
    then: Yup.string().required('Descrição da consultoria é obrigatório')
  }),
  avalistas: Yup.array().of(
    Yup.object().shape({
      nomeDoAvalista: Yup.string().required('Nome do avalista é obrigatório'),
      casado: Yup.boolean().required('Casado é obrigatório'),
      eCpf: Yup.boolean().required('E-CPF é obrigatório')
    })
  ),
  prazoMedioDeVenda: Yup.number()
    .typeError('Prazo médio de venda deve ser um número')
    .required('Prazo médio de venda é obrigatório'),
  ticketMedio: Yup.number()
    .typeError('Ticket médio deve ser um número')
    .required('Ticket médio é obrigatório'),
  preFaturamento: Yup.string()
    .required('Pre-faturamento é obrigatório')
    .oneOf(['sim', 'nao'], 'Tipo de resposta inválida'),
  logisticaDeEntrega: Yup.string()
    .required('Logística de entrega é obrigatório')
    .typeError('Selecione uma opção'),
  qtdDiasPreFaturamento: Yup.number()
    .typeError('Quantidade de dias de pre-faturamento deve ser um número')
    .optional(),
  temInformalidadeNasVendas: Yup.string()
    .oneOf(['sim', 'nao'], 'Tipo de resposta inválida')
    .required('Tem informalidade nas vendas é obrigatório'),
  recebimentoDuplicatas: Yup.number()
    .typeError('Duplicatas deve ser um número')
    .optional(),
  recebimentoCheques: Yup.number()
    .typeError('Cheques deve ser um número')
    .optional(),
  recebimentoContaEspecial: Yup.number()
    .typeError('Conta especial deve ser um número')
    .optional(),
  recebimentoContratos: Yup.number()
    .typeError('Contratos deve ser um número')
    .optional(),
  recebimentoBoletoEspecial: Yup.number()
    .typeError('Boleto especial deve ser um número')
    .optional(),
  recebimentoOutros: Yup.number()
    .typeError('Outros deve ser um número')
    .optional(),
  localSede: Yup.string().required('Local da sede é obrigatório'),
  justificativaRestritivos: Yup.string().optional(),
  limiteGlobal: Yup.number()
    .typeError('Limite global deve ser um número')
    .optional(),
  descontoConvencional: Yup.object().shape({
    valor: Yup.number()
      .typeError('Valor deve ser um número')
      .optional(),
    taxa: Yup.string().optional(),
    tranche: Yup.number()
      .typeError('Tranche deve ser um número')
      .optional()
  }),
  boletoEspecial: Yup.object().shape({
    valor: Yup.number()
      .typeError('Valor deve ser um número')
      .optional(),
    taxa: Yup.string().optional(),
    prazoCanhoto: Yup.number()
      .typeError('Prazo canhoto deve ser um número')
      .optional(),
    reciprocidade: Yup.string().optional()
  }),
  contaEspecial: Yup.object().shape({
    valor: Yup.number()
      .typeError('Valor deve ser um número')
      .optional(),
    taxa: Yup.string().optional(),
    prazoCanhoto: Yup.number()
      .typeError('Prazo canhoto deve ser um número')
      .optional(),
    reciprocidade: Yup.string().optional()
  }),
  fluxoFuturo: Yup.object().shape({
    valor: Yup.number()
      .typeError('Valor deve ser um número')
      .optional(),
    taxa: Yup.string().optional(),
    prazoCanhoto: Yup.number()
      .typeError('Prazo canhoto deve ser um número')
      .optional(),
    reciprocidade: Yup.string().optional()
  }),
  adValorem: Yup.number()
    .typeError('Ad valorem deve ser um número')
    .optional(),
  outrasLinhas: Yup.array().of(
    Yup.object().shape({
      texto: Yup.string().optional(),
      valor: Yup.number()
        .typeError('Valor deve ser um número')
        .optional(),
      taxa: Yup.string().optional()
    })
  ),
  manifestacaoFinalGerenteComercial: Yup.string().required(
    'Manifestação final do gerente comercial é obrigatório'
  ),
  manifestacaoFinalGerenteRegional: Yup.string().required(
    'Manifestação final do gerente regional é obrigatório'
  )
})

export default function Operacionais () {
  const { data: visitasData, status: statusVisitas } = useSulbarsilVisitas()
  const { targetDocument } = useSelector(selectMinData)
  const dispatch = useDispatch()

  const hasTargetDocument = !!targetDocument

  if ([statusVisitas].some(status => status === PROGRESS_TYPES.PENDING)) {
    return <Loading />
  }

  return (
    <>
      <Formik
        initialValues={{
          quantidadeDeColaboradores: (
            visitasData?.qtdColaboradores || ''
          ).replace(/[^0-9.]/g, ''),
          temConsultoria: visitasData?.quemQtoTempo !== '-' ? 'sim' : 'nao',
          descricaoConsultoria: visitasData?.quemQtoTempo,
          avalistas: visitasData?.avalistas || [],
          prazoMedioDeVenda: (visitasData?.prazoMedioVendas || '').replace(
            /[^0-9.]/g,
            ''
          ),
          ticketMedio: visitasData?.valorTicketMedioVenda,
          preFaturamento:
            (visitasData?.preFaturamento || '').toLowerCase() === 'sim'
              ? 'sim'
              : 'nao',
          logisticaDeEntrega: visitasData?.logisticaEntrega,
          qtdDiasPreFaturamento: visitasData?.preFaturamentoDias ?? undefined,
          temInformalidadeNasVendas:
            visitasData?.grauInformalidadeVendas === true ? 'sim' : 'nao',
          recebimentoDuplicatas: parseFloat(
            visitasData?.recebimentoDuplicatas || '0'
          ),
          recebimentoCheques: parseFloat(
            visitasData?.recebimentoCheques || '0'
          ),
          recebimentoContaEspecial: parseFloat(
            visitasData?.recebimentoContaEspecial || '0'
          ),
          recebimentoContratos: parseFloat(
            visitasData?.recebimentoContratos || ''
          ),
          recebimentoBoletoEspecial: parseFloat(
            visitasData?.recebimentoBoletoEspecial || '0'
          ),
          recebimentoOutros: parseFloat(visitasData?.recebimentOutros || '0'),
          localSede: undefined,
          justificativaRestritivos: undefined,
          limiteGlobal: visitasData?.limiteGlobal,
          descontoConvencional: {
            valor: visitasData?.descConvencional?.[0]?.valor,
            taxa: visitasData?.descConvencional?.[0]?.taxa || '',
            tranche: parseFloat(
              (visitasData?.descConvencional?.[0]?.tranche || '')
                .replace(/\./g, '')
                .replace(/,/g, '.')
                .replace(/[^0-9.]/g, '')
            )
          },
          boletoEspecial: {
            valor: visitasData?.boletoEspecial?.[0]?.valor,
            taxa: visitasData?.boletoEspecial?.[0]?.taxa || '',
            prazoCanhoto: visitasData?.boletoEspecial?.[0]?.prazoCanhoto.replace(
              /[^0-9.]/g,
              ''
            ),
            reciprocidade: visitasData?.boletoEspecial?.[0]?.reciprocidade
          },
          contaEspecial: {
            valor: visitasData?.contaEspecial?.[0]?.valor,
            taxa: visitasData?.contaEspecial?.[0]?.taxa || '',
            prazoCanhoto: visitasData?.contaEspecial?.[0]?.prazoCanhoto.replace(
              /[^0-9.]/g,
              ''
            ),
            reciprocidade: visitasData?.contaEspecial?.[0]?.reciprocidade
          },
          fluxoFuturo: {
            valor: visitasData?.fluxoFuturo?.[0]?.valor,
            taxa: visitasData?.fluxoFuturo?.[0]?.taxa || '',
            prazoCanhoto: visitasData?.fluxoFuturo?.[0]?.prazoCanhoto.replace(
              /[^0-9.]/g,
              ''
            ),
            reciprocidade: visitasData?.fluxoFuturo?.[0]?.reciprocidade
          },
          adValorem: visitasData?.adValorem,
          outrasLinhas: [
            {
              texto: visitasData?.outrasLinhas?.[0]?.txt,
              valor: visitasData?.outrasLinhas?.[0]?.valor,
              taxa: visitasData?.outrasLinhas?.[0]?.taxa
            }
          ],
          manifestacaoFinalGerenteComercial:
            visitasData?.manifestacaoFinalGerenteComercial,
          manifestacaoFinalGerenteRegional:
            visitasData?.manifestacaoFinalGerenteRegional
        }}
        validationSchema={schema}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(setDadosOperacionaisSulBrasil(values))
          setSubmitting(false)
          toast.success('Dados operacionais parcialmente salvo!')
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isValidating,
          isSubmitting
        }) => {
          if (
            !isValidating &&
            isSubmitting &&
            Object.keys(errors || {}).length > 0
          ) {
            toast.error('Você não preencheu corretamente todos os campos', {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 5000,
              toastId: 'form-operacinais-error'
            })
          }
          return (
            <form onSubmit={handleSubmit}>
              <div className='row'>
                <div className='col-12'>
                  <h3 className='h4'>Dados gerais</h3>
                </div>
                <div className='col-3 col-lg-3'>
                  <Form.Group>
                    <Form.Label>Quantidade de Colaboradores</Form.Label>
                    <Form.Control
                      name='quantidadeDeColaboradores'
                      type='number'
                      min={0}
                      step={1}
                      isInvalid={
                        errors.quantidadeDeColaboradores &&
                        touched.quantidadeDeColaboradores
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.quantidadeDeColaboradores}
                    />
                    {errors.quantidadeDeColaboradores &&
                      touched.quantidadeDeColaboradores && (
                        <Form.Control.Feedback type='invalid'>
                          {errors.quantidadeDeColaboradores}
                        </Form.Control.Feedback>
                      )}
                  </Form.Group>
                </div>
                <div className='col-3 col-lg-2'>
                  <Form.Group>
                    <Form.Label>Tem consultoria? </Form.Label>
                    <div className='d-flex flex-row gap-4'>
                      <Form.Check
                        type='radio'
                        label='Sim'
                        name='temConsultoria'
                        isInvalid={
                          errors.temConsultoria && touched.temConsultoria
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value='sim'
                        checked={values.temConsultoria === 'sim'}
                      />
                      <Form.Check
                        type='radio'
                        label='Não'
                        name='temConsultoria'
                        isInvalid={
                          errors.temConsultoria && touched.temConsultoria
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value='nao'
                        checked={values.temConsultoria === 'nao'}
                      />
                    </div>
                    {errors.temConsultoria && touched.temConsultoria && (
                      <Form.Control.Feedback type='invalid'>
                        {errors.temConsultoria}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </div>
                {values.temConsultoria === 'sim' && (
                  <div className='col-6 col-lg-4'>
                    <Form.Group>
                      <Form.Label>
                        Quem? Quanto tempo está no projeto? Possui gestão de
                        caixa?
                      </Form.Label>
                      <Form.Control
                        type='input'
                        as='textarea'
                        name='descricaoConsultoria'
                        isInvalid={
                          errors.descricaoConsultoria &&
                          touched.descricaoConsultoria
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.descricaoConsultoria}
                      />
                      {errors.descricaoConsultoria &&
                        touched.descricaoConsultoria && (
                          <Form.Control.Feedback type='invalid'>
                            {errors.descricaoConsultoria}
                          </Form.Control.Feedback>
                        )}
                    </Form.Group>
                  </div>
                )}
              </div>
              <div>
                <div className='row'>
                  <div className='col-12'>
                    <h4 className='h6'>Avalistas / Responsáveis Solidários</h4>
                  </div>
                </div>
                <FieldArray
                  name='avalistas'
                  render={arrayHelpers => (
                    <div>
                      {values.avalistas.map((_, index) => (
                        <div className='row' key={index.toString()}>
                          <div className='col-6'>
                            <Form.Group>
                              <Form.Label>Nome</Form.Label>
                              <Form.Control
                                name={`avalistas[${index}].nomeDoAvalista`}
                                type='text'
                                isInvalid={
                                  errors.avalistas?.[index]?.nomeDoAvalista &&
                                  touched.avalistas?.[index]?.nomeDoAvalista
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={
                                  values.avalistas?.[index]?.nomeDoAvalista
                                }
                              />
                              {errors.avalistas?.[index]?.nomeDoAvalista &&
                                touched.avalistas?.[index]?.nomeDoAvalista && (
                                  <Form.Control.Feedback type='invalid'>
                                    {errors.avalistas?.[index]?.nomeDoAvalista}
                                  </Form.Control.Feedback>
                                )}
                            </Form.Group>
                          </div>
                          <div className='col-2'>
                            <Form.Group>
                              <Form.Label>Possui E-CPF? </Form.Label>
                              <Form.Check
                                name={`avalistas[${index}].eCpf`}
                                type='checkbox'
                                label='Sim'
                                value
                                checked={values.avalistas?.[index]?.eCpf}
                                isInvalid={
                                  errors.avalista?.[index]?.eCpf &&
                                  touched.avalistas?.[index]?.eCpf
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </Form.Group>
                          </div>
                          <div className='col-2'>
                            <Form.Group>
                              <Form.Label>Casado? </Form.Label>
                              <Form.Check
                                name={`avalistas[${index}].casado`}
                                type='checkbox'
                                label='Sim'
                                value
                                checked={values.avalistas?.[index]?.casado}
                                isInvalid={
                                  errors.avalistas?.[index]?.casado &&
                                  touched.avalistas?.[index]?.casado
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </Form.Group>
                          </div>
                          <div className='col-2 d-flex align-items-center'>
                            <div>
                              <button
                                type='button'
                                className='btn btn-danger'
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                <AiOutlineMinusCircle /> Remover
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                      <button
                        type='button'
                        className='btn btn-success text-light'
                        onClick={() =>
                          arrayHelpers.push({
                            nomeDoAvalista: '',
                            casado: false,
                            eCpf: false
                          })
                        }
                      >
                        <AiOutlinePlusCircle /> Adicionar
                      </button>
                    </div>
                  )}
                />
              </div>

              <div className='row mt-3'>
                <div className='col-12'>
                  <h3 className='h4'>Vendas</h3>
                </div>
                <div className='col-6 col-lg-4'>
                  <Form.Group>
                    <Form.Label>Prazo médio de venda (dias)</Form.Label>
                    <Form.Control
                      name='prazoMedioDeVenda'
                      type='number'
                      min={0}
                      step={1}
                      isInvalid={
                        errors.prazoMedioDeVenda && touched.prazoMedioDeVenda
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.prazoMedioDeVenda}
                    />
                    {errors.prazoMedioDeVenda && touched.prazoMedioDeVenda && (
                      <Form.Control.Feedback type='invalid'>
                        {errors.prazoMedioDeVenda}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </div>
                <div className='col-6 col-lg-4'>
                  <Form.Group>
                    <Form.Label>Ticket médio</Form.Label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>R$</InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        name='ticketMedio'
                        type='number'
                        min={0}
                        step={0.01}
                        isInvalid={errors.ticketMedio && touched.ticketMedio}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.ticketMedio}
                      />
                    </InputGroup>
                    {errors.ticketMedio && touched.ticketMedio && (
                      <Form.Control.Feedback type='invalid'>
                        {errors.ticketMedio}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </div>
                <div className='col-3 col-lg-3'>
                  <Form.Group>
                    <Form.Label>Logística de entrega</Form.Label>
                    <Form.Control
                      name='logisticaDeEntrega'
                      as='select'
                      isInvalid={
                        errors.logisticaDeEntrega && touched.logisticaDeEntrega
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.logisticaDeEntrega}
                    >
                      <option value=''>---- selecione ----</option>
                      <option value='Própria'>Própria</option>
                      <option value='Terceirizada'>Terceirizada</option>
                      <option value='Própria / Terceirizada'>
                        Própria / Terceirizada
                      </option>
                    </Form.Control>
                    {errors.logisticaDeEntrega &&
                      touched.logisticaDeEntrega && (
                        <Form.Control.Feedback type='invalid'>
                          {errors.logisticaDeEntrega}
                        </Form.Control.Feedback>
                      )}
                  </Form.Group>
                </div>
                <div className='col-3 col-lg-3'>
                  <Form.Group>
                    <Form.Label>Tem informalidade nas vendas?</Form.Label>
                    <div className='d-flex flex-row gap-4'>
                      <Form.Check
                        type='radio'
                        label='Sim'
                        name='temInformalidadeNasVendas'
                        isInvalid={
                          errors.temInformalidadeNasVendas &&
                          touched.temInformalidadeNasVendas
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value='sim'
                        checked={values.temInformalidadeNasVendas === 'sim'}
                      />
                      <Form.Check
                        type='radio'
                        label='Não'
                        name='temInformalidadeNasVendas'
                        isInvalid={
                          errors.temInformalidadeNasVendas &&
                          touched.temInformalidadeNasVendas
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value='nao'
                        checked={values.temInformalidadeNasVendas === 'nao'}
                      />
                    </div>
                    {errors.temInformalidadeNasVendas &&
                      touched.temInformalidadeNasVendas && (
                        <Form.Control.Feedback type='invalid'>
                          {errors.temInformalidadeNasVendas}
                        </Form.Control.Feedback>
                      )}
                  </Form.Group>
                </div>
                <div className='col-3 col-lg-2'>
                  <Form.Group>
                    <Form.Label>Tem pré faturamento? </Form.Label>
                    <div className='d-flex flex-row gap-4'>
                      <Form.Check
                        type='radio'
                        label='Sim'
                        name='preFaturamento'
                        isInvalid={
                          errors.preFaturamento && touched.preFaturamento
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value='sim'
                        checked={values.preFaturamento === 'sim'}
                      />
                      <Form.Check
                        type='radio'
                        label='Não'
                        name='preFaturamento'
                        isInvalid={
                          errors.preFaturamento && touched.preFaturamento
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value='nao'
                        checked={values.preFaturamento === 'nao'}
                      />
                    </div>
                    {errors.preFaturamento && touched.preFaturamento && (
                      <Form.Control.Feedback type='invalid'>
                        {errors.preFaturamento}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </div>
                {values.preFaturamento === 'sim' && (
                  <div className='col-3 col-lg-3'>
                    <Form.Group>
                      <Form.Label>Qtd. dias de pré faturamento</Form.Label>
                      <Form.Control
                        name='qtdDiasPreFaturamento'
                        type='number'
                        min={0}
                        step={1}
                        isInvalid={
                          errors.qtdDiasPreFaturamento &&
                          touched.qtdDiasPreFaturamento
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.qtdDiasPreFaturamento}
                      />
                      {errors.qtdDiasPreFaturamento &&
                        touched.qtdDiasPreFaturamento && (
                          <Form.Control.Feedback type='invalid'>
                            {errors.qtdDiasPreFaturamento}
                          </Form.Control.Feedback>
                        )}
                    </Form.Group>
                  </div>
                )}
                <div className='col-12'>
                  <h4 className='h6'>Formas de recebimento</h4>
                </div>
                <div className='col-3 col-lg-2'>
                  <Form.Group>
                    <Form.Label>Duplicatas</Form.Label>
                    <InputGroup>
                      <Form.Control
                        name='recebimentoDuplicatas'
                        type='number'
                        min={0}
                        step={0.01}
                        max={100}
                        isInvalid={
                          errors.recebimentoDuplicatas &&
                          touched.recebimentoDuplicatas
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.recebimentoDuplicatas}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text>%</InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                    {errors.recebimentoDuplicatas &&
                      touched.recebimentoDuplicatas && (
                        <Form.Control.Feedback type='invalid'>
                          {errors.recebimentoDuplicatas}
                        </Form.Control.Feedback>
                      )}
                  </Form.Group>
                </div>

                <div className='col-3 col-lg-2'>
                  <Form.Group>
                    <Form.Label>Cheques</Form.Label>
                    <InputGroup>
                      <Form.Control
                        name='recebimentoCheques'
                        type='number'
                        min={0}
                        step={0.01}
                        max={100}
                        isInvalid={
                          errors.recebimentoCheques &&
                          touched.recebimentoCheques
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.recebimentoCheques}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text>%</InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                    {errors.recebimentoCheques &&
                      touched.recebimentoCheques && (
                        <Form.Control.Feedback type='invalid'>
                          {errors.recebimentoCheques}
                        </Form.Control.Feedback>
                      )}
                  </Form.Group>
                </div>

                <div className='col-3 col-lg-2'>
                  <Form.Group>
                    <Form.Label>Conta Especial</Form.Label>
                    <InputGroup>
                      <Form.Control
                        name='recebimentoContaEspecial'
                        type='number'
                        min={0}
                        step={0.01}
                        max={100}
                        isInvalid={
                          errors.recebimentoContaEspecial &&
                          touched.recebimentoContaEspecial
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.recebimentoContaEspecial}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text>%</InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                    {errors.recebimentoContaEspecial &&
                      touched.recebimentoContaEspecial && (
                        <Form.Control.Feedback type='invalid'>
                          {errors.recebimentoContaEspecial}
                        </Form.Control.Feedback>
                      )}
                  </Form.Group>
                </div>

                <div className='col-3 col-lg-2'>
                  <Form.Group>
                    <Form.Label>Contratos</Form.Label>
                    <InputGroup>
                      <Form.Control
                        name='recebimentoContratos'
                        type='number'
                        min={0}
                        step={0.01}
                        max={100}
                        isInvalid={
                          errors.recebimentoContratos &&
                          touched.recebimentoContratos
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.recebimentoContratos}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text>%</InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                    {errors.recebimentoContratos &&
                      touched.recebimentoContratos && (
                        <Form.Control.Feedback type='invalid'>
                          {errors.recebimentoContratos}
                        </Form.Control.Feedback>
                      )}
                  </Form.Group>
                </div>

                <div className='col-3 col-lg-2'>
                  <Form.Group>
                    <Form.Label>Boleto Especial</Form.Label>
                    <InputGroup>
                      <Form.Control
                        name='recebimentoBoletoEspecial'
                        type='number'
                        min={0}
                        step={0.01}
                        max={100}
                        isInvalid={
                          errors.recebimentoBoletoEspecial &&
                          touched.recebimentoBoletoEspecial
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.recebimentoBoletoEspecial}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text>%</InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                    {errors.recebimentoBoletoEspecial &&
                      touched.recebimentoBoletoEspecial && (
                        <Form.Control.Feedback type='invalid'>
                          {errors.recebimentoBoletoEspecial}
                        </Form.Control.Feedback>
                      )}
                  </Form.Group>
                </div>

                <div className='col-3 col-lg-2'>
                  <Form.Group>
                    <Form.Label>Outros</Form.Label>
                    <InputGroup>
                      <Form.Control
                        name='recebimentoOutros'
                        type='number'
                        min={0}
                        step={0.01}
                        max={100}
                        isInvalid={
                          errors.recebimentoOutros && touched.recebimentoOutros
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.recebimentoOutros}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text>%</InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                    {errors.recebimentoOutros && touched.recebimentoOutros && (
                      <Form.Control.Feedback type='invalid'>
                        {errors.recebimentoOutros}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </div>
              </div>
              <div>
                <div className='row mt-3'>
                  <div className='col-12'>
                    <h3 className='h4'>Pleito</h3>
                  </div>
                  <div className='col-6 col-lg-12'>
                    <Form.Group>
                      <Form.Label>Limite global</Form.Label>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text>R$</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          type='number'
                          name='limiteGlobal'
                          min={0}
                          step={0.01}
                          isInvalid={
                            errors.limiteGlobal && touched.limiteGlobal
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.limiteGlobal}
                        />
                      </InputGroup>
                      {errors.limiteGlobal && touched.limiteGlobal && (
                        <Form.Control.Feedback type='invalid'>
                          {errors.limiteGlobal}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </div>
                  <div className='col-3 col-lg-3'>
                    <Form.Group>
                      <Form.Label>Desconto convencional</Form.Label>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text>R$</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          name='descontoConvencional.valor'
                          type='number'
                          min={0}
                          step={0.01}
                          isInvalid={
                            errors.descontoConvencional?.valor &&
                            touched.descontoConvencional?.valor
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.descontoConvencional?.valor}
                        />
                      </InputGroup>
                      {errors.descontoConvencional?.valor &&
                        touched.descontoConvencional?.valor && (
                          <Form.Control.Feedback type='invalid'>
                            {errors.descontoConvencional.valor}
                          </Form.Control.Feedback>
                        )}
                    </Form.Group>
                  </div>
                  <div className='col-3 col-lg-2'>
                    <Form.Group>
                      <Form.Label>Taxa</Form.Label>
                      <InputGroup>
                        <Form.Control
                          name='descontoConvencional.taxa'
                          type='text'
                          isInvalid={
                            errors.descontoConvencional?.taxa &&
                            touched.descontoConvencional?.taxa
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.descontoConvencional?.taxa}
                        />
                        <InputGroup.Append>
                          <InputGroup.Text>%</InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                      {errors.descontoConvencional?.taxa &&
                        touched.descontoConvencional?.taxa && (
                          <Form.Control.Feedback type='invalid'>
                            {errors.descontoConvencional.taxa}
                          </Form.Control.Feedback>
                        )}
                    </Form.Group>
                  </div>
                  <div className='col-6 col-lg-4'>
                    <Form.Group>
                      <Form.Label>Tranche</Form.Label>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text>R$</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          name='descontoConvencional.tranche'
                          type='number'
                          min={0}
                          step={0.01}
                          isInvalid={
                            errors.descontoConvencional?.tranche &&
                            touched.descontoConvencional?.tranche
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.descontoConvencional?.tranche}
                        />
                      </InputGroup>
                      {errors.descontoConvencional?.tranche &&
                        touched.descontoConvencional?.tranche && (
                          <Form.Control.Feedback type='invalid'>
                            {errors.descontoConvencional.tranche}
                          </Form.Control.Feedback>
                        )}
                    </Form.Group>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-3 col-lg-3'>
                    <Form.Group>
                      <Form.Label>Boleto Especial</Form.Label>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text>R$</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          name='boletoEspecial.valor'
                          type='number'
                          min={0}
                          step={0.01}
                          isInvalid={
                            errors.boletoEspecial?.valor &&
                            touched.boletoEspecial?.valor
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.boletoEspecial?.valor}
                        />
                      </InputGroup>
                      {errors.boletoEspecial?.valor &&
                        touched.boletoEspecial?.valor && (
                          <Form.Control.Feedback type='invalid'>
                            {errors.boletoEspecial.valor}
                          </Form.Control.Feedback>
                        )}
                    </Form.Group>
                  </div>
                  <div className='col-3 col-lg-2'>
                    <Form.Group>
                      <Form.Label>Taxa</Form.Label>
                      <InputGroup>
                        <Form.Control
                          name='boletoEspecial.taxa'
                          type='text'
                          isInvalid={
                            errors.boletoEspecial?.taxa &&
                            touched.boletoEspecial?.taxa
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.boletoEspecial?.taxa}
                        />
                        <InputGroup.Append>
                          <InputGroup.Text>%</InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                      {errors.boletoEspecial?.taxa &&
                        touched.boletoEspecial?.taxa && (
                          <Form.Control.Feedback type='invalid'>
                            {errors.boletoEspecial.taxa}
                          </Form.Control.Feedback>
                        )}
                    </Form.Group>
                  </div>
                  <div className='col-3 col-lg-2'>
                    <Form.Group>
                      <Form.Label>Prazo canhoto (dias)</Form.Label>
                      <Form.Control
                        type='number'
                        name='boletoEspecial.prazoCanhoto'
                        min={0}
                        step={1}
                        isInvalid={
                          errors.boletoEspecial?.prazoCanhoto &&
                          touched.boletoEspecial?.prazoCanhoto
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.boletoEspecial?.prazoCanhoto}
                      />
                      {errors.boletoEspecial?.prazoCanhoto &&
                        touched.boletoEspecial?.prazoCanhoto && (
                          <Form.Control.Feedback type='invalid'>
                            {errors.boletoEspecial.prazoCanhoto}
                          </Form.Control.Feedback>
                        )}
                    </Form.Group>
                  </div>
                  <div className='col-3 col-lg-2'>
                    <Form.Group>
                      <Form.Label>Reciprocidade</Form.Label>
                      <Form.Control
                        name='boletoEspecial.reciprocidade'
                        type='text'
                        isInvalid={
                          errors.boletoEspecial?.reciprocidade &&
                          touched.boletoEspecial?.reciprocidade
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.boletoEspecial?.reciprocidade}
                      />
                      {errors.boletoEspecial?.reciprocidade &&
                        touched.boletoEspecial?.reciprocidade && (
                          <Form.Control.Feedback type='invalid'>
                            {errors.boletoEspecial.reciprocidade}
                          </Form.Control.Feedback>
                        )}
                    </Form.Group>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-3 col-lg-3'>
                    <Form.Group>
                      <Form.Label>Conta Especial</Form.Label>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text>R$</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          name='contaEspecial.valor'
                          type='number'
                          min={0}
                          step={0.01}
                          isInvalid={
                            errors.contaEspecial?.valor &&
                            touched.contaEspecial?.valor
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.contaEspecial?.valor}
                        />
                      </InputGroup>
                      {errors.contaEspecial?.valor &&
                        touched.contaEspecial?.valor && (
                          <Form.Control.Feedback type='invalid'>
                            {errors.contaEspecial.valor}
                          </Form.Control.Feedback>
                        )}
                    </Form.Group>
                  </div>
                  <div className='col-3 col-lg-2'>
                    <Form.Group>
                      <Form.Label>Taxa</Form.Label>
                      <InputGroup>
                        <Form.Control
                          name='contaEspecial.taxa'
                          type='text'
                          isInvalid={
                            errors.contaEspecial?.taxa &&
                            touched.contaEspecial?.taxa
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.contaEspecial?.taxa}
                        />
                        <InputGroup.Append>
                          <InputGroup.Text>%</InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                      {errors.contaEspecial?.taxa &&
                        touched.contaEspecial?.taxa && (
                          <Form.Control.Feedback type='invalid'>
                            {errors.contaEspecial.taxa}
                          </Form.Control.Feedback>
                        )}
                    </Form.Group>
                  </div>
                  <div className='col-3 col-lg-2'>
                    <Form.Group>
                      <Form.Label>Prazo canhoto (dias)</Form.Label>
                      <Form.Control
                        name='contaEspecial.prazoCanhoto'
                        type='number'
                        min={0}
                        step={1}
                        isInvalid={
                          errors.contaEspecial?.prazoCanhoto &&
                          touched.contaEspecial?.prazoCanhoto
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.contaEspecial?.prazoCanhoto}
                      />
                      {errors.contaEspecial?.prazoCanhoto &&
                        touched.contaEspecial?.prazoCanhoto && (
                          <Form.Control.Feedback type='invalid'>
                            {errors.contaEspecial.prazoCanhoto}
                          </Form.Control.Feedback>
                        )}
                    </Form.Group>
                  </div>
                  <div className='col-3 col-lg-2'>
                    <Form.Group>
                      <Form.Label>Reciprocidade</Form.Label>
                      <Form.Control
                        name='contaEspecial.reciprocidade'
                        type='text'
                        isInvalid={
                          errors.contaEspecial?.reciprocidade &&
                          touched.contaEspecial?.reciprocidade
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.contaEspecial?.reciprocidade}
                      />
                      {errors.contaEspecial?.reciprocidade &&
                        touched.contaEspecial?.reciprocidade && (
                          <Form.Control.Feedback type='invalid'>
                            {errors.contaEspecial.reciprocidade}
                          </Form.Control.Feedback>
                        )}
                    </Form.Group>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-3 col-lg-3'>
                    <Form.Group>
                      <Form.Label>Fluxo futuro</Form.Label>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text>R$</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          name='fluxoFuturo.valor'
                          type='number'
                          min={0}
                          step={0.01}
                          isInvalid={
                            errors.fluxoFuturo?.valor &&
                            touched.fluxoFuturo?.valor
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.fluxoFuturo?.valor}
                        />
                      </InputGroup>
                      {errors.fluxoFuturo?.valor &&
                        touched.fluxoFuturo?.valor && (
                          <Form.Control.Feedback type='invalid'>
                            {errors.fluxoFuturo.valor}
                          </Form.Control.Feedback>
                        )}
                    </Form.Group>
                  </div>
                  <div className='col-3 col-lg-2'>
                    <Form.Group>
                      <Form.Label>Taxa</Form.Label>
                      <InputGroup>
                        <Form.Control
                          name='fluxoFuturo.taxa'
                          type='text'
                          isInvalid={
                            errors.fluxoFuturo?.taxa &&
                            touched.fluxoFuturo?.taxa
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.fluxoFuturo?.taxa}
                        />
                        <InputGroup.Append>
                          <InputGroup.Text>%</InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                      {errors.fluxoFuturo?.taxa &&
                        touched.fluxoFuturo?.taxa && (
                          <Form.Control.Feedback type='invalid'>
                            {errors.fluxoFuturo.taxa}
                          </Form.Control.Feedback>
                        )}
                    </Form.Group>
                  </div>
                  <div className='col-3 col-lg-2'>
                    <Form.Group>
                      <Form.Label>Prazo canhoto (dias)</Form.Label>
                      <Form.Control
                        name='fluxoFuturo.prazoCanhoto'
                        type='number'
                        min={0}
                        step={1}
                        isInvalid={
                          errors.fluxoFuturo?.prazoCanhoto &&
                          touched.fluxoFuturo?.prazoCanhoto
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.fluxoFuturo?.prazoCanhoto}
                      />
                      {errors.fluxoFuturo?.prazoCanhoto &&
                        touched.fluxoFuturo?.prazoCanhoto && (
                          <Form.Control.Feedback type='invalid'>
                            {errors.fluxoFuturo.prazoCanhoto}
                          </Form.Control.Feedback>
                        )}
                    </Form.Group>
                  </div>
                  <div className='col-3 col-lg-2'>
                    <Form.Group>
                      <Form.Label>Reciprocidade</Form.Label>
                      <Form.Control
                        name='fluxoFuturo.reciprocidade'
                        type='text'
                        isInvalid={
                          errors.fluxoFuturo?.reciprocidade &&
                          touched.fluxoFuturo?.reciprocidade
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.fluxoFuturo?.reciprocidade}
                      />
                      {errors.fluxoFuturo?.reciprocidade &&
                        touched.fluxoFuturo?.reciprocidade && (
                          <Form.Control.Feedback type='invalid'>
                            {errors.fluxoFuturo.reciprocidade}
                          </Form.Control.Feedback>
                        )}
                    </Form.Group>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-3 col-lg-3'>
                    <Form.Group>
                      <Form.Label>Ad. Valorem</Form.Label>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text>R$</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          name='adValorem'
                          type='number'
                          min={0}
                          step={0.01}
                          isInvalid={errors.adValorem && touched.adValorem}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.adValorem}
                        />
                      </InputGroup>
                      {errors.adValorem && touched.adValorem && (
                        <Form.Control.Feedback type='invalid'>
                          {errors.adValorem}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </div>
                </div>
                <FieldArray
                  name='outrasLinhas'
                  render={arrayHelpers => (
                    <div>
                      {values.outrasLinhas.map((_, index) => (
                        <div className='row' key={`outrasLinhas-${index}`}>
                          <div className='col-3 col-lg-3'>
                            <Form.Group>
                              <Form.Label>Outras linhas</Form.Label>
                              <Form.Control
                                name={`outrasLinhas[${index}].texto`}
                                type='text'
                                isInvalid={
                                  errors.outrasLinhas?.[index]?.texto &&
                                  touched.outrasLinhas?.[index]?.texto
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.outrasLinhas?.[index]?.texto}
                              />
                              {errors.outrasLinhas?.[index]?.texto &&
                                touched.outrasLinhas?.[index]?.texto && (
                                  <Form.Control.Feedback type='invalid'>
                                    {errors.outrasLinhas[index].texto}
                                  </Form.Control.Feedback>
                                )}
                            </Form.Group>
                          </div>
                          <div className='col-3 col-lg-3'>
                            <Form.Group>
                              <Form.Label>Valor</Form.Label>
                              <InputGroup>
                                <InputGroup.Prepend>
                                  <InputGroup.Text>R$</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                  name={`outrasLinhas[${index}].valor`}
                                  type='number'
                                  min={0}
                                  step={0.01}
                                  isInvalid={
                                    errors.outrasLinhas?.[index]?.valor &&
                                    touched.outrasLinhas?.[index]?.valor
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.outrasLinhas?.[index]?.valor}
                                />
                              </InputGroup>
                              {errors.outrasLinhas?.[index]?.valor &&
                                touched.outrasLinhas?.[index]?.valor && (
                                  <Form.Control.Feedback type='invalid'>
                                    {errors.outrasLinhas[index].valor}
                                  </Form.Control.Feedback>
                                )}
                            </Form.Group>
                          </div>
                          <div className='col-3 col-lg-2'>
                            <Form.Group>
                              <Form.Label>Taxa</Form.Label>
                              <InputGroup>
                                <Form.Control
                                  name={`outrasLinhas[${index}].taxa`}
                                  type='text'
                                  isInvalid={
                                    errors.outrasLinhas?.[index]?.taxa &&
                                    touched.outrasLinhas?.[index]?.taxa
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.outrasLinhas?.[index]?.taxa}
                                />
                                <InputGroup.Append>
                                  <InputGroup.Text>%</InputGroup.Text>
                                </InputGroup.Append>
                              </InputGroup>
                              {errors.outrasLinhas?.[index]?.taxa &&
                                touched.outrasLinhas?.[index]?.taxa && (
                                  <Form.Control.Feedback type='invalid'>
                                    {errors.outrasLinhas?.[index]?.taxa}
                                  </Form.Control.Feedback>
                                )}
                            </Form.Group>
                          </div>
                          <div className='col-2 d-flex align-items-center'>
                            <div>
                              <button
                                type='button'
                                className='btn btn-danger'
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                <AiOutlineMinusCircle /> Remover
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                      <button
                        type='button'
                        className='btn btn-success text-light'
                        onClick={() =>
                          arrayHelpers.push({
                            texto: '',
                            valor: undefined,
                            taxa: ''
                          })
                        }
                      >
                        <AiOutlinePlusCircle /> Mais linhas
                      </button>
                    </div>
                  )}
                />
              </div>
              <div className='row mt-3'>
                <div className='col-12'>
                  <h3 className='h4'>Dados adicionais</h3>
                </div>
                <div className='col-3 col-lg-3'>
                  <Form.Group>
                    <Form.Label>Prédio próprio ou alugado</Form.Label>
                    <Form.Control
                      name='localSede'
                      as='select'
                      isInvalid={errors.localSede && touched.localSede}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.localSede}
                    >
                      <option value=''>---- selecione ----</option>
                      <option value='Não Informado'>Não Informado</option>
                      <option value='Próprio'>Próprio</option>
                      <option value='Alugado'>Alugado</option>
                    </Form.Control>
                  </Form.Group>
                </div>
                <div className='col-9'>
                  <Form.Group>
                    <Form.Label>Justificativa restritivos</Form.Label>
                    <Form.Control
                      name='justificativaRestritivos'
                      type='input'
                      as='textarea'
                      isInvalid={
                        errors.justificativaRestritivos &&
                        touched.justificativaRestritivos
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.justificativaRestritivos}
                    />
                    {errors.justificativaRestritivos &&
                      touched.justificativaRestritivos && (
                        <Form.Control.Feedback type='invalid'>
                          {errors.justificativaRestritivos}
                        </Form.Control.Feedback>
                      )}
                  </Form.Group>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <h4>Manifestações finais</h4>
                </div>
                <div className='col-6 col-lg-6'>
                  <Form.Group>
                    <Form.Label>
                      Manifestação final - Gerente comercial
                    </Form.Label>
                    <Form.Control
                      name='manifestacaoFinalGerenteComercial'
                      type='input'
                      as='textarea'
                      isInvalid={
                        errors.manifestacaoFinalGerenteComercial &&
                        touched.manifestacaoFinalGerenteComercial
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.manifestacaoFinalGerenteComercial}
                    />
                    {errors.manifestacaoFinalGerenteComercial &&
                      touched.manifestacaoFinalGerenteComercial && (
                        <Form.Control.Feedback type='invalid'>
                          {errors.manifestacaoFinalGerenteComercial}
                        </Form.Control.Feedback>
                      )}
                  </Form.Group>
                </div>
                <div className='col-6 col-lg-6'>
                  <Form.Group>
                    <Form.Label>
                      Manifestação final - Gerente regional
                    </Form.Label>
                    <Form.Control
                      name='manifestacaoFinalGerenteRegional'
                      type='input'
                      as='textarea'
                      isInvalid={
                        errors.manifestacaoFinalGerenteRegional &&
                        touched.manifestacaoFinalGerenteRegional
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.manifestacaoFinalGerenteRegional}
                    />
                    {errors.manifestacaoFinalGerenteRegional &&
                      touched.manifestacaoFinalGerenteRegional && (
                        <Form.Control.Feedback type='invalid'>
                          {errors.manifestacaoFinalGerenteRegional}
                        </Form.Control.Feedback>
                      )}
                  </Form.Group>
                </div>
              </div>
              <div className='text-center mt-3'>
                <button
                  className='btn btn-primary'
                  type='submit'
                  disabled={isSubmitting || !hasTargetDocument}
                >
                  Salvar
                </button>
              </div>
            </form>
          )
        }}
      </Formik>
    </>
  )
}
