import React from 'react'

import * as styles from './style.module.scss'
import logo from '../../assets/img/logo/boa-nota/full_white.svg'

import { VERSION } from '../../utils'

export default function Footer () {
  return (
    <footer className={styles.container}>
      <div className='d-flex flex-row justify-content-center px-4 mr-3 py-3'>
        <div className='d-flex flex-grow-1 align-items-center'>
          <img src={logo} alt='Logo Boa Nota' height={40} />
        </div>
        <div className='d-flex flex-column justify-content-center align-items-center py-2'>
          <div className={`text-right ${styles.powered}`}>
            <p className='mt-1 mb-0'>
              Powered by <a href='https://indrema.com.br/'>Indrema</a>
            </p>
          </div>
          <div
            className={`text-right font-weight-light mt-1 w-100 ${styles.version}`}
          >
            <span>v{VERSION}</span>
          </div>
        </div>
      </div>
    </footer>
  )
}
