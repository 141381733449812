import { all, call, put, select } from 'redux-saga/effects'
import { toast } from 'react-toastify'

import API from '../../services/api'
import { setGrafoData, setGrafoError, setGrafoLoading } from '../actions/grafo'

export function * fetchGrafoData (action) {
  const toastId = 'carregando-dados-grafo'
  toast('Carregando informações do item selecionado', {
    position: toast.POSITION.BOTTOM_RIGHT,
    toastId,
    autoClose: false
  })

  try {
    yield put(setGrafoLoading(true))

    const { nodes } = action.payload

    const generators = nodes.map(fetchGraphData)

    yield all(generators)
  } catch (err) {
    console.error(err)
    toast.error('Erro ao carregar dados do item selecionado', {
      position: toast.POSITION.BOTTOM_RIGHT
    })

    yield put(setGrafoError())
  } finally {
    yield put(setGrafoLoading(false))

    toast.dismiss(toastId)
  }
}

function * fetchGraphData (item) {
  const values = yield select(state => state.grafo.values)

  const { type, data } = item
  const { document, name } = data

  const isCPF = document.length === 11

  if (document.length > 14) {
    return
  }

  if (values[`${document}-${name}`]) {
    return
  }

  const responseData = yield call(API.grupoSocietario.getDadosCadastrais, {
    document,
    name: isCPF ? name : undefined
  })

  const { tipoLogradouro, logradouro, numero, municipio, uf, cep } =
    responseData.estabelecimento || {}

  const googleMapsResponse =
    type === 'PJ' || document.length === 14
      ? yield call(fetchGoogleImages, {
          name,
          tipoLogradouro,
          logradouro,
          numero,
          municipio,
          uf,
          cep
        })
      : {
          error: false,
          data: { address: undefined, geolocation: undefined, images: [] }
        }
  yield put(
    setGrafoData({
      key: `${document}-${name}`,
      data: {
        ...responseData,
        googleImages: googleMapsResponse
      }
    })
  )
}

function * fetchGoogleImages ({
  name,
  tipoLogradouro,
  logradouro,
  numero,
  municipio,
  uf,
  cep
}) {
  try {
    const googleImagesResponseData = yield call(API.googleMaps.consultar, {
      tipoLogradouro: tipoLogradouro,
      logradouro,
      numero,
      municipio,
      estado: uf,
      cep
    })
    return { error: false, data: googleImagesResponseData }
  } catch {
    console.error(`Erro ao carregar imagens do google maps do: ${name}`)
    return {
      error: true,
      data: { address: undefined, geolocation: undefined, images: [] }
    }
  }
}
