import React from 'react'

export default function GoogleMapsResponsive ({ query, height, width }) {
  const internalQuery = query || ''
  const internalHeight = height || '400px'
  const internalWidth = width || '100%'
  return (
    <iframe
      title='Google Maps'
      scrolling='no'
      marginHeight='0'
      marginWidth='0'
      src={`https://maps.google.com/maps?width=100%&height=100%&hl=pt-br&q=${internalQuery}&t=&z=14&ie=UTF8&iwloc=B&output=embed`}
      width={internalWidth}
      height={internalHeight}
      frameborder='0'
    />
  )
}
