const questionnaires = [
  {
    question: 'Empresa Eireli, MEI ou EI',
    id: 'empresaEireli',
    answers: [
      { text: 'Sim', value: 2 },
      { text: 'Não', value: 0 }
    ]
  },
  {
    question: 'Análise eco-financeira',
    id: 'analiseEcoFinanceira',
    answers: [
      { text: 'Situação Adequada', value: 0 },
      { text: 'Satisfatória', value: 1 },
      { text: 'Ausência de Informs.', value: 2 },
      { text: 'Atenção', value: 3 },
      { text: 'Extrema Atenção', value: 5 }
    ]
  },
  {
    question: 'Consulta parceiros mercado',
    id: 'consultaParceirosMercado',
    answers: [
      { text: 'Liquidez > 90%', value: 0 },
      { text: 'Liquidez entre 80% e 89%', value: 1 },
      { text: 'Liquidez entre 50% e 79%', value: 2 },
      { text: 'Liquidez < 49%', value: 3 }
    ]
  },
  {
    question: 'Migrando faturamento entre CNPJ',
    id: 'migrandoFaturamentoCnpj',
    answers: [
      { text: 'Sim', value: 3 },
      { text: 'Não', value: 0 }
    ]
  },
  {
    question: 'Proprietário / adm. fora quadra',
    id: 'proprietarioAdmForaQuadra',
    answers: [
      { text: 'Sim', value: 3 },
      { text: 'Não', value: 0 }
    ]
  },
  {
    question: 'Entrada recente sócio empresa',
    id: 'entradaRecenteSocioEmpresa',
    answers: [
      { text: '> 5 Anos', value: 0 },
      { text: 'De 3 a 5 Anos', value: 1 },
      { text: 'De 1 a 3 Anos', value: 2 },
      { text: 'De 7 Meses a 1 Ano', value: 3 },
      { text: '< 7 Meses', value: 4 }
    ]
  },
  {
    question: 'Restritivos / apontamentos',
    id: 'restritivosApontamentos',
    answers: [
      { text: 'Não', value: 0 },
      { text: 'Impostos, RJ DEF/HOM', value: 1 },
      { text: 'Bancos', value: 2 },
      { text: 'Fornecedores e/ou Liminar', value: 3 },
      { text: 'Fundos, Pedido de RJ e Falência', value: 4 }
    ]
  },
  {
    question: 'Fundação inferior a 5 anos',
    id: 'fundacaoInferior5Anos',
    answers: [
      { text: 'Sim', value: 4 },
      { text: 'Não', value: 2 }
    ]
  },
  {
    question: 'Setores',
    id: 'setores',
    answers: [
      { text: 'Normais', value: 0 },
      { text: 'Restritos', value: 2 },
      { text: 'Proibidos', value: 4 }
    ]
  },
  {
    question: 'Endereço eletrônico (gmail, hotmail)',
    id: 'enderecoEletronico',
    answers: [
      { text: 'Sim', value: 3 },
      { text: 'Não', value: 0 }
    ]
  }
]

export default questionnaires
