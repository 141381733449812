import React from 'react'
import { Route } from 'react-router-dom'

import { Header, Main, Footer } from '../../components'

export default function CnabRoute ({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => (
        <WrappedComponent>
          <Component {...props} />
        </WrappedComponent>
      )}
    />
  )
}

const WrappedComponent = ({ children }) => {
  return (
    <>
      <Header />
      <Main>
        <div className='d-flex flex-grow-1 flex-row my-4'>
          <main className='d-flex flex-grow-1'>{children}</main>
        </div>
      </Main>
      <Footer />
    </>
  )
}
