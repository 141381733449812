import React, { useState } from 'react'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import { useSelector } from 'react-redux'
import Popup from 'reactjs-popup'

import { Table } from '../../../../../components'
import mapWithRelationship, {
  mapWithFullDocumentAndRelationship,
  SOCIO_NUMBER
} from '../../../../../lib/detectRelationship'
import { selectRelateds } from '../../../../../redux/selectors/relateds'
import {
  onSortDate,
  onSortDocument
} from '../../../../../components/Table/sort'
import {
  formatDate,
  formatDocument,
  hashObject,
  onlyNumbers
} from '../../../../../utils'

export default function Socios ({ data, error }) {
  const relateds = useSelector(selectRelateds)
  const [filiais, setFiliais] = useState({
    data: [],
    open: false
  })
  const { receitaFederal } = data?.socios || {}

  if (error) {
    return <div>Erro no carregamento dos dados</div>
  }

  const tableData = mapWithFullDocumentAndRelationship({
    array: receitaFederal || [],
    relateds,
    documentKey: 'cnpjCpfSocio',
    nameKey: 'nomeRazaoSocial'
  }).map(item => {
    const fullDocument = item.fullDocument || undefined
    const relationshipNumber = item.relationshipNumber || 0
    const isSocios = relationshipNumber & SOCIO_NUMBER

    if (isSocios) {
      return { ...item, fullDocument }
    } else {
      return { ...item, fullDocument: undefined }
    }
  })

  const columns = [
    {
      dataField: 'nome',
      text: 'Nome',
      sort: true
    },
    {
      dataField: 'documento',
      text: 'CPF / CNPJ',
      sort: true,
      sortFunc: onSortDocument
    },
    {
      dataField: 'qualificacao',
      text: 'Qualificação',
      sort: true
    },
    {
      dataField: 'relationship',
      text: 'Grau',
      sort: true
    },
    {
      dataField: 'dataEntrada',
      text: 'Data de entrada',
      sortFunc: onSortDate,
      sort: true
    },
    {
      dataField: 'filiaisAtivas',
      text: 'Filiais ativas',
      sort: true
    },
    {
      dataField: 'rawDocumento',
      hidden: true
    }
  ]

  const mappedData = (tableData || []).map(item => {
    const id = hashObject(item)

    const filiais = item.filiaisAtivas || []

    return {
      id,
      rawDocumento: item.cnpjCpfSocio,
      documento: !item.cnpjCpfSocio ? (
        '-'
      ) : item.fullDocument ? (
        <a
          href={`?documento=${onlyNumbers(item.fullDocument)}`}
          target='_blank'
          rel='noopener noreferrer'
        >
          {!item.fullDocument ? '-' : formatDocument(item.fullDocument)}
        </a>
      ) : (
        item.cnpjCpfSocio
      ),
      nome: item.nomeRazaoSocial,
      dataEntrada: formatDate(item.dataEntradaSociedade),
      filiaisAtivas:
        filiais.length === 0 ? null : (
          <button
            className='btn btn-primary'
            disabled={filiais.length === 0}
            onClick={() =>
              setFiliais({
                data: filiais,
                open: true
              })
            }
          >
            Ver {filiais.length}
          </button>
        ),
      qualificacao: item.qualificacaoSocio
    }
  })

  return (
    <>
      <PopupFiliais
        filiais={filiais.data}
        open={filiais.open}
        onClose={() => setFiliais({ data: [], open: false })}
      />
      <ToolkitProvider
        keyField='id'
        data={mapWithRelationship({
          array: mappedData,
          relateds,
          documentKey: 'rawDocumento',
          nameKey: 'nome'
        })}
        columns={columns.map(item => ({
          ...item,
          align: 'center',
          headerAlign: 'center'
        }))}
        search
      >
        {props => (
          <>
            <div className='row'>
              <div className='col-12'>
                <Table {...props.baseProps} enablePagination />
              </div>
            </div>
          </>
        )}
      </ToolkitProvider>
    </>
  )
}

const PopupFiliais = ({ filiais, open, onClose }) => {
  return (
    <Popup
      closeOnDocumentClick
      modal
      nested
      contentStyle={{ maxHeight: '90vh', width: '95%', overflowY: 'scroll' }}
      open={open}
      onClose={onClose}
    >
      <div className='mx-2 my-2'>
        <div style={{ width: '100%', display: 'flex' }} className='mb-3'>
          <span style={{ flex: 1 }}>Filiais ativas</span>
          <button
            style={{
              cursor: 'pointer',
              padding: '2px 5px',
              lineHeight: '20px',
              right: '-10px',
              top: '-10px',
              fontSize: '24px',
              background: '#ffffff',
              borderRadius: '18px',
              border: '1px solid #cfcece'
            }}
            onClick={onClose}
          >
            X
          </button>
        </div>
        <table className='table table-sm table-striped col-12'>
          <thead>
            <tr>
              <th scope='col'>CNPJ</th>
              <th scope='col'>Município</th>
              <th scope='col'>UF</th>
            </tr>
          </thead>
          <tbody>
            {(filiais || []).map((item, key) => {
              const id = hashObject({ item, key })
              return (
                <tr key={id}>
                  <td>{formatDocument(item.documento)}</td>
                  <td>{item.municipio}</td>
                  <td>{item.uf}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </Popup>
  )
}
