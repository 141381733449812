import { toast } from 'react-toastify'
import { call, put, select } from 'redux-saga/effects'

import API from '../../services/api'
import { fetchDadosBasicosFromS3 } from '../../lib/s3Theme'

import { setImages, setError, setAddress } from '../actions/googleMaps'
import { selectMinData } from '../selectors/dadosBasicos'
import { Types as DadosBasicosThemeTypes } from '../store/dadosBasicosTheme'

export function * fetchAddress (action) {
  try {
    const { isCPF } = yield select(selectMinData)
    const type = action.type

    if (type === DadosBasicosThemeTypes.SET_ERROR) {
      yield put(setError(true))
      return
    }

    if (type !== DadosBasicosThemeTypes.SET_S3_PATH) {
      return
    }

    const payload = yield select(state => state.dadosBasicosTheme)

    const dadosBasicos = yield call(fetchDadosBasicosFromS3, { payload })

    if (!dadosBasicos.loaded || dadosBasicos.error) {
      return
    }

    if (isCPF) {
      const {
        tipoLogradouro,
        bairro,
        cep,
        logradouro,
        municipio,
        numero,
        uf: estado
      } = dadosBasicos.data?.dadosBasicos?.enderecos?.[0] || {}

      const parsed = `${tipoLogradouro} ${logradouro}, ${numero} - ${municipio} - ${estado}`
      const raw = {
        tipoLogradouro,
        bairro,
        cep,
        logradouro,
        municipio,
        numero,
        estado,
        complemento: ''
      }

      yield put(setAddress({ raw, parsed }))
    } else {
      const {
        cep,
        bairro,
        municipio,
        logradouro,
        numero,
        tipoLogradouro,
        uf: estado,
        complemento
      } = dadosBasicos.data?.dadosBasicos?.enderecos?.[0] || {}

      const parsed = `${tipoLogradouro} ${logradouro}, ${numero} - ${municipio} - ${estado}`
      const raw = {
        tipoLogradouro,
        bairro,
        cep,
        logradouro,
        municipio,
        numero,
        estado,
        complemento
      }
      yield put(setAddress({ raw, parsed }))
    }
  } catch (err) {
    console.error(err)
    toast.error('Erro ao buscar endereço', {
      toastId: 'erro-fetch-endereco',
      position: toast.POSITION.BOTTOM_RIGHT
    })
  }
}
export function * fetchGoogleMaps (action) {
  try {
    const { rawData } = action.payload

    const {
      tipoLogradouro,
      logradouro,
      numero,
      municipio,
      estado,
      cep
    } = rawData

    if (!logradouro || !numero || !municipio || !estado) {
      throw new Error('Some data is missing to find Image from Google Maps')
    }

    const data = yield call(API.googleMaps.consultar, {
      tipoLogradouro,
      logradouro,
      numero,
      municipio,
      estado,
      cep
    })
    yield put(setImages(data))
  } catch (err) {
    console.error(err)
    toast.error('Erro ao carregar dados do Google Maps', {
      position: toast.POSITION.BOTTOM_RIGHT
    })

    yield put(setError(true))
  }
}
