import axios from 'axios'
import { DOCX_API_BASE_URL } from '../../config/env'
import { toCamelCase } from '../../utils'

export const startGenerateDocumento = async ({
  queueUrl,
  document,
  hash,
  s3InputPath,
  filename,
  userId,
  scope,
  bucketOutput,
  discoveryStartedAt
}) => {
  const { data } = await axios.post(`${DOCX_API_BASE_URL}/v3/start_step`, {
    queueUrl,
    document,
    hash,
    s3InputPath,
    userId,
    filename,
    scope,
    bucketOutput,
    discoveryStartedAt
  })

  return toCamelCase(data)
}
