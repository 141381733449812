const optionsGrafoVis = {
  autoResize: true,
  layout: {
    randomSeed: undefined,
    improvedLayout: true,
    clusterThreshold: 150,
    hierarchical: {
      enabled: true,
      levelSeparation: 300,
      nodeSpacing: 400,
      treeSpacing: 100,
      blockShifting: true,
      edgeMinimization: true,
      parentCentralization: true,
      direction: 'UD', // UD, DU, LR, RL
      sortMethod: 'hubsize' // hubsize, directed
      // roots, leaves
    }
  },
  height: '300px',
  groups: {
    PESQUISADO: {
      shape: 'icon',
      icon: {
        face: '"Font Awesome 5 Free"',
        code: '\uf005',
        size: 120,
        color: 'purple'
      }
    },
    ESTRANGEIRO: {
      shape: 'icon',
      icon: {
        face: '"Font Awesome 5 Free"',
        code: '\uf2c1',
        size: 75,
        color: 'blue'
      }
    },
    'PESSOA FÍSICA': {
      shape: 'icon',
      icon: {
        face: '"Font Awesome 5 Free"',
        code: '\uf2c1',
        size: 75,
        color: 'yellow'
      }
    },
    NULA: {
      shape: 'icon',
      icon: {
        face: '"Font Awesome 5 Free"',
        code: '\uf1ad',
        size: 75,
        color: 'black'
      }
    },
    ATIVA: {
      shape: 'icon',
      icon: {
        face: '"Font Awesome 5 Free"',
        code: '\uf1ad',
        size: 75,
        color: '#00b07d'
      }
    },
    SUSPENSA: {
      shape: 'icon',
      icon: {
        face: '"Font Awesome 5 Free"',
        code: '\uf1ad',
        size: 75,
        color: 'blue'
      }
    },
    INAPTA: {
      shape: 'icon',
      icon: {
        face: '"Font Awesome 5 Free"',
        code: '\uf1ad',
        size: 75,
        color: 'red'
      }
    },
    BAIXADA: {
      shape: 'icon',
      icon: {
        face: '"Font Awesome 5 Free"',
        code: '\uf1ad',
        size: 75,
        color: '#00000050'
      }
    }
  },
  interaction: {
    zoomView: true,
    navigationButtons: true,
    keyboard: {
      enabled: false,
      speed: { x: 10, y: 10, zoom: 0.02 },
      bindToWindow: true
    },
    multiselect: true
  },
  physics: {
    enabled: false,
    maxVelocity: 0,
    minVelocity: 0,
    stabilization: {
      enabled: false,
      iterations: 1
    }
  },
  edges: {
    width: 5,
    selectionWidth: 1,
    color: {
      color: '#00000020',
      highlight: '#9aca3c'
    }
  },
  nodes: {
    font: {
      color: '#343434',
      size: 14, // px
      face: 'arial',
      background: 'none',
      strokeWidth: 0, // px
      strokeColor: '#ffffff',
      align: 'center',
      multi: false,
      vadjust: 0,
      bold: {
        color: '#343434',
        size: 14, // px
        face: 'arial',
        vadjust: 0,
        mod: 'bold'
      },
      ital: {
        color: '#343434',
        size: 14, // px
        face: 'arial',
        vadjust: 0,
        mod: 'italic'
      },
      boldital: {
        color: '#343434',
        size: 14, // px
        face: 'arial',
        vadjust: 0,
        mod: 'bold italic'
      },
      mono: {
        color: '#343434',
        size: 15, // px
        face: 'courier new',
        vadjust: 2,
        mod: ''
      }
    }
  }
}

export default optionsGrafoVis
