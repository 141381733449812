import ToolkitProvider from 'react-bootstrap-table2-toolkit'

import { CustomWindow, Table } from '../../../../../components'
import {
  factoryDate,
  formatDate,
  hashObject,
  isPending
} from '../../../../../utils'

export default function Caixa ({ data, error, loaded }) {
  const name = 'operacionais.caixa'
  const title = 'Caixa'

  if (isPending({ error, loaded })) {
    return (
      <CustomWindow title={title} name={name}>
        <div />
      </CustomWindow>
    )
  }

  if (error) {
    return (
      <CustomWindow title={title} name={name} defaultOpen>
        <div>Ops! Aconteceu um erro ao carregar as informações de caixa</div>
      </CustomWindow>
    )
  }

  return (
    <CustomWindow title={title} name={name} defaultOpen>
      <ToolkitProvider
        keyField='id'
        columns={[
          { dataField: 'data', text: 'Data' },
          { dataField: 'acc/ace', text: 'Acc/Ace' },
          {
            dataField: 'ajustesPatrimônioLíquido',
            text: 'Ajustes Patrimônio Líquido'
          },
          { dataField: 'ativoFixo', text: 'Ativo Fixo' },
          { dataField: 'bensParaVenda', text: 'Bens para venda' },
          { dataField: 'caixaApósCapex', text: 'Caixa após Capex' },
          { dataField: 'caixaApósCrescimento', text: 'Caixa após crescimento' },
          {
            dataField: 'caixaDisponívelParaCapex',
            text: 'Caixa disponível para Capex'
          },
          { dataField: 'caixaFinal', text: 'Caixa final' },
          {
            dataField: 'capitalização/minoritários',
            text: 'Capitalização/minoritários'
          },
          {
            dataField: 'dIversosNãoOperacionaisLp',
            text: 'Diversos não operacionais LP'
          },
          {
            dataField: 'despFinanceiraPaga',
            text: 'Despesas financeiras pagas'
          },
          { dataField: 'despesasAntecipadas', text: 'Despesas antecipadas' },
          {
            dataField: 'diversosNãoOperacionaisCp',
            text: 'Diversos não operacionais CP'
          },
          { dataField: 'dividendos', text: 'Dividendos' },
          {
            dataField: 'duplicatasDescontadas',
            text: 'Duplicatas descontadas'
          },
          { dataField: 'ebitda', text: 'EBITDA' },
          {
            dataField: 'empCurtoPrazo (moedaEstrangeira)',
            text: 'Emp. curto prazo (moeda estrangeira)'
          },
          {
            dataField: 'empCurtoPrazo (reais)',
            text: 'Emp. curto prazo (reais)'
          },
          {
            dataField: 'emprestimosDeLongoPrazo',
            text: 'Empréstimos de longo prazo'
          },
          {
            dataField: 'empréstimosDeCurtoPrazo',
            text: 'Empréstimos de curto prazo'
          },
          { dataField: 'fundosIntragrupo', text: 'Fundos intragrupo' },
          {
            dataField: 'ganhoEPerdasNãoOperacionais',
            text: 'Ganho e perdas não operacionais'
          },
          { dataField: 'gerCaixaLivre', text: 'Geração de caixa livre' },
          {
            dataField: 'geraçãoDeCaixaNoPeríodo',
            text: 'Ger. de caixa no período'
          },
          { dataField: 'importação', text: 'Importação' },
          { dataField: 'impostoDeRenda', text: 'Imposto de renda' },
          { dataField: 'investimentos', text: 'Investimentos' },
          {
            dataField: 'necessidadeAdicionalDeGiro',
            text: 'Necessidade adicional de giro'
          },
          {
            dataField: 'parcelaCorrenteEmpLongoPrazo',
            text: 'Parcela corrente emp. longo prazo'
          },
          {
            dataField: 'parcelamentoDeTributos',
            text: 'Parcelamento de tributos'
          },
          {
            dataField: 'resultadoDeExercíciosFuturos',
            text: 'Resultado de exercícios futuros'
          },
          {
            dataField: 'saldoDasInversõesPermanentes',
            text: 'Saldo das inversões permanentes'
          },
          { dataField: 'suporte/(cashDrain)', text: 'Suporte/(cash drain)' },
          {
            dataField: 'usosEFontesNãoOperacionais',
            text: 'Usos e fontes não operacionais'
          }
        ].map(item => ({ ...item, align: 'center', headerAlign: 'center' }))}
        data={(data.dataCaixa || []).map((item, key) => {
          const id = hashObject({ item, key })

          return {
            ...item,
            id,
            data: formatDate(factoryDate(item.data))
          }
        })}
        // columnToggle
      >
        {props => (
          <>
            <div className='row'>
              {/* <ToggleList {...props.columnToggleProps} /> */}
            </div>
            <div className='row'>
              <div className='col-12'>
                <Table
                  enablePagination
                  keyField='id'
                  search
                  {...props.baseProps}
                />
              </div>
            </div>
          </>
        )}
      </ToolkitProvider>
    </CustomWindow>
  )
}
