import axios from 'axios'

import { GRUPO_SOCIETARIO_BASE_URL } from '../../config/env'
import { toCamelCase } from '../../utils'

export const getGrafo = async ({ document }) => {
  const url = `${GRUPO_SOCIETARIO_BASE_URL}/grafo`

  const { data } = await axios.get(url, {
    params: {
      document,
      include_fips: 't'
    }
  })

  const formattedData = toCamelCase(data)
  return formattedData
}

export const getGrafo2 = async ({ bucketName, key }) => {
  const url = `${GRUPO_SOCIETARIO_BASE_URL}/grafo2`

  const { data } = await axios.get(url, {
    params: {
      v: btoa(JSON.stringify({ bucket_name: bucketName, key: key })),
      include_fips: 't'
    }
  })

  const formattedData = toCamelCase(data)
  return formattedData
}

export const getDadosCadastrais = async ({ document, name }) => {
  const url = `${GRUPO_SOCIETARIO_BASE_URL}/dados_cadastrais`

  const { data } = await axios.get(url, {
    params: {
      document,
      name,
      get_rj: 't'
    }
  })

  const formattedData = toCamelCase(data)
  return formattedData
}

export const listarMatches = async ({ listaCNPJ }) => {
  const url = `${GRUPO_SOCIETARIO_BASE_URL}/consulta`

  const mappedCNPJ = listaCNPJ.map(cnpj => cnpj.replace(/\D/g, '').slice(0, 8))

  const { data } = await axios.post(url, {
    table: 'estabelecimentos',
    fields: [
      'cnpj_raiz',
      'cnpj_ordem',
      'cnpj_dv',
      'correio_eletronico',
      'dominio_email',
      'ddd_1',
      'ddd_2',
      'telefone_1',
      'telefone_2',
      'cep',
      'logradouro'
    ],
    key: 'cnpj_raiz',
    values: mappedCNPJ
  })

  const formattedData = toCamelCase(data)
  return formattedData
}

export const consultarCorreioEletronico = async ({ email }) => {
  const url = `${GRUPO_SOCIETARIO_BASE_URL}/correio_eletronico`

  const { data } = await axios.get(url, {
    params: {
      correio_eletronico: email
    }
  })

  const formattedData = toCamelCase(data)
  return formattedData
}

export const consultarDominioEmail = async ({ dominio }) => {
  const url = `${GRUPO_SOCIETARIO_BASE_URL}/dominio_email`

  const { data } = await axios.get(url, {
    params: {
      dominio_email: dominio
    }
  })

  const formattedData = toCamelCase(data)
  return formattedData
}

export const consultarEndereco = async ({ cep, logradouro }) => {
  const url = `${GRUPO_SOCIETARIO_BASE_URL}/endereco`

  const { data } = await axios.get(url, {
    params: {
      cep,
      logradouro
    }
  })

  const formattedData = toCamelCase(data)
  return formattedData
}

export const consultarTelefone = async ({ telefone, ddd, ordem }) => {
  const url = `${GRUPO_SOCIETARIO_BASE_URL}/telefone`

  const { data } = await axios.get(url, {
    params: {
      telefone,
      ddd,
      field: ordem
    }
  })

  const formattedData = toCamelCase(data)
  return formattedData
}
