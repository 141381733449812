import { useSelector } from 'react-redux'

import {
  CEP_FORMATTER,
  formatDate,
  PHONE_FORMATTER
} from '../../../../../utils'
import Field from '../../../../../components/Field'

export default function DadosBasicosCNPJ ({ dadosCadastrais }) {
  const address = useSelector(state => state.googleMaps.rawAddress)
  return (
    <>
      <div className='row'>
        <Field
          label='razão social'
          value={dadosCadastrais?.data?.dadosBasicos?.razaoSocial}
          className='col-3 mb-3'
        />
        <Field
          label='CNPJ'
          value={dadosCadastrais?.data?.dadosBasicos?.cnpj}
          className='col-3 mb-3'
        />
        <Field
          label='Identificador Matriz/Filial'
          value={dadosCadastrais?.data?.dadosBasicos?.identificadorMatrizFilial}
          className='col-3 mb-3'
        />
        <Field
          label='Motivo situação cadastral'
          value={dadosCadastrais?.data?.dadosBasicos?.situacaoCadastral}
          className='col-3 mb-3'
        />
        <Field
          label='capital social'
          value={dadosCadastrais?.data?.dadosBasicos?.capitalSocial}
          className='col-3'
        />
        <Field
          label='CNAE Fiscal'
          value={dadosCadastrais?.data?.dadosBasicos?.cnaeFiscalPrincipal}
          className='col-3'
        />
        <Field
          label='Qualificação Responsável'
          value={dadosCadastrais?.data?.dadosBasicos?.qualificacaoResponsavel}
          className='col-3'
        />
      </div>
      <hr />
      <div className='row'>
        <Field
          label='Data do Início das Atividades'
          value={formatDate(
            dadosCadastrais?.data?.dadosBasicos?.datas?.dataInicioAtividade ||
              ''
          )}
          className='col-3 mb-3'
        />
        <Field
          label='Data da Situação Cadastral'
          value={formatDate(
            dadosCadastrais?.data?.dadosBasicos?.datas?.dataSituacaoCadastral ||
              ''
          )}
          className='col-3 mb-3'
        />
        <Field
          label='Data da Situação Especial'
          value={formatDate(
            dadosCadastrais?.data?.dadosBasicos?.datas?.dataSituacaoEspecial ||
              ''
          )}
          className='col-3 mb-3'
        />
        <Field
          label='Data de opção pelo simples'
          value={formatDate(
            dadosCadastrais?.data?.dadosBasicos?.datas?.dataOpcaoPeloSimples ||
              ''
          )}
          className='col-3 mb-3'
        />
        <Field
          label='Data Exclusão no Simples'
          value={formatDate(
            dadosCadastrais?.data?.dadosBasicos?.datas?.dataExclusaoSimples ||
              ''
          )}
          className='col-3'
        />
      </div>
      <hr />
      <div className='row'>
        <Field
          label='tipo logradouro'
          value={address?.tipoLogradouro}
          className='col-3 mb-3'
        />
        <Field
          label='logradouro'
          value={address?.logradouro}
          className='col-3 mb-3'
        />
        <Field label='numero' value={address?.numero} className='col-3 mb-3' />
        <Field label='bairro' value={address?.bairro} className='col-3 mb-3' />
        <Field
          label='cep'
          value={CEP_FORMATTER(address?.cep)}
          className='col-3'
        />
        <Field label='municipio' value={address?.municipio} className='col-3' />
        <Field label='uf' value={address?.estado} className='col-3' />
        <Field
          label='complemento'
          value={address?.complemento}
          className='col-3'
        />
      </div>
      <hr />
      <div className='row'>
        <Field
          label='Código País'
          value={dadosCadastrais?.data?.dadosBasicos?.codigos?.pais}
          className='col-3 mb-3'
        />
        <Field
          label='telefone 1'
          value={
            PHONE_FORMATTER(dadosCadastrais?.data?.dadosBasicos?.telefone1) ||
            ''
          }
          className='col-3 mb-3'
        />
        <Field
          label='telefone 2'
          value={
            PHONE_FORMATTER(
              `${dadosCadastrais?.data?.dadosBasicos?.telefone2}`
            ) || ''
          }
          className='col-3 mb-3'
        />
        <Field
          label='telefone/fax'
          value={
            PHONE_FORMATTER(dadosCadastrais?.data?.dadosBasicos?.fax) || ''
          }
          className='col-3 mb-3'
        />
        <Field
          label='E-mail'
          value={dadosCadastrais?.data?.dadosBasicos?.correioEletronico}
          className='col-3'
        />
      </div>
      <hr />
      <div className='row'>
        <Field
          label='Código municipal (SIAFI)'
          value={dadosCadastrais?.data?.dadosBasicos?.codigos?.municipioSiafi}
          className='col-3 mb-3'
        />
        <Field
          label='Código natureza jurídica'
          value={
            dadosCadastrais?.data?.dadosBasicos?.naturezaJuridica
              ? `${dadosCadastrais?.data?.dadosBasicos?.naturezaJuridica} - ${dadosCadastrais?.data?.dadosBasicos?.codigos.naturezaJuridica} `
              : null
          }
          className='col-3 mb-3'
        />
        <Field
          label='Opção pelo MEI'
          value={dadosCadastrais?.data?.dadosBasicos?.opcaoPeloMei}
          className='col-3 mb-3'
        />
        <Field
          label='Opção pelo Simples'
          value={dadosCadastrais?.data?.dadosBasicos?.opcaoPeloSimples}
          className='col-3 mb-3'
        />
        <Field
          label='Situação Cadastral'
          value={dadosCadastrais?.data?.dadosBasicos?.situacaoCadastral}
          className='col-3'
        />
        <Field
          label='Situação Especial'
          value={dadosCadastrais?.data?.dadosBasicos?.situacaoEspecial}
          className='col-3'
        />
      </div>
    </>
  )
}
