import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import { useSelector } from 'react-redux'

import { CustomWindow, Table } from '../../../../../components'
import {
  onSortCurrency,
  onSortDocument
} from '../../../../../components/Table/sort'
import mapWithRelationship from '../../../../../lib/detectRelationship'
import { selectRelateds } from '../../../../../redux/selectors/relateds'
import {
  formatCurrency,
  formatDocument,
  hashObject,
  isCypheredDocument,
  isPending,
  onlyNumbers
} from '../../../../../utils'

const { SearchBar } = Search

export default function EmpresasPunidas ({ data, error, loaded }) {
  const relateds = useSelector(selectRelateds)

  const name = 'bandeiraAmarela.empresasPunidas'
  const title = 'Empresas Punidas'

  if (isPending({ error, loaded })) {
    return (
      <CustomWindow title={title} name={name}>
        <div />
      </CustomWindow>
    )
  }

  if (error) {
    return (
      <CustomWindow title={title} name={name} defaultOpen>
        <div>Ops! Aconteceu um erro ao carregar empresas punidas</div>
      </CustomWindow>
    )
  }

  return (
    <CustomWindow title={title} name={name}>
      <ToolkitProvider
        keyField='id'
        search
        columns={[
          { dataField: 'razaoSocial', text: 'Nome', sort: true },
          {
            dataField: 'relationship',
            text: 'Grau',
            sort: true
          },
          {
            dataField: 'cpfCnpjSancionado',
            text: 'Documento',
            searchable: false,
            sortFunc: onSortDocument,
            sort: true
          },
          { dataField: 'rawDocument', text: '', hidden: true },
          {
            dataField: 'numeroProcesso',
            text: 'Número do Processo',
            sort: true
          },
          {
            dataField: 'valorMulta',
            text: 'Valor da Multa',
            sortFunc: onSortCurrency,
            sort: true
          },
          { dataField: 'tipoSancao', text: 'Tipo de Sanção', sort: true },
          {
            dataField: 'dataInicioSancao',
            text: 'Data de Início da Sanção',
            sort: true
          },
          {
            dataField: 'dataFinalSancao',
            text: 'Data Final da Sanção',
            sort: true
          },
          {
            dataField: 'orgaoSancionador',
            text: 'Órgão Sancionador',
            sort: true
          },
          {
            dataField: 'dataOrigemInformacoes',
            text: 'Data de Origem das Informações',
            sort: true
          },
          { dataField: 'publicacao', text: 'Publicação', sort: true },
          { dataField: 'rawValorMulta', text: '', hidden: true }
        ].map(item => ({ ...item, align: 'center', headerAlign: 'center' }))}
        data={mapWithRelationship({
          array: data || [],
          documentKey: 'cpfCnpjSancionado',
          nameKey: 'razaoSocial',
          relateds
        }).map(item => {
          const id = hashObject(item)
          return {
            ...item,
            id,
            rawDocument: item.cpfCnpjSancionado,
            cpfCnpjSancionado: isCypheredDocument(item.cpfCnpjSancionado) ? (
              item.cpfCnpjSancionado
            ) : (
              <a
                href={`?documento=${onlyNumbers(item.cpfCnpjSancionado)}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                {formatDocument(item.cpfCnpjSancionado)}
              </a>
            ),
            valorMulta: formatCurrency(item.valorMulta),
            rawValorMulta: item.valorMulta
          }
        })}
      >
        {props => (
          <>
            <div className='row'>
              <div className='col-12 text-lg-right'>
                <SearchBar {...props.searchProps} placeholder='Pesquisar' />
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <Table enablePagination {...props.baseProps} />
              </div>
            </div>
          </>
        )}
      </ToolkitProvider>
    </CustomWindow>
  )
}
