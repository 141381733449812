import React, { useEffect, useState } from 'react'
import { useResizeDetector } from 'react-resize-detector'
import plotComponentFactory from 'react-plotly.js/factory'
import * as Plotly from 'plotly.js-dist'

import Loading from '../Loading'

const Plot = plotComponentFactory(Plotly)

export default function ResponsivePlot ({
  loading,
  loadingText = 'Loading',
  unloadedText,
  minHeight,
  ...props
}) {
  const [loaded, setLoaded] = useState(false)
  const { width, height, ref } = useResizeDetector({})

  const text = unloadedText || 'Sem dados'

  useEffect(() => {
    if (loading || props.data?.length > 0) {
      setLoaded(true)
    }
  }, [props.data, loading])

  return (
    <div ref={ref} style={{ display: 'flex', height: '100%' }}>
      {loading ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          width={width || '100%'}
          height={height ?? minHeight ?? 440}
        >
          <Loading size={54} />
          <div mt={4}>{loadingText}</div>
        </div>
      ) : !loaded ? (
        <div
          width={width || '100%'}
          height={height ?? minHeight ?? 440}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div>{text}</div>
        </div>
      ) : (
        <Plot
          {...props}
          config={{
            displaylogo: false,
            ...props.config,
            modeBarButtonsToRemove: [
              'pan2d',
              'autoScale2d',
              ...(props.config?.modeBarButtonsToRemove || [])
            ]
          }}
          layout={{
            ...props?.layout,
            ...{
              width: props?.layout?.width || width,
              height: props?.layout?.height || height
            }
          }}
        />
      )}
    </div>
  )
}
