import { toast } from 'react-toastify'
import { batch } from 'react-redux'

import { isValidCNPJ, isValidCPF } from './validators'

import { resetOffshoreTheme } from '../redux/store/offshoreTheme'
import { resetJuridicoTheme } from '../redux/store/juridicoTheme'
import { resetPatrimonioTheme } from '../redux/store/patrimonioTheme'
import { resetDadosBasicosTheme } from '../redux/store/dadosBasicosTheme'
import { resetEndividamentoTheme } from '../redux/store/endividamentoTheme'
import { resetOperacionaisTheme } from '../redux/store/operacionaisTheme'

import { resetGrafoData } from '../redux/actions/grafo'
import { resetGoogleMaps } from '../redux/actions/googleMaps'
import { resetMatches } from '../redux/actions/matches'
import { resetDocx } from '../redux/store/doc'
import {
  openProgressStatus,
  resetProgressStatus
} from '../redux/store/progressStatus'
import { resetGlobalStep, trocarDocumento } from '../redux/store/globalStep'
import { resetBandeiraAmarelaTheme } from '../redux/store/bandeiraAmarelaTheme'
import { resetRelateds } from '../redux/store/relateds'
import { resetSpreadTheme } from '../redux/store/spreadTheme'
import { resetVisitasTheme } from '../redux/store/visitasTheme'

import { resetSulBrasil } from '../redux/store/sulbrasil'

import { resetDiscreditingMediaTheme } from '../redux/store/discreditingMediaTheme'

const TOAST_ID_DOCUMENT_INPUT = 'error_invalid_document_input'

export default function searchDocument ({
  documento,
  enableSerasa,
  enablePartners,
  user,
  dispatch
}) {
  documento = documento.replace(/\D/g, '')
  if (documento.length !== 11 && documento.length !== 14) {
    toast.error('Documento inválido! Deve informar um CPF ou CNPJ', {
      position: toast.POSITION.BOTTOM_RIGHT,
      toastId: TOAST_ID_DOCUMENT_INPUT
    })
    return false
  }

  if (documento.length === 11 && !isValidCPF(documento)) {
    toast.error('CPF inválido', {
      position: toast.POSITION.BOTTOM_RIGHT,
      toastId: TOAST_ID_DOCUMENT_INPUT
    })
    return false
  }

  if (documento.length === 14 && !isValidCNPJ(documento)) {
    toast.error('CNPJ inválido', {
      position: toast.POSITION.BOTTOM_RIGHT,
      toastId: TOAST_ID_DOCUMENT_INPUT
    })
    return false
  }

  if (documento === '') {
    toast.error('Sem documento', {
      position: toast.POSITION.BOTTOM_RIGHT,
      toastId: TOAST_ID_DOCUMENT_INPUT
    })
    return false
  }

  batch(() => {
    dispatch(resetSulBrasil())

    dispatch(resetGoogleMaps())
    dispatch(resetGrafoData())
    dispatch(resetMatches())
    dispatch(resetDocx())

    dispatch(resetBandeiraAmarelaTheme())
    dispatch(resetDadosBasicosTheme())
    dispatch(resetOperacionaisTheme())
    dispatch(resetEndividamentoTheme())
    dispatch(resetJuridicoTheme())
    dispatch(resetOffshoreTheme())
    dispatch(resetPatrimonioTheme())
    dispatch(resetSpreadTheme())
    dispatch(resetVisitasTheme())
    dispatch(resetDiscreditingMediaTheme())

    dispatch(resetRelateds())
    dispatch(resetProgressStatus())
    dispatch(resetGlobalStep())
  })

  dispatch(trocarDocumento({ documento, enableSerasa, enablePartners }))

  dispatch(openProgressStatus())
  return true
}
