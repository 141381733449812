import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'

import mapWithRelationship from '../../../lib/detectRelationship'
import { fetchOffshoreFromS3 } from '../../../lib/s3Theme'

import { hashObject, isPending } from '../../../utils'

import { selectMinData } from '../../../redux/selectors/dadosBasicos'
import { selectRelateds } from '../../../redux/selectors/relateds'

import { Content, CustomWindow, Loading, Table } from '../../../components'
import { onSortDate, onSortNumber } from '../../../components/Table/sort'

const { SearchBar } = Search

export default function Offshore () {
  const { targetName, targetDocument } = useSelector(selectMinData)

  const offshoreTheme = useSelector(state => state.offshoreTheme)

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    offshoreLeaks: { data: [], loaded: false, error: false },
    rde: { data: [], loaded: false, error: false }
  })

  useEffect(() => {
    if (
      isPending(offshoreTheme.offshoreLeaks) &&
      isPending(offshoreTheme.rde)
    ) {
      return
    }

    setLoading(true)
    fetchOffshoreFromS3({
      offshoreLeaksPayload: offshoreTheme.offshoreLeaks,
      rdePayload: offshoreTheme.rde
    })
      .then(offshore => {
        setData(offshore)
      })
      .catch(error => {
        toast.error(error.message)
        console.error(error)
        setData({
          offshoreLeaks: { data: [], loaded: false, error: true },
          rde: { data: [], loaded: false, error: true }
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [offshoreTheme])

  const isStepPending =
    targetDocument &&
    isPending(offshoreTheme.offshoreLeaks) &&
    isPending(offshoreTheme.rde)

  return (
    <Content
      title={`Offshore ${targetName ? `- ${targetName}` : ''}`}
      subtitle='Busca por offshore na base de dados Offshore Leaks.'
    >
      {loading || isStepPending ? (
        <Loading />
      ) : (
        <>
          <ListagemEmpresasOffshore
            data={data.offshoreLeaks}
            error={offshoreTheme.error}
          />
          <ListagemCapitalEstrangeiro
            data={data.rde}
            error={offshoreTheme.error}
          />
        </>
      )}
    </Content>
  )
}

const ListagemEmpresasOffshore = React.memo(({ data, error }) => {
  const relateds = useSelector(selectRelateds)

  if (isPending(data) && !error) {
    return <div />
  }

  const name = 'offshore.listagemDeEmpresasOffshore'

  if (data.error || error) {
    return (
      <div className='col-12 px-0 mx-0'>
        <CustomWindow
          title='Listagem de Empresas Offshore'
          subtitle='Dados do Offshore Leaks'
          name={name}
        >
          <div>
            Ops! Aconteceu um erro e por isso não foi possível carregar a
            listagem
          </div>
        </CustomWindow>
      </div>
    )
  }

  return (
    <div className='col-12 px-0 mx-0'>
      <CustomWindow
        title='Listagem de Empresas Offshore'
        subtitle='Dados do Offshore Leaks'
        name={name}
      >
        <ToolkitProvider
          keyField='id'
          search
          columns={[
            { dataField: 'sourceId', text: 'Tipo', sort: true },
            { dataField: 'relType', text: 'Relacionado', sort: true },
            { dataField: 'name1', text: 'Nome PF', sort: true },
            {
              dataField: 'grau',
              text: 'Grau',
              sort: true
            },
            { dataField: 'name2', text: 'Nome PJ', sort: true },
            { dataField: 'address', text: 'Endereço', sort: true },
            {
              dataField: 'startDate',
              text: 'Início',
              sort: true,
              sortFunc: onSortDate
            },
            {
              dataField: 'endDate',
              text: 'Término',
              sort: true,
              sortFunc: onSortDate
            }
          ].map(item => ({ ...item, headerAlign: 'center', align: 'center' }))}
          data={mapWithRelationship({
            array: data.data || [],
            nameKey: 'name1',
            relateds
          }).map(item => {
            const id = hashObject(item)

            return {
              id,
              sourceId: item.sourceId,
              relType: item.relType,
              name1: item.name1,
              name2: item.name2,
              grau: item.relationship,
              address: item.address,
              startDate: item.startDate,
              endDate: item.endDate
            }
          })}
        >
          {props => (
            <>
              <div className='row'>
                <div className='col-12 text-right'>
                  <SearchBar {...props.searchProps} placeholder='Pesquisar' />
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <Table enablePagination {...props.baseProps} />
                </div>
              </div>
            </>
          )}
        </ToolkitProvider>
      </CustomWindow>
    </div>
  )
})

const ListagemCapitalEstrangeiro = React.memo(({ data, error }) => {
  const relateds = useSelector(selectRelateds)
  const name = 'offshore.listagemCapitalEstrangeiro'
  const subtitle = 'Dados retirados do Banco Central'

  if (isPending(data) && !error) {
    return <div />
  }

  if (data.error || error) {
    return (
      <div className='col-12 px-0 mx-0'>
        <CustomWindow
          title='Capital Estrangeiro'
          subtitle={subtitle}
          name={name}
          showNote
        >
          <div>
            Ops! Aconteceu um erro e por isso não foi possível carregar a
            listagem
          </div>
        </CustomWindow>
      </div>
    )
  }

  return (
    <div className='col-12 px-0 mx-0'>
      <CustomWindow
        title='Capital Estrangeiro'
        subtitle={subtitle}
        name={name}
        showNote
      >
        <ToolkitProvider
          keyField='id'
          search
          columns={[
            {
              dataField: 'nomePessoaNacional',
              text: 'Nome pessoa nacional',
              sort: true
            },
            {
              dataField: 'grau',
              text: 'Grau',
              sort: true
            },
            {
              dataField: 'nomePessoaEstrangeira',
              text: 'Nome pessoa estrangeira',
              sort: true
            },
            { dataField: 'ano', text: 'Ano', sort: true },
            { dataField: 'mes', text: 'Mês', sort: true },
            { dataField: 'uf', text: 'UF', sort: true },
            {
              dataField: 'paisPessoaEstrangeira',
              text: 'País pessoa estrangeira',
              sort: true
            },
            { dataField: 'moedaOperacao', text: 'Moeda operação', sort: true },
            {
              dataField: 'valorOperacao',
              text: 'Valor operação',
              sort: true,
              sortFunc: onSortNumber
            }
          ]}
          data={mapWithRelationship({
            array: data.data || [],
            nameKey: 'queryName',
            relateds
          }).map(capital => {
            const id = hashObject(capital)

            return {
              id,
              nomePessoaNacional: capital.nomePessoaNacional,
              nomePessoaEstrangeira: capital.nomePessoaEstrangeira,
              grau: capital.relationship,
              ano: capital.ano,
              mes: capital.mes,
              uf: capital.ufPessoaNacional,
              paisPessoaEstrangeira: capital.paisPessoaEstrangeira,
              moedaOperacao: capital.moedaOperacao,
              valorOperacao: capital.valorOperacao
            }
          })}
        >
          {props => (
            <>
              <div className='row'>
                <div className='col-12 text-right'>
                  <SearchBar {...props.searchProps} placeholder='Pesquisar' />
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <Table enablePagination {...props.baseProps} />
                </div>
              </div>
            </>
          )}
        </ToolkitProvider>
      </CustomWindow>
    </div>
  )
})
