import { Types } from '../reducers/googleMaps'

// Action Creators
export function setImages (data) {
  return {
    type: Types.SET_IMAGES,
    payload: data
  }
}

export function setAddress ({ raw, parsed }) {
  return {
    type: Types.SET_ADDRESS,
    payload: { rawData: raw, parsedData: parsed }
  }
}

export function setError (data) {
  return {
    type: Types.SET_ERROR_IMAGES,
    payload: data
  }
}

export function resetGoogleMaps () {
  return {
    type: Types.RESET
  }
}
