import React, { useState } from 'react'
import { Redirect, Switch } from 'react-router-dom'

import { EXCLUSIVE_CONTENTS, StaticScopeMetadata } from '../../lib/scope'

import CnabRoute from './CnabRoute'
import { FilePicker } from './components'

export default function CnabPage () {
  const isSubApp = StaticScopeMetadata.getAvailableApps().length > 1
  const hasPermission = StaticScopeMetadata.getAvailableApps().includes(
    EXCLUSIVE_CONTENTS.APPS.CNAB
  )
  const basePath = isSubApp ? '/cnab' : ''

  if (!hasPermission) {
    return <Redirect to='/' />
  }

  return (
    <Switch>
      <CnabRoute path={`${basePath}/`} component={Content} />
    </Switch>
  )
}

const Content = () => {
  const [files, setFiles] = useState()
  return (
    <div className='container'>
      <div className='row'>
        <div className='col-12 text-center'>
          <h1 className='mb-0 flex-grow-1'>Faça upload do arquivo CNAB</h1>
        </div>
        <div className='col-12 d-flex flex-column align-items-center justify-content-center pt-4'>
          <FilePicker
            multiple
            fileType='.cpe, .rem, .cpr, .ret, .2pe, .2re, .2pr, .2rr, .pag'
            className='w-100'
            data={files}
            onChange={nextFile => setFiles(nextFile)}
            onError={err => console.error(err)}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-12 text-center mt-4'>
          <button className='btn btn-primary btn-lg text-white'>
            Processar arquivos
          </button>
        </div>
      </div>
    </div>
  )
}
