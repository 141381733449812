// Action Types
export const Types = {
  RESET: 'JURIDICO_THEME/RESET',
  ADD_RELATED_STATUS: 'JURIDICO_THEME/ADD_RELATED_STATUS',
  SET_RELATEDS: 'JURIDICO_THEME/SET_RELATEDS',
  FINISHED: 'JURIDICO_THEME/FINISHED',
  SET_ERROR: 'JURIDICO_THEME/SET_ERROR'
}

// Reducer
const initialState = {
  relateds: [],
  error: false,
  loaded: false
}

export default function reducer (state = initialState, action) {
  switch (action.type) {
    case Types.ADD_RELATED_STATUS: {
      const { parameters, output, status } = action.payload
      const prevValue = state.relateds.find(
        item =>
          item.document === parameters.document &&
          item.name === parameters.name &&
          item.type === parameters.personType
      )
      const prevRelateds = state.relateds.filter(
        item =>
          item.document !== parameters.document ||
          item.name !== parameters.name ||
          item.type !== parameters.personType
      )
      const relateds = prevRelateds.concat({ ...prevValue, ...output, status })
      return { ...state, relateds }
    }
    case Types.FINISHED:
      return { ...state, loaded: true }
    case Types.SET_RELATEDS:
      return { ...state, relateds: action.payload }
    case Types.SET_ERROR:
      return { ...state, error: action.payload, loaded: true }
    case Types.RESET:
      return initialState
    default:
      return state
  }
}

// Action Creators
export function setErrorJuridico (data) {
  return {
    type: Types.SET_ERROR,
    payload: data
  }
}

export function addRelatedStatusJuridico ({ status, parameters, output }) {
  return {
    type: Types.ADD_RELATED_STATUS,
    payload: { status, parameters, output }
  }
}

export function setRelatedsJuridico (data) {
  return {
    type: Types.SET_RELATEDS,
    payload: data
  }
}

export function finishJuridico () {
  return {
    type: Types.FINISHED
  }
}

export function resetJuridicoTheme () {
  return {
    type: Types.RESET
  }
}
