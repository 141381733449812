import { removeAccents } from '../utils'

export const SOCIO_NUMBER = 32

export default function mapWithRelationship ({
  array,
  documentKey,
  nameKey,
  relateds,
  sort = true
}) {
  let mappedArray = mapWithCustomKey({
    array,
    documentKey,
    nameKey,
    relateds,
    outputKey: 'relationship',
    resultKey: 'relationship',
    defaultValue: 'Desconhecido',
    sort
  })

  if (sort) {
    mappedArray = mappedArray
      .map(item => ({
        ...item,
        relationshipNumber: parseInt(reverseSearch(item.relationship))
      }))
      .sort((a, b) => {
        const sortRelationship = a.relationshipNumber - b.relationshipNumber
        const sortName = a[nameKey]?.localeCompare(b[nameKey])
        const sortDocument = a[documentKey]?.localeCompare(b[documentKey])

        return sortRelationship || sortName || sortDocument
      })
  }

  return mappedArray
}

export function mapWithFullDocument ({
  array,
  documentKey,
  nameKey,
  relateds
}) {
  return mapWithCustomKey({
    array,
    documentKey,
    nameKey,
    relateds,
    outputKey: 'fullDocument',
    resultKey: 'document',
    defaultValue: 'Desconhecido'
  })
}

export const mapWithFullDocumentAndRelationship = ({
  array,
  relateds,
  documentKey,
  nameKey
}) => {
  const mappedWithFullDocument = mapWithFullDocument({
    array,
    relateds,
    documentKey,
    nameKey
  })

  return mapWithRelationship({
    array: mappedWithFullDocument,
    relateds,
    documentKey,
    nameKey
  })
}

function mapWithCustomKey ({
  array,
  documentKey,
  nameKey,
  relateds,
  resultKey,
  outputKey,
  defaultValue
}) {
  const hasDocument = !!documentKey
  const hasName = !!nameKey

  const mappedArray = array.map(item => {
    const document = hasDocument ? item[documentKey]?.trim() : null
    const name = hasName
      ? removeAccents(item[nameKey]?.toUpperCase().trim())
      : null
    const documentExactMatch =
      hasDocument &&
      document &&
      relateds.find(related => related.document === document)
    const nameMatch =
      hasName && name && relateds.find(related => related.name === name)

    const documentProbableMatch =
      hasDocument && !documentExactMatch
        ? matchEncrypted({ document, array: relateds, documentKey: 'document' })
        : null
    const probableFullMatch =
      documentProbableMatch && documentProbableMatch.name === name

    return {
      ...item,
      [outputKey]:
        documentExactMatch?.[resultKey] ||
        (probableFullMatch && documentProbableMatch?.[resultKey]) ||
        nameMatch?.[resultKey] ||
        defaultValue
    }
  })

  return mappedArray
}

const matchEncrypted = ({ document, array, documentKey }) => {
  if (!document) {
    return null
  }

  const groupsToMatch = []

  const splitedDocument = document.split('*')
  let length = 0
  splitedDocument.forEach(splitedDocumentPart => {
    if (splitedDocumentPart > 0) {
      groupsToMatch.push({
        start: length,
        end: length + splitedDocumentPart.length,
        token: splitedDocumentPart
      })
      length += splitedDocumentPart.length + 1
    } else {
      length += 1
    }
  })

  const match = array.find(item => {
    return groupsToMatch.every(group => {
      const value = item[documentKey]

      if (!value) {
        return null
      }

      return value.slice(group.start, group.end) === group.token
    })
  })

  return match
}

const getCombinations = (array, size) => {
  function p (t, i) {
    if (t.length === size) {
      result.push(t)
      return
    }
    if (i + 1 > array.length) {
      return
    }
    p(t.concat(array[i]), i + 1)
    p(t, i + 1)
  }

  var result = []
  p([], 0)
  return result
}

const _relatedsTypes = {
  1: 'Esposa(o)',
  2: 'Mãe',
  4: 'Pai',
  8: 'Irmã(o)',
  16: 'Filha(o)',
  32: 'Sócia(o)',
  64: 'Tia(o)',
  128: 'Prima(o)',
  256: 'Sobrinha(o)',
  512: 'Avó(ô)',
  1024: 'Neta(o)',
  2048: 'Correlacionada(o)',
  32768: 'Potencial parente'
}

const combinations = getCombinations(Object.keys(_relatedsTypes), 2).reduce(
  (obj, pair) => {
    obj[pair[0] ^ pair[1]] = `${_relatedsTypes[pair[0]]} ${
      _relatedsTypes[pair[1]]
    }`
    return obj
  },
  {}
)

const allHumanRelationTypes = Object.assign({}, _relatedsTypes, combinations)

const maxHumanIndex = Math.max.apply(null, Object.keys(allHumanRelationTypes))

const companiesRelactionTypes = {
  [maxHumanIndex + 1]: 'Empresa com ligação direta',
  [maxHumanIndex + 2]: 'Empresa com ligação familiar',
  [maxHumanIndex + 3]: 'Empresa com ligação correlacionada',
  [maxHumanIndex + 4]: 'Empresa com ligação indireta',
  [maxHumanIndex + 5]: 'Empresa com ligação direta e familiar',
  [maxHumanIndex + 6]: 'Empresa com ligação indireta e familiar',
  [maxHumanIndex + 7]: 'Empresa com ligação correlacionada e familiar'
}

const maxCompaniesIndex = Math.max.apply(
  null,
  Object.keys(companiesRelactionTypes)
)

const reverseHumanTypes = Object.entries(allHumanRelationTypes).reduce(
  (acc, [key, value]) => {
    acc[value] = key
    return acc
  },
  {}
)

const reverseCompaniesTypes = Object.entries(companiesRelactionTypes).reduce(
  (acc, [key, value]) => {
    acc[value] = key
    return acc
  },
  {}
)

const reverseSearch = value => {
  if (!value) {
    return
  }

  if (value === 'Target') {
    return 0
  }

  if (reverseCompaniesTypes[value]) {
    return reverseCompaniesTypes[value]
  }

  if (value === 'Desconhecido') {
    return maxCompaniesIndex + 1 // Indice para o desconhecido
  }

  return reverseHumanTypes[value]
}
