import React, { useState, useEffect, useCallback } from 'react'
import { useBeforeunload } from 'react-beforeunload'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom'
import Swal from 'sweetalert2'
import { backOff } from 'exponential-backoff'
import { ToastContainer, toast } from 'react-toastify'
import { translations } from '@aws-amplify/ui-react'
import { Auth, Amplify, I18n, Hub as AwsHub } from 'aws-amplify'

import 'react-step-progress-bar/styles.css'
import 'react-toastify/dist/ReactToastify.css'

// Pages
import { Hub, Login, Processing } from './pages'

import DossieRouter from './apps/dossie'
import CnabRouter from './apps/cnab'

import { usePrevious, toCamelCase, getCurrentUser, getIdToken } from './utils'

import { HubRoute } from './components/PrivateRoute'

import { selectProgressStatus } from './redux/selectors/progressStatus'
import { selectMinData } from './redux/selectors/dadosBasicos'
import { saveDocx } from './redux/store/doc'

import searchDocument from './lib/searchDocument'
import { SQSConsumer } from './lib/sqs'
import { PROGRESS_TYPES } from './lib/progressStatus'

import {
  BOANOTA_PROVIDER_NAME,
  BOANOTA_TOAST_ID,
  BASIC_INFO_PROVIDER_NAME,
  BASIC_INFO_TOAST_ID,
  OFFSHORE_PROVIDER_NAME,
  OFFSHORE_TOAST_ID,
  PATRIMONIO_PROVIDER_NAME,
  PATRIMONIO_TOAST_ID,
  ENDIVIDAMENTO_PROVIDER_NAME,
  ENDIVIDAMENTO_TOAST_ID,
  JURIDICO_PROVIDER_NAME,
  BANDEIRA_AMARELA_TOAST_ID,
  BANDEIRA_AMARELA_PROVIDER_NAME,
  JURIDICO_TOAST_ID,
  SPREAD_PROVIDER_NAME,
  VISITAS_PROVIDER_NAME,
  VISITAS_TOAST_ID,
  SPREAD_TOAST_ID,
  LOGSUL_PROVIDER_NAME,
  LOGSUL_TOAST_ID,
  MATCH_GRUPO_SOCIETARIO_TOAST_ID,
  MATCH_GRUPO_SOCIETARIO_PROVIDER_NAME,
  RISCO_JURIDICO_TOAST_ID,
  RISCO_JURIDICO_PROVIDER_NAME,
  DISCREDITING_MEDIA_PROVIDER_NAME,
  DISCREDITING_MEDIA_TOAST_ID,
  OPERACIONAIS_TOAST_ID,
  OPERACIONAIS_PROVIDER_NAME
} from './config/stepFunction'
import {
  setS3PathOffshore,
  setErrorOffshore
} from './redux/store/offshoreTheme'
import {
  setS3PathBandeiraAmarela,
  setErrorBandeiraAmarela
} from './redux/store/bandeiraAmarelaTheme'
import { setS3PathSpread, setErrorSpread } from './redux/store/spreadTheme'
import {
  setErrorEndividamento,
  setS3PathEndividamento
} from './redux/store/endividamentoTheme'
import {
  setErrorDadosBasicos,
  setS3PathDadosBasicos
} from './redux/store/dadosBasicosTheme'
import {
  setErrorPatrimonio,
  setS3PathPatrimonio
} from './redux/store/patrimonioTheme'
import {
  addRelatedStatusJuridico,
  finishJuridico,
  setRelatedsJuridico
} from './redux/store/juridicoTheme'
import {
  finishGlobalStep,
  setErrorGlobalStep,
  setMetadataGlobalStep
} from './redux/store/globalStep'
import { setErrorVisitas, setS3PathVisitas } from './redux/store/visitasTheme'
import {
  setErrorDiscreditingMedia,
  setS3PathDiscreditingMedia
} from './redux/store/discreditingMediaTheme'

import { amplifyConfig } from './config/amplify'
import {
  AWS_COGNITO_AUTH_DOMAIN,
  AWS_COGNITO_SHARED_DOMAIN
} from './config/env'
import { setErrorLogsul, setS3PathLogsul } from './redux/store/logsulTheme'
import {
  setErrorMatchGrupoSocietario,
  setS3PathMatchGrupoSocietario
} from './redux/store/matchGrupoSocietarioTheme'
import { EXCLUSIVE_CONTENTS, StaticScopeMetadata } from './lib/scope'
import {
  setErrorRiscoJuridico,
  setS3PathRiscoJuridico
} from './redux/store/riscoJuridicoTheme'
import InvestmentFundAnalysis from './apps/investment-fund-analysis'
import {
  setErrorOperacionais,
  setS3PathOperacionais
} from './redux/store/operacionaisTheme'

const DOCX_PROVIDER_NAME = 'DocxGeneratorStateMachine'
const TOAST_ID_DOCX = 'gerando-dossie'

const settings = {
  aws_cognito_region: amplifyConfig.awsCognitoRegion,
  aws_user_pools_id: amplifyConfig.awsUserPoolsId,
  aws_user_pools_web_client_id: amplifyConfig.awsUserPoolsWebClientId,
  oauth: {
    domain: AWS_COGNITO_AUTH_DOMAIN,
    scope: ['email', 'profile', 'openid'],
    redirectSignIn: window.location.origin,
    redirectSignOut: window.location.origin,
    clientId: amplifyConfig.awsUserPoolsId,
    responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
  }
}

if (window.location.hostname !== 'localhost') {
  // configura para que não tenha que se autenticar caso já esteja logado em um subdomínio
  settings.cookieStorage = {
    domain: AWS_COGNITO_SHARED_DOMAIN,
    path: '/',
    sameSite: 'strict',
    secure: true
  }
}

Amplify.configure(settings)
I18n.putVocabularies(translations)
I18n.setLanguage('pt-br')

const AuthenticationWrapper = () => {
  const [loading, setLoading] = React.useState(true)
  const [currentUser, setCurrentUser] = React.useState()

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(async currentUser => {
        await StaticScopeMetadata.updateMetadata()

        setCurrentUser(currentUser)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => setLoading(false))

    const unsubscribe = AwsHub.listen('auth', data => {
      if (data.payload.event === 'signIn') {
        setCurrentUser(data.payload.data.username)
      } else if (data.payload.event === 'signOut') {
        StaticScopeMetadata.clearMetadata()
        setCurrentUser(undefined)
      }
    })
    return unsubscribe
  }, [])

  if (loading || (currentUser && !StaticScopeMetadata.getMedata())) {
    return <Processing />
  }

  if (!currentUser) {
    return <Login />
  }

  return <Root />
}

const resolveToastErrorMessage = (
  theme,
  { succeeded, total, failedJobs, foundFile }
) => {
  if (typeof foundFile === 'boolean' && !foundFile) {
    const errorMessage = `${theme}: arquivo não encontrada`

    return errorMessage
  }

  const failed = typeof failedJobs === 'number' ? failedJobs : total - succeeded
  const errorMessage =
    failed === 1
      ? `${theme}: ${failed} de ${total} consulta falhou`
      : `${theme}: ${failed} de ${total} consultas falharam`

  return errorMessage
}

const Root = () => {
  const dispatch = useDispatch()

  const { targetDocument } = useSelector(selectMinData)
  const sulbrasilReduxData = useSelector(state => state.sulbrasil)

  const queueUrl = useSelector(state => state.globalStep.queueUrl)
  const globalStepError = useSelector(state => state.globalStep.error)
  const progressStatus = useSelector(selectProgressStatus)

  const [dossierStatus, setDossierStatus] = useState('none')

  const previousQueueUrl = usePrevious(queueUrl)

  const _callback = useCallback(
    async messages => {
      const handleDadosBasicos = message => {
        const contentData = toCamelCase(message.content.data)
        const s3Path = contentData

        if (message.content.action === 'error') {
          toast.error(`Dados básicos: Falhou`, {
            toastId: `${BASIC_INFO_TOAST_ID}-error-message`
          })

          dispatch(setErrorDadosBasicos(true))
          return
        }

        if (contentData?.total !== contentData?.succeeded) {
          const errorMessage = resolveToastErrorMessage(
            'Dados básicos',
            contentData
          )
          toast.error(errorMessage, {
            toastId: `${BASIC_INFO_TOAST_ID}-error-message`
          })
        }

        dispatch(setS3PathDadosBasicos(s3Path))
      }

      const handleSpread = message => {
        const contentData = toCamelCase(message.content.data)
        const s3Path = contentData

        if (message.content.action === 'error') {
          toast.error(`Spread: Falhou`, {
            toastId: `${SPREAD_TOAST_ID}-error-message`
          })

          dispatch(setErrorSpread(true))
          return
        }

        if (contentData?.succeeded && !contentData.foundFile) {
          const errorMessage = 'Spread: não existe arquivo'
          toast.warning(errorMessage, {
            toastId: `${SPREAD_TOAST_ID}-error-message`
          })
        }

        dispatch(setS3PathSpread(s3Path))
      }

      const handleVisitas = message => {
        const contentData = toCamelCase(message.content.data)
        const s3Path = contentData

        if (message.content.action === 'error') {
          toast.error(`Visitas: Falhou`, {
            toastId: `${VISITAS_TOAST_ID}-error-message`
          })

          dispatch(setErrorVisitas(true))
          return
        }

        if (
          contentData?.succeeded &&
          !contentData.foundFile &&
          !contentData?.outdated
        ) {
          const errorMessage = 'Visitas: não existe arquivo'
          toast.warning(errorMessage, {
            toastId: `${VISITAS_TOAST_ID}-error-message`
          })
        } else if (
          contentData?.succeeded &&
          contentData.foundFile &&
          contentData?.outdated
        ) {
          const errorMessage = 'Visitas: arquivo desatualizado'
          toast.warning(errorMessage, {
            toastId: `${VISITAS_TOAST_ID}-error-message`
          })
        }

        dispatch(setS3PathVisitas(s3Path))
      }

      const handleLogsul = message => {
        const contentData = toCamelCase(message.content.data)
        const s3Path = contentData

        if (message.content.action === 'error') {
          toast.error(`LogSul: Falhou`, {
            toastId: `${LOGSUL_TOAST_ID}-error-message`
          })

          dispatch(setErrorLogsul(true))
          return
        }

        if (contentData?.succeeded && !contentData.foundFile) {
          const errorMessage = 'LogSul: não existe arquivo'
          toast.warning(errorMessage, {
            toastId: `${LOGSUL_TOAST_ID}-error-message`
          })
        }

        dispatch(setS3PathLogsul(s3Path))
      }

      const handleOffshore = message => {
        const contentData = toCamelCase(message.content?.data || {})

        const { offshoreLeaks, registrosRde: rde } = contentData.result

        if (message.content.action === 'error') {
          toast.error(`Offshore: Todas as consultas falharam`, {
            toastId: `${OFFSHORE_TOAST_ID}-error-message`
          })
          dispatch(setErrorOffshore(true))
          return
        }

        if (contentData?.total !== contentData?.succeeded) {
          const errorMessage = resolveToastErrorMessage('Offshore', contentData)
          toast.error(errorMessage, {
            toastId: `${OFFSHORE_TOAST_ID}-error-message`
          })
        }

        if (offshoreLeaks?.total !== offshoreLeaks?.succeeded) {
          const errorMessage = resolveToastErrorMessage(
            'Offshore Leaks',
            offshoreLeaks
          )
          toast.error(errorMessage, {
            toastId: `${OFFSHORE_TOAST_ID}-offshore-leaks-error-message`
          })
        }

        if (rde?.total !== rde?.succeeded) {
          const errorMessage = resolveToastErrorMessage('Banco Central', rde)
          toast.error(errorMessage, {
            toastId: `${OFFSHORE_TOAST_ID}-offshore-banco-central-error-message`
          })
        }

        dispatch(setS3PathOffshore({ offshoreLeaks, rde }))
      }

      const handlePatrimonio = message => {
        const contentData = toCamelCase(message.content?.data || {})

        if (message.content.action === 'error') {
          toast.error(`Patrimônio: Todas as consultas falharam`, {
            toastId: `${PATRIMONIO_TOAST_ID}-error-message`
          })
          dispatch(setErrorPatrimonio(true))
          return
        }

        const { aeronaves, sncr, sigef, ibama, inpi } = contentData.result

        if (aeronaves?.total !== aeronaves?.succeeded) {
          const errorMessage = resolveToastErrorMessage('Aeronaves', aeronaves)
          toast.error(errorMessage, {
            toastId: `${PATRIMONIO_TOAST_ID}-aeronaves-error-message`
          })
        }

        if (sncr?.total !== sncr?.succeeded) {
          const errorMessage = resolveToastErrorMessage(
            'Imóveis Rurais (Cadastro rural)',
            sncr
          )
          toast.error(errorMessage, {
            toastId: `${PATRIMONIO_TOAST_ID}-cadastro-rural-error-message`
          })
        }

        if (sigef?.total !== sigef?.succeeded) {
          const errorMessage = resolveToastErrorMessage(
            'Imóveis Rurais (Ibama)',
            sigef
          )
          toast.error(errorMessage, {
            toastId: `${PATRIMONIO_TOAST_ID}-ibama-rural-error-message`
          })
        }

        if (ibama?.total !== ibama?.succeeded) {
          const errorMessage = resolveToastErrorMessage(
            'Certificado de Regularidade Ambiental',
            ibama
          )
          toast.error(errorMessage, {
            toastId: `${PATRIMONIO_TOAST_ID}-ibama-error-message`
          })
        }

        if (inpi?.total !== inpi?.succeeded) {
          const errorMessage = resolveToastErrorMessage('INPI', inpi)
          toast.error(errorMessage, {
            toastId: `${PATRIMONIO_TOAST_ID}-inpi-error-message`
          })
        }

        dispatch(setS3PathPatrimonio({ aeronaves, sncr, ibama, sigef, inpi }))
      }

      const handleBandeiraAmarela = message => {
        const contentData = toCamelCase(message.content?.data || {})

        if (message.content.action === 'error') {
          toast.error(`Pontos de atenção: Todas as consultas falharam`, {
            toastId: `${BANDEIRA_AMARELA_TOAST_ID}-error-message`
          })
          dispatch(setErrorBandeiraAmarela(true))
          return
        }

        const {
          trabalhoEscravo,
          pep,
          acordosLeniencia,
          autoInfracao,
          cepim,
          ofac,
          termoApreensao,
          termoEmbargo,
          termoSuspensao,
          cadastroExpulsoes,
          cnep,
          ceis,
          ceaf
        } = contentData.result

        if (trabalhoEscravo?.total !== trabalhoEscravo?.succeeded) {
          const errorMessage = resolveToastErrorMessage(
            'Trabalho escravo',
            trabalhoEscravo
          )
          toast.error(errorMessage, {
            toastId: `${BANDEIRA_AMARELA_TOAST_ID}-trabalhoEscravo-error-message`
          })
        }

        if (pep?.total !== pep?.succeeded) {
          const errorMessage = resolveToastErrorMessage('PEP', pep)
          toast.error(errorMessage, {
            toastId: `${BANDEIRA_AMARELA_TOAST_ID}-pep-error-message`
          })
        }

        if (acordosLeniencia?.total !== acordosLeniencia?.succeeded) {
          const errorMessage = resolveToastErrorMessage(
            'Acordos de leniência',
            acordosLeniencia
          )
          toast.error(errorMessage, {
            toastId: `${BANDEIRA_AMARELA_TOAST_ID}-acordosLeniencia-error-message`
          })
        }
        if (autoInfracao?.total !== autoInfracao?.succeeded) {
          const errorMessage = resolveToastErrorMessage(
            'Auto infração',
            autoInfracao
          )
          toast.error(errorMessage, {
            toastId: `${BANDEIRA_AMARELA_TOAST_ID}-autoInfracao-error-message`
          })
        }

        if (termoApreensao?.total !== termoApreensao?.succeeded) {
          const errorMessage = resolveToastErrorMessage(
            'Termo de apreensão',
            termoApreensao
          )
          toast.error(errorMessage, {
            toastId: `${BANDEIRA_AMARELA_TOAST_ID}-termoApreensao-error-message`
          })
        }

        if (cepim?.total !== cepim?.succeeded) {
          const errorMessage = resolveToastErrorMessage(
            'Entidades Privadas Sem Fins Lucrativos',
            cepim
          )
          toast.error(errorMessage, {
            toastId: `${BANDEIRA_AMARELA_TOAST_ID}-cepim-error-message`
          })
        }

        if (ofac?.total !== ofac?.succeeded) {
          const errorMessage = resolveToastErrorMessage(
            'Ativos Estrangeiros dos EUA',
            ofac
          )
          toast.error(errorMessage, {
            toastId: `${BANDEIRA_AMARELA_TOAST_ID}-ofac-error-message`
          })
        }

        if (termoEmbargo?.total !== termoEmbargo?.succeeded) {
          const errorMessage = resolveToastErrorMessage(
            'Termo de embargo',
            termoEmbargo
          )
          toast.error(errorMessage, {
            toastId: `${BANDEIRA_AMARELA_TOAST_ID}-termoEmbargo-error-message`
          })
        }

        if (termoSuspensao?.total !== termoSuspensao?.succeeded) {
          const errorMessage = resolveToastErrorMessage(
            'Termo de suspensão',
            termoSuspensao
          )
          toast.error(errorMessage, {
            toastId: `${BANDEIRA_AMARELA_TOAST_ID}-termoSuspensao-error-message`
          })
        }

        if (cadastroExpulsoes?.total !== cadastroExpulsoes?.succeeded) {
          const errorMessage = resolveToastErrorMessage(
            'Cadastro de expulsões',
            cadastroExpulsoes
          )
          toast.error(errorMessage, {
            toastId: `${BANDEIRA_AMARELA_TOAST_ID}-cadastroExpulsoes-error-message`
          })
        }

        if (cnep?.total !== cnep?.succeeded) {
          const errorMessage = resolveToastErrorMessage(
            'Empresas Punidas',
            cnep
          )
          toast.error(errorMessage, {
            toastId: `${BANDEIRA_AMARELA_TOAST_ID}-cnep-error-message`
          })
        }

        if (ceis?.total !== ceis?.succeeded) {
          const errorMessage = resolveToastErrorMessage(
            'Empresas Inidôneas e Suspensas',
            ceis
          )
          toast.error(errorMessage, {
            toastId: `${BANDEIRA_AMARELA_TOAST_ID}-ceis-error-message`
          })
        }

        if (ceaf?.total !== ceaf?.succeeded) {
          const errorMessage = resolveToastErrorMessage(
            'Expulsões da Administração Federal',
            ceaf
          )
          toast.error(errorMessage, {
            toastId: `${BANDEIRA_AMARELA_TOAST_ID}-ceaf-error-message`
          })
        }

        dispatch(
          setS3PathBandeiraAmarela({
            trabalhoEscravo,
            pep,
            acordosLeniencia,
            autoInfracao,
            cepim,
            ofac,
            termoApreensao,
            termoEmbargo,
            termoSuspensao,
            cadastroExpulsoes,
            cnep,
            ceis,
            ceaf
          })
        )
      }

      const handleMatchGrupoSocietario = message => {
        const contentData = toCamelCase(message.content?.data || {})

        if (message.content.action === 'error') {
          toast.error(`Match grupo societário: Todas as consultas falharam`, {
            toastId: `${MATCH_GRUPO_SOCIETARIO_TOAST_ID}-error-message`
          })
          dispatch(setErrorMatchGrupoSocietario(true))
          return
        }

        const { email, endereco, telefone1, telefone2 } = contentData.result

        if (email?.failedJobs) {
          const errorMessage = resolveToastErrorMessage('Match e-mail', email)
          toast.error(errorMessage, {
            toastId: `${MATCH_GRUPO_SOCIETARIO_TOAST_ID}-email-error-message`
          })
        }

        if (endereco?.failedJobs) {
          const errorMessage = resolveToastErrorMessage(
            'Match endereço',
            endereco
          )
          toast.error(errorMessage, {
            toastId: `${MATCH_GRUPO_SOCIETARIO_TOAST_ID}-endereco-error-message`
          })
        }

        if (telefone1?.failedJobs) {
          const errorMessage = resolveToastErrorMessage(
            'Match telefone 1',
            telefone1
          )
          toast.error(errorMessage, {
            toastId: `${MATCH_GRUPO_SOCIETARIO_TOAST_ID}-telefone1-error-message`
          })
        }

        if (telefone2?.failedJobs) {
          const errorMessage = resolveToastErrorMessage(
            'Match telefone 2',
            telefone2
          )
          toast.error(errorMessage, {
            toastId: `${MATCH_GRUPO_SOCIETARIO_TOAST_ID}-telefone2-error-message`
          })
        }

        dispatch(
          setS3PathMatchGrupoSocietario({
            email,
            endereco,
            telefone1,
            telefone2
          })
        )
      }

      const handleJuridico = message => {
        const contentData = toCamelCase(message.content?.data || {})

        const { name, document, personType, type } = contentData
        const { bucketName, key } = contentData || {}

        if (message.content.action === 'error') {
          toast.error(`Jurídico: ${document} falhou`, {
            toastId: `${JURIDICO_TOAST_ID}-${document}-error-message`
          })
          dispatch(
            addRelatedStatusJuridico({
              parameters: { name, document, personType: personType || type },
              output: { bucketName, key },
              status: PROGRESS_TYPES.ERROR
            })
          )
          return
        }

        const { relateds } = contentData
        if (relateds) {
          dispatch(
            setRelatedsJuridico(
              relateds.map(({ bucketName, key, personType, type, ...item }) => {
                return {
                  ...item,
                  type: personType || type,
                  bucketName,
                  key,
                  status: item.status || PROGRESS_TYPES.PENDING
                }
              })
            )
          )
          return
        }

        dispatch(
          addRelatedStatusJuridico({
            parameters: { name, document, personType },
            output: { bucketName, key },
            status: PROGRESS_TYPES.LOADED
          })
        )
      }

      const handleEndividamento = message => {
        const contentData = toCamelCase(message.content?.data || {})

        if (message.content.action === 'error') {
          toast.error(`Endividamento: Todas as consultas falharam`, {
            toastId: `${ENDIVIDAMENTO_TOAST_ID}-error-message`
          })
          dispatch(setErrorEndividamento(true))
          return
        }

        const { cheque, cndt, pgfn, protesto, comprot } = contentData.result

        if (cheque.total !== cheque.succeeded) {
          const errorMessage = resolveToastErrorMessage('Cheques', cheque)
          toast.error(errorMessage, {
            toastId: `${ENDIVIDAMENTO_TOAST_ID}-cheques-error-message`
          })
        }

        if (cndt.total !== cndt.succeeded) {
          const errorMessage = resolveToastErrorMessage('CNDT', cndt)
          toast.error(errorMessage, {
            toastId: `${ENDIVIDAMENTO_TOAST_ID}-cndt-error-message`
          })
        }

        if (pgfn.total !== pgfn.succeeded) {
          const errorMessage = resolveToastErrorMessage('PGFN', pgfn)
          toast.error(errorMessage, {
            toastId: `${ENDIVIDAMENTO_TOAST_ID}-pgfn-error-message`
          })
        }

        if (protesto.total !== protesto.succeeded) {
          const errorMessage = resolveToastErrorMessage('Protesto', protesto)
          toast.error(errorMessage, {
            toastId: `${ENDIVIDAMENTO_TOAST_ID}-protesto-error-message`
          })
        }

        if (comprot.total !== comprot.succeeded) {
          const errorMessage = resolveToastErrorMessage('Comprot', comprot)
          toast.error(errorMessage, {
            toastId: `${ENDIVIDAMENTO_TOAST_ID}-comprot-error-message`
          })
        }

        dispatch(
          setS3PathEndividamento({ cheque, cndt, pgfn, protesto, comprot })
        )
      }

      const handleOperacionais = message => {
        const contentData = toCamelCase(message.content?.data || {})

        if (message.content.action === 'error') {
          toast.error(`Match funcionários: Todas as consultas falharam`, {
            toastId: `${OPERACIONAIS_TOAST_ID}-error-message`
          })
          dispatch(setErrorOperacionais(true))
          return
        }

        if (contentData?.total !== contentData?.succeeded) {
          const errorMessage = resolveToastErrorMessage(
            'Match funcionários',
            contentData
          )
          toast.error(errorMessage, {
            toastId: `${OPERACIONAIS_TOAST_ID}-error-message`
          })
        }

        dispatch(setS3PathOperacionais(contentData))
      }

      const handleRiscoJuridico = message => {
        const contentData = toCamelCase(message.content?.data || {})

        if (message.content.action === 'error') {
          toast.error(`Risco jurídico: Todas as consultas falharam`, {
            toastId: `${RISCO_JURIDICO_TOAST_ID}-error-message`
          })
          dispatch(setErrorRiscoJuridico(true))
          return
        }

        dispatch(setS3PathRiscoJuridico(contentData))
      }

      const handleDiscreditingMedia = message => {
        const contentData = toCamelCase(message.content?.data || {})

        const { news: discreditingNews } = contentData.result

        if (message.content.action === 'error') {
          toast.error(`Midia Desabonadora: Todas as consultas falharam`, {
            toastId: `${DISCREDITING_MEDIA_TOAST_ID}-error-message`
          })
          dispatch(setErrorDiscreditingMedia(true))
          return
        }

        if (discreditingNews?.total !== discreditingNews?.succeeded) {
          const errorMessage = resolveToastErrorMessage(
            'Notícias Desabonadoras',
            discreditingNews
          )
          toast.error(errorMessage, {
            toastId: `${DISCREDITING_MEDIA_TOAST_ID}-discrediting-news-error-message`
          })
        }

        dispatch(setS3PathDiscreditingMedia({ discreditingNews }))
      }

      const handleRoot = async message => {
        if (message.content.action === 'end') {
          dispatch(finishGlobalStep())
          const accessToken = await getIdToken()
          const sqsInstance = await SQSConsumer.getInstance({ accessToken })

          if (sqsInstance.providers.includes(DOCX_PROVIDER_NAME)) {
            return
          }
          sqsInstance.stop()

          dispatch(setMetadataGlobalStep({ finishedAt: new Date() }))
          toast.success('Carregamento concluído', {
            toastId: `${BOANOTA_TOAST_ID}-success-message`
          })
          return
        }

        if (message.content.action === 'error') {
          toast.error(`Boa nota: Todas as consultas falharam`, {
            toastId: `${BOANOTA_TOAST_ID}-error-message`
          })
          dispatch(setErrorGlobalStep(true))
        }
      }

      const handleDocx = message => {
        const contentMetadata = toCamelCase(message.content?.metadata || {})

        if (message.content.action === 'error') {
          toast.error(`Erro ao gerar dossiê`, {
            toastId: `${TOAST_ID_DOCX}-error-message`
          })
          toast.dismiss(TOAST_ID_DOCX)
          setDossierStatus('error')
          return
        }
        const { bucket, key, link } = contentMetadata
        dispatch(saveDocx({ bucket, key, link }))
        setDossierStatus('loaded')
      }

      while (messages.length > 0) {
        const message = messages.shift()
        console.debug('Message:', message)

        if (message.messageGroupId === BOANOTA_PROVIDER_NAME) {
          handleRoot(message)
          continue
        }

        if (message.content.action === 'end') {
          if (message.messageGroupId === JURIDICO_PROVIDER_NAME) {
            dispatch(finishJuridico())
          }
          continue
        }

        if (message.messageGroupId === JURIDICO_PROVIDER_NAME) {
          handleJuridico(message)
        } else if (message.messageGroupId === DOCX_PROVIDER_NAME) {
          handleDocx(message)
        } else if (message.messageGroupId === SPREAD_PROVIDER_NAME) {
          handleSpread(message)
        } else if (message.messageGroupId === PATRIMONIO_PROVIDER_NAME) {
          handlePatrimonio(message)
        } else if (message.messageGroupId === BASIC_INFO_PROVIDER_NAME) {
          handleDadosBasicos(message)
        } else if (message.messageGroupId === OFFSHORE_PROVIDER_NAME) {
          handleOffshore(message)
        } else if (message.messageGroupId === OPERACIONAIS_PROVIDER_NAME) {
          handleOperacionais(message)
        } else if (message.messageGroupId === BANDEIRA_AMARELA_PROVIDER_NAME) {
          handleBandeiraAmarela(message)
        } else if (message.messageGroupId === ENDIVIDAMENTO_PROVIDER_NAME) {
          handleEndividamento(message)
        } else if (message.messageGroupId === VISITAS_PROVIDER_NAME) {
          handleVisitas(message)
        } else if (message.messageGroupId === LOGSUL_PROVIDER_NAME) {
          handleLogsul(message)
        } else if (message.messageGroupId === RISCO_JURIDICO_PROVIDER_NAME) {
          handleRiscoJuridico(message)
        } else if (
          message.messageGroupId === MATCH_GRUPO_SOCIETARIO_PROVIDER_NAME
        ) {
          handleMatchGrupoSocietario(message)
        } else if (
          message.messageGroupId === DISCREDITING_MEDIA_PROVIDER_NAME
        ) {
          handleDiscreditingMedia(message)
        }
      }
    },
    [dispatch]
  )

  useEffect(() => {
    setDossierStatus('none')
  }, [targetDocument])

  useBeforeunload(event => {
    if (!sulbrasilReduxData.formularioSalvo) {
      event.preventDefault()
    }
  })

  useEffect(() => {
    if (previousQueueUrl === queueUrl) {
      return
    }

    const execute = async () => {
      const accessToken = await getIdToken()

      const sqsInstance = await SQSConsumer.getInstance({ accessToken })

      if (globalStepError) {
        sqsInstance.stop()
        sqsInstance.queueUrl = undefined
        return
      }

      sqsInstance.callback = _callback
      sqsInstance.queueUrl = queueUrl
      sqsInstance.addProvider(BOANOTA_PROVIDER_NAME)
      try {
        await backOff(() => sqsInstance.consume(), { startingDelay: 0 })
      } catch (err) {
        console.error(err)
      }
    }

    execute()
  }, [dispatch, previousQueueUrl, queueUrl, globalStepError, _callback])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const documento = urlParams.get('documento')

    if (documento) {
      Swal.fire({
        title: 'Pesquisando CNPJ/CPF'
      })

      getCurrentUser()
        .then(user => {
          const result = searchDocument({ documento, user, dispatch })
          if (result) {
            Swal.close()
          }
        })
        .catch(err => {
          console.error(err)
          toast.error(err.message, {
            position: toast.POSITION.BOTTOM_RIGHT
          })
        })
    }
  }, [dispatch])

  const hasMultipleApps = StaticScopeMetadata.getAvailableApps().length > 1

  return (
    <div
      className='position-relative h-100 d-flex flex-grow-1 flex-column'
      style={{ maxWidth: '100vw' }}
    >
      <BrowserRouter>
        <Switch>
          {hasMultipleApps ? (
            <>
              <HubRoute path='/' exact component={Hub} />
              {StaticScopeMetadata.getAvailableApps().includes(
                EXCLUSIVE_CONTENTS.APPS.DOSSIER
              ) && <Route path='/dossie' component={DossieRouter} />}
              {StaticScopeMetadata.getAvailableApps().includes(
                EXCLUSIVE_CONTENTS.APPS.CNAB // TODO: mudar para CNAB
              ) && <Route path='/cnab' component={CnabRouter} />}
              {StaticScopeMetadata.getAvailableApps().includes(
                EXCLUSIVE_CONTENTS.APPS.INVESTMENT_FUND_ANALYSIS
              ) && (
                <Route
                  path='/analise-fundo'
                  component={InvestmentFundAnalysis}
                />
              )}
            </>
          ) : (
            <Route path='/' component={DossieRouter} />
          )}
          <Route path='*'>
            <Redirect to='/' />
          </Route>
        </Switch>
      </BrowserRouter>
      <ToastContainer
        position='bottom-right'
        autoClose={8000}
        hideProgressBar
        newestOnTop={false}
        draggable
        pauseOnVisibilityChange
        closeOnClick
        pauseOnHover
      />
      <div
        className='d-none'
        data-test={
          !targetDocument
            ? 'progress-none'
            : progressStatus.progressLoaded === 100
            ? 'progress-loaded'
            : 'progress-loading'
        }
      />
      <div className='d-none' data-test={`dossier-status-${dossierStatus}`} />
    </div>
  )
}

export default AuthenticationWrapper
