import React, { useState, useEffect, useCallback } from 'react'
import { Carousel, Modal } from 'react-bootstrap'
import { connect, useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { toast } from 'react-toastify'

import { selectMinData } from '../../../redux/selectors/dadosBasicos'

import API from '../../../services/api'
import {
  CNPJ_FORMATTER,
  formatDocument,
  hashObject,
  isCNPJ,
  isPending
} from '../../../utils'
import { GraphNetwork } from '../../../lib/graph-js'
import { fetchGraphMetadataFromS3 } from '../../../lib/s3Theme'

import { Content, GrafoVis, CustomWindow, Loading } from '../../../components'
import MaisInformacoesGrafo from '../../../components/MaisInformacoesGrafo'

import '../../../assets/sass/grafo.scss'
import { setMatchesData } from '../../../redux/actions/matches'

const Grafo = props => {
  const { dispatch } = props
  const { isCPF, targetName, targetDocument } = useSelector(selectMinData)
  const dadosBasicosTheme = useSelector(state => state.dadosBasicosTheme)

  const selectedNodes = useSelector(state => state.grafo.selectedNodes)

  const [grafo, setGrafo] = useState({ data: {}, loaded: false, error: false })

  const [googleMapsPopup, setGoogleMapsPopup] = useState({
    visible: false,
    images: []
  })

  useEffect(() => {
    if (!targetDocument || isPending(dadosBasicosTheme)) {
      return
    }

    fetchGraphMetadataFromS3({
      payload: dadosBasicosTheme
    })
      .catch(err => {
        console.error(err)
        toast.error('Erro ao carregar dados do grafo')
        setGrafo({ data: {}, loaded: true, error: true })
      })
      .then(graphMetadata => {
        try {
          const network = new GraphNetwork({ name: 'myNetwork' })
          const data = network.generateVisGraph(graphMetadata, true)
          setGrafo({ data, loaded: true, error: false })
        } catch (err) {
          console.error(err)
          toast.error('Erro ao carregar dados do grafo')
          setGrafo({ data: {}, loaded: true, error: true })
        }

        const listaCNPJ = Array.from(
          new Set(
            graphMetadata
              .filter(item => isCNPJ(item.document))
              .map(item => item.document)
          )
        )

        if (listaCNPJ.length === 0) {
          return Promise.resolve([])
        }

        return API.grupoSocietario.matches.listar({ listaCNPJ })
      })
      .then(data => {
        dispatch(setMatchesData(data))
      })
      .catch(err => {
        console.error(err)
        toast.error('Erro ao carregar dados cadastrais das empresas envolvidas')
      })
  }, [targetDocument, dadosBasicosTheme, dispatch])

  const isGraphPending = targetDocument && !grafo?.loaded

  return (
    <>
      <ReactTooltip />
      <Content
        title={`Grupo Societário ${targetName ? `- ${targetName}` : ''}`}
        subtitle='Dados principais retirados da receita federal e fontes internas'
      >
        {isGraphPending ? (
          <Loading />
        ) : (
          <>
            <div className='row'>
              <div className='col-12'>
                <CustomWindow
                  title={`Grafo ${targetName ? `- ${targetName}` : ''}`}
                  subtitle={`${
                    targetDocument
                      ? isCPF
                        ? 'CPF:'
                        : 'CNPJ:'
                      : 'Dados Básicos'
                  } ${formatDocument(targetDocument)}`}
                  name='grupoSocietario.grafo'
                >
                  <GrafoVis
                    data={grafo.data}
                    error={grafo.error}
                    loaded={grafo.loaded}
                  />
                </CustomWindow>
              </div>

              <div className='col-12 mx-0'>
                <Match grafo={grafo.data} />
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <div className='row'>
                  {selectedNodes?.nodes?.map((item, index) => {
                    const id = hashObject(item)
                    return (
                      <div
                        key={id}
                        className={
                          selectedNodes?.nodes?.length <= 1
                            ? 'col-12 col-sm-12'
                            : 'col-6 col-sm-6'
                        }
                      >
                        <MaisInformacoesGrafo
                          selectedNode={item}
                          onClick={images =>
                            setGoogleMapsPopup({ visible: true, images })
                          }
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </>
        )}
      </Content>
      <Modal
        show={googleMapsPopup.visible}
        onHide={() => setGoogleMapsPopup({ visible: false, images: [] })}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-center'>
            Imagens retiradas do Google Maps
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Carousel>
            {googleMapsPopup.images.map((item, index) => {
              return (
                <Carousel.Item key={index.toString()}>
                  <img
                    alt='Imagem do google maps'
                    style={{ maxWidth: '90vh' }}
                    src={item}
                  />
                </Carousel.Item>
              )
            })}
          </Carousel>
        </Modal.Body>
      </Modal>
    </>
  )
}

const Match = ({ grafo }) => {
  const matchesRedux = useSelector(state => state.matches)
  const [tipoFiltro, setTipoFiltro] = useState('email')
  const [valorFiltro, setValorFiltro] = useState(undefined)
  const [resultados, setResultados] = useState({})

  const handleFilter = useCallback(async () => {
    if (!valorFiltro) {
      return
    }

    const func = {
      dominio: API.grupoSocietario.matches.consultarDominioEmail,
      email: API.grupoSocietario.matches.consultarCorreioEletronico,
      endereco: API.grupoSocietario.matches.consultarEndereco,
      telefone: API.grupoSocietario.matches.consultarTelefone
    }

    const toastId = 'toast-matches'

    toast('Buscando matches...', {
      position: toast.POSITION.BOTTOM_RIGHT,
      toastId
    })

    const dados = JSON.parse(valorFiltro)
    const consultar = func[tipoFiltro]
    try {
      const data = await consultar(dados)
      setResultados(data)
    } catch (err) {
      const message =
        err.response?.data?.message || 'Não foi possível fazer a busca!'
      toast.error(message, {
        position: toast.POSITION.BOTTOM_RIGHT
      })
      console.error(err)
    } finally {
      toast.dismiss(toastId)
    }
  }, [tipoFiltro, valorFiltro])

  const renderNotLoaded = () => {
    return <div>Os dados ainda não foram carregados</div>
  }

  const renderError = () => {
    return (
      <div>Ops! Aconteceu um erro e não foi possível carregar os matches</div>
    )
  }

  const renderEmpty = () => {
    return <div>Nenhuma empresa encontrada para o match</div>
  }

  const renderLoaded = () => {
    if (matchesRedux.data.length === 0) {
      return renderEmpty()
    }

    const emails = matchesRedux.data
      .map(item => ({
        value: { email: item.correioEletronico },
        label: item.correioEletronico
      }))
      .filter(item => !!item.label)
      .filter(
        (item, index, array) =>
          array.findIndex(i => i.label === item.label) === index
      )
    const dominioEmails = matchesRedux.data
      .map(item => ({
        value: { dominio: item.dominioEmail },
        label: item.dominioEmail
      }))
      .filter(item => !!item.label)
      .filter(
        (item, index, array) =>
          array.findIndex(i => i.label === item.label) === index
      )
    const telefones = matchesRedux.data
      .map(item => ({
        value: { ddd: item.ddd1, telefone: item.telefone1, ordem: '1' },
        label: (item.ddd1 ? item.ddd1 + ' ' : '') + item.telefone1
      }))
      .concat(
        matchesRedux.data.map(item => ({
          value: { ddd: item.ddd2, telefone: item.telefone2, ordem: '2' },
          label: (item.ddd2 ? item.ddd2 + ' ' : '') + item.telefone2
        }))
      )
      .filter(item => !!item.label)
      .filter(
        (item, index, array) =>
          array.findIndex(i => i.label === item.label) === index
      )
    const enderecos = matchesRedux.data
      .map(item => ({
        value: { logradouro: item.logradouro, cep: item.cep },
        label: item.logradouro + (item.cep ? ` (${item.cep})` : '')
      }))
      .filter(item => item.label)
      .filter(
        (item, index, array) =>
          array.findIndex(i => i.label === item.label) === index
      )

    const filtro =
      tipoFiltro === 'dominio'
        ? dominioEmails
        : tipoFiltro === 'email'
        ? emails
        : tipoFiltro === 'endereco'
        ? enderecos
        : telefones
    const empresas = (grafo?.nodes || [])
      .filter(item => !!item.metadata?.cnpj)
      .map(item => item.metadata.cnpj)
    return (
      <div className=''>
        <div className='d-flex flex-row'>
          <select
            className='custom-select'
            onChange={event => {
              setTipoFiltro(event.target.value)
              setValorFiltro(undefined)
            }}
          >
            <option value='dominio' selected={tipoFiltro === 'dominio'}>
              Domínio de e-mail
            </option>
            <option value='email' selected={tipoFiltro === 'email'}>
              E-mail
            </option>
            <option value='endereco' selected={tipoFiltro === 'endereco'}>
              Endereço
            </option>
            <option value='telefone' selected={tipoFiltro === 'telefone'}>
              Telefone
            </option>
          </select>
          <select
            className='custom-select ml-2'
            onChange={event => {
              setValorFiltro(event.target.value)
            }}
          >
            {[{ label: 'Selecione uma opção', value: undefined }]
              .concat(
                filtro.filter(
                  item => !['null', 'undefined'].includes(item.label)
                )
              )
              .map(item => {
                return (
                  <option
                    key={item.label}
                    value={JSON.stringify(item.value)}
                    selected={valorFiltro === JSON.stringify(item.value)}
                  >
                    {item.label}
                  </option>
                )
              })}
          </select>
          <button
            type='button'
            className='btn btn-primary ml-4'
            onClick={handleFilter}
          >
            Filtrar
          </button>
        </div>
        <div className='mt-2'>
          {Object.entries(resultados).map(([key, values]) => (
            <table className='table table-striped' key={key}>
              <thead>
                <tr>
                  <th scope='col'>CNPJ</th>
                  <th scope='col'>Razão Social</th>
                </tr>
              </thead>
              <tbody>
                {values.map(({ cnpj, razaoSocial }) => {
                  return (
                    <tr key={cnpj.toString()}>
                      <td
                        {...(empresas.includes(cnpj.toString()) && {
                          className: 'bg-warning'
                        })}
                      >
                        {CNPJ_FORMATTER(cnpj)}
                      </td>
                      <td
                        {...(empresas.includes(cnpj.toString()) && {
                          className: 'bg-warning'
                        })}
                      >
                        {razaoSocial}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          ))}
        </div>
      </div>
    )
  }

  return (
    <CustomWindow title='Match' name='grupoSocietario.match'>
      {matchesRedux.error
        ? renderError()
        : !matchesRedux.loaded
        ? renderNotLoaded()
        : renderLoaded()}
    </CustomWindow>
  )
}

const maps2Props = state => ({
  sidebar: state.sidebar
})

export default connect(maps2Props)(Grafo)
