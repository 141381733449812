import { BUCKET_NAME } from '../config/env'
import { getIdToken, resolveScope } from '../utils'
import { StaticS3Client } from './s3'

export class StaticScopeMetadata {
  static _data

  static async updateMetadata ({ accessToken } = {}) {
    const internalAccessToken = accessToken || (await getIdToken())
    const s3Client = await StaticS3Client.getInstance({
      accessToken: internalAccessToken
    })

    const scope = resolveScope()

    const bucket = BUCKET_NAME
    const key = `boanota/${scope}/frontend/field=metadata/json`

    const metadata = await s3Client.readFile({
      bucket,
      key,
      responseContentType: 'application/json'
    })
    StaticScopeMetadata._data = metadata

    return metadata
  }

  static getMedata () {
    return StaticScopeMetadata._data
  }

  static clearMetadata () {
    StaticScopeMetadata._data = null
  }

  static getAvailableSteps () {
    return StaticScopeMetadata._data.sqs || []
  }

  static getAvailablePages () {
    return StaticScopeMetadata._data.exclusivePages || []
  }

  static getAvailableApps () {
    return StaticScopeMetadata._data.apps || []
  }

  static getAvailableTables () {
    return StaticScopeMetadata._data.exclusiveTables || []
  }

  static getAvailableFeatures () {
    return StaticScopeMetadata._data.features || {}
  }
}

export const EXCLUSIVE_CONTENTS = {
  PAGES: {
    FORMULARIO: 'formulario',
    OPERACIONAIS: 'operacionais'
  },
  TABLES: {
    CAIXA: 'operacionais.caixa'
  },
  FEATURES: {
    DOCX: 'docx',
    REPORT_PAGE: 'reportPage'
  },
  APPS: {
    DOSSIER: 'dossier',
    INVESTMENT_FUND_ANALYSIS: 'atrio-investment-fund-analysis',
    CNAB: 'atrio-cnab',
    ATRIO_PUBLIC_REPORT: 'atrio-public-report'
  }
}
