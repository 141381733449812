import { Types } from '../reducers/grafo'

export const resetGrafoData = () => ({
  type: Types.RESET_GRAFO
})

export const setSelectedNodes = data => ({
  type: Types.SET_SELECTED_NODES,
  payload: data
})

export const setGrafoError = () => ({
  type: Types.SET_ERROR
})

export const setGrafoLoading = value => ({
  type: Types.SET_LOADING,
  payload: value
})

export const setGrafoData = ({ key, data }) => ({
  type: Types.SET_DATA,
  payload: { key, data }
})

export const setGrafoLink = ({ link }) => ({
  type: Types.SET_LINK,
  payload: link
})

export const setErrorGrafoLink = data => ({
  type: Types.SET_ERROR_LINK,
  payload: data
})
