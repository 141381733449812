import React from 'react'
import { Field } from '../../../../../components'

export default function RamoDeAtividade ({ data, error }) {
  if (error) {
    return <div>Erro no carregamento dos dados</div>
  }

  return (
    <div className='row'>
      <Field
        className='col-3 mb-3'
        label='Alíquota Máxima'
        value={data?.ramoDeAtividade?.cnaeAliquotaMax}
      />
      <Field
        className='col-3 mb-3'
        label='Alíquota Mínima'
        value={data?.ramoDeAtividade?.cnaeAliquotaMin}
      />
      <Field
        className='col-3 mb-3'
        label='Anexo'
        value={data?.ramoDeAtividade?.cnaeAnexo}
      />

      <Field
        className='col-3 mb-3'
        label='Código'
        value={data?.cnaeFiscalPrincipal}
      />

      <Field
        className='col-12 mb-3'
        label='Compreende'
        value={data?.ramoDeAtividade?.cnaeCompreende}
      />

      <Field
        className='col-12 mb-3'
        label='Não Compreende'
        value={data?.ramoDeAtividade?.cnaeNCompreende}
      />
    </div>
  )
}
