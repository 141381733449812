import React from 'react'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'

import { Table } from '../../../../../components'
import { onSortDocument } from '../../../../../components/Table/sort'
import { formatDocument, hashObject, onlyNumbers } from '../../../../../utils'

const { SearchBar } = Search

export default function ParentesSocios ({ data, error }) {
  if (error) {
    return <div>Erro no carregamento dos dados</div>
  }

  const tableData = data?.pessoasFisicasRelacionadas?.parentesSocios

  const columns = [
    {
      dataField: 'document',
      text: 'CPF',
      sort: true,
      sortFunc: onSortDocument
    },
    {
      dataField: 'nome',
      text: 'Nome',
      sort: true
    },
    {
      dataField: 'grau',
      text: 'Grau',
      sort: true
    },
    {
      dataField: 'pessoaDeLigacao',
      text: 'Pessoa de ligação',
      sort: true
    },
    {
      dataField: 'rawDocumento',
      hidden: true
    }
  ]

  const mappedData = (tableData || []).map(item => {
    const id = hashObject(item)

    return {
      id,
      rawDocumento: formatDocument(item.document),
      document: item.document ? (
        <a
          href={`?documento=${onlyNumbers(item.document)}`}
          target='_blank'
          rel='noopener noreferrer'
        >
          {' '}
          {formatDocument(item.document)}
        </a>
      ) : (
        '-'
      ),
      nome: item.nome,
      grau: item.relationship,
      pessoaDeLigacao: item.nomePessoaLigacao
        ? item.documentoPessoaLigacao
          ? `${item.nomePessoaLigacao} (${item.documentoPessoaLigacao})`
          : `${item.nomePessoaLigacao}`
        : item.documentoPessoaLigacao
    }
  })

  return (
    <ToolkitProvider
      keyField='id'
      data={mappedData}
      columns={columns.map(item => ({
        ...item,
        align: 'center',
        headerAlign: 'center'
      }))}
      search
    >
      {props => (
        <>
          <div className='row mb-2'>
            <div className='col-12 text-right'>
              <SearchBar {...props.searchProps} placeholder='Pesquisar' />
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <Table {...props.baseProps} enablePagination />
            </div>
          </div>
        </>
      )}
    </ToolkitProvider>
  )
}
