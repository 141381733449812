import {
  resolveProgressStatus,
  isError,
  isLoaded
} from '../../lib/progressStatus'

// Action Types
export const Types = {
  RESET: 'VISITAS_THEME/RESET',
  SET_S3_PATH: 'VISITAS_THEME/SET_S3_PATH',
  SET_ERROR: 'VISITAS_THEME/SET_ERROR'
}

// Reducer
const initialState = {
  s3Path: undefined,
  error: false,
  loaded: false
}

export default function reducer (state = initialState, action) {
  switch (action.type) {
    case Types.SET_S3_PATH: {
      const status = resolveProgressStatus(action.payload)

      return {
        ...state,
        s3Path: action.payload,
        loaded: isLoaded(status),
        error: isError(status)
      }
    }
    case Types.SET_ERROR:
      return {
        ...state,
        s3Path: action.payload,
        error: true,
        loaded: true
      }
    case Types.RESET:
      return initialState
    default:
      return state
  }
}

// Action Creators
export function setErrorVisitas (data) {
  return {
    type: Types.SET_ERROR,
    payload: data
  }
}

export function setS3PathVisitas (data) {
  return {
    type: Types.SET_S3_PATH,
    payload: data
  }
}

export function resetVisitasTheme () {
  return {
    type: Types.RESET
  }
}
