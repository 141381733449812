import * as yup from 'yup'

import pldQuestionnaires from '../questionnaires/pld'
import prospeccaoQuestionnaires from '../questionnaires/prospeccao'
import comportamentalQuestionnaires from '../questionnaires/comportamental'

export class ValidationFormError extends Error {
  constructor (message, errorObject) {
    super(message)
    this.message = message
    this.errorObject = errorObject
  }
}

export async function validateForm ({ schema, data }) {
  if (!yup.isSchema(schema)) {
    throw new Error('Você deve informar um schema válido')
  }

  if (typeof data !== 'object') {
    throw new Error('Você deve informar dados válidos')
  }

  return schema.validate(data, { abortEarly: false }).catch(err => {
    const errorObject = err.inner.reduce((obj, item) => {
      obj[item.path] = item.message
      return obj
    }, {})
    throw new ValidationFormError(err.message, errorObject)
  })
}

export const SCHEMA_SCORE_PLD = yup.object().shape(
  pldQuestionnaires.reduce((obj, item) => {
    obj[item.id] = yup
      .mixed()
      .oneOf(['sim', 'nao'], 'Tipo de resposta inválida')
      .required('Campo obrigatório')
    return obj
  }, {})
)

export const SCHEMA_SCORE_COMPORTAMENTAL = yup.object().shape(
  comportamentalQuestionnaires.reduce((obj, item) => {
    obj[item.id] = yup.number().required('Campo obrigatório')
    return obj
  }, {})
)

export const SCHEMA_SCORE_PROSPECCAO = yup.object().shape(
  prospeccaoQuestionnaires.reduce((obj, item) => {
    obj[item.id] = yup.number().required('Campo obrigatório')
    return obj
  }, {})
)
