import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'

import { selectMinData } from '../../../../../redux/selectors/dadosBasicos'

import { matchFuncionarios } from '../../../../../lib/matchDeFuncionarios'

import {
  CNPJ_FORMATTER,
  CPF_FORMATTER,
  formatDocument,
  hashObject,
  onlyNumbers
} from '../../../../../utils'

import { onSortDocument } from '../../../../../components/Table/sort'
import { CustomWindow, Loading, Table } from '../../../../../components'

const { SearchBar } = Search

const TabelaMatchFuncionarios = ({
  funcionarios,
  exFuncionarios,
  match,
  loaded
}) => {
  const notLoaded = !funcionarios.loaded || !exFuncionarios.loaded
  const error = funcionarios.error || exFuncionarios.error

  const { targetName, isCPF, targetDocument } = useSelector(selectMinData)
  const [tipoMatch, setTipoMatch] = useState('only')
  const name = 'operacionais.matchFuncionarios'

  if (!loaded) {
    return (
      <CustomWindow
        title='Match Funcionários e Ex-funcionários'
        name={name}
        showNote
      >
        <Loading />
      </CustomWindow>
    )
  }

  if (notLoaded) {
    return (
      <CustomWindow title='Match Funcionários e Ex-funcionários' name={name}>
        <div />
      </CustomWindow>
    )
  }

  if (error) {
    return (
      <CustomWindow
        title={`Match Funcionários e Ex-funcionários ${
          targetName ? `- ${targetName}` : ''
        }`}
        subtitle={`${
          targetDocument ? (isCPF ? 'CPF:' : 'CNPJ:') : ''
        } ${formatDocument(targetDocument)}`}
        name={name}
        showNote
      >
        <div>
          Ops! Aconteceu um erro e por isso não foi possível carregar a tabela
          de matches dos funcionários e ex-funcionários jive.
        </div>
      </CustomWindow>
    )
  }

  const funcionariosData = funcionarios?.data || []
  const exFuncionariosData = exFuncionarios?.data || []
  const mathData = match?.data || []

  if (
    funcionariosData.length === 0 &&
    exFuncionariosData.length === 0 &&
    mathData.length === 0
  ) {
    return (
      <CustomWindow
        title={`Match Funcionários e Ex-funcionários ${
          targetName ? `- ${targetName}` : ''
        }`}
        subtitle={`${
          targetDocument ? (isCPF ? 'CPF:' : 'CNPJ:') : ''
        } ${formatDocument(targetDocument)}`}
        name={name}
        showNote
      >
        <div />
      </CustomWindow>
    )
  }

  // Computa os matches dos funcionários e ex-funcionários
  const matches = matchFuncionarios({
    funcionarios,
    exFuncionarios,
    targetDocument,
    match,
    tipoMatch
  })

  return (
    <CustomWindow
      title={`Match Funcionários e Ex-funcionários ${
        targetName ? `- ${targetName}` : ''
      }`}
      subtitle={`${
        targetDocument ? (isCPF ? 'CPF:' : 'CNPJ:') : ''
      } ${formatDocument(targetDocument)}`}
      name={name}
      showNote
    >
      <ToolkitProvider
        keyField='id'
        search
        columns={[
          { dataField: 'nome', text: 'Nome Funcionário', sort: true },
          {
            dataField: 'cpf',
            text: 'CPF',
            searchable: false,
            sort: true,
            sortFunc: onSortDocument
          },
          { dataField: 'funcionario', text: 'É funcionário?', sort: true },
          {
            dataField: 'exfuncionario',
            text: 'É ex-funcionário?',
            sort: true
          },
          { dataField: 'dataAdmissao', text: 'Data admissão', sort: true },
          {
            dataField: 'anoMesDesligamento',
            text: 'Data desligamento',
            sort: true
          },
          {
            dataField: 'documentoRazaoSocial',
            text: 'Razão Social Empresa',
            sort: true
          },
          {
            dataField: 'documentoEmpresa',
            text: 'Empresa (CNPJ)',
            searchable: false,
            sort: true,
            sortFunc: (...args) => {
              const order = args[2]
              const a = args[4]?.rawEmpresa || ''
              const b = args[5]?.rawEmpresa || ''

              const cleanedA = onlyNumbers(a)
              const cleanedB = onlyNumbers(b)

              if (order === 'asc') {
                return cleanedA.localeCompare(cleanedB)
              } else {
                return cleanedB.localeCompare(cleanedA)
              }
            }
          },
          { dataField: 'rawCpf', hidden: true },
          { dataField: 'rawEmpresa', hidden: true }
        ].map(item => ({ ...item, align: 'center', headerAlign: 'center' }))}
        data={matches.map(item => {
          const id = hashObject(item)
          return {
            id,
            nome: item.nome,
            rawDocumento: CPF_FORMATTER(item.cpf),
            rawEmpresa: CNPJ_FORMATTER(item.documentoEmpresa),
            cpf: item.cpf ? (
              <a
                href={`?documento=${onlyNumbers(item.cpf)}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                {CPF_FORMATTER(item.cpf)}
              </a>
            ) : (
              '-'
            ),
            funcionario: item.funcionario ? 'Sim' : 'Não',
            exfuncionario: item.exfuncionario ? 'Sim' : 'Não',
            dataAdmissao: item.dataAdmissao
              .split('T')[0]
              .split('-')
              .reverse()
              .join('/'),
            anoMesDesligamento: item.anoMesDesligamento
              ?.split('T')[0]
              .split('-')
              .join('/'),
            documentoRazaoSocial: item.documentoRazaoSocial,
            documentoEmpresa: item.documentoEmpresa ? (
              <a
                href={`?documento=${onlyNumbers(item.documentoEmpresa)}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                {CNPJ_FORMATTER(item.documentoEmpresa)}
              </a>
            ) : (
              '-'
            )
          }
        })}
      >
        {props => (
          <>
            <div className='row'>
              <div className='col-6'>
                <div className='btn-group mb-2' role='group'>
                  <button
                    type='button'
                    className={`btn ${
                      tipoMatch === 'only' ? 'btn-primary' : ''
                    }`}
                    onClick={() => setTipoMatch('only')}
                  >
                    Match
                  </button>
                  <button
                    type='button'
                    className={`btn ${
                      tipoMatch === 'all' ? 'btn-primary' : ''
                    }`}
                    onClick={() => setTipoMatch('all')}
                  >
                    Todos
                  </button>
                </div>
              </div>
              <div className='col-6 text-right'>
                <SearchBar {...props.searchProps} placeholder='Pesquisar' />
              </div>
            </div>
            <Table enablePagination keyField='id' {...props.baseProps} />
          </>
        )}
      </ToolkitProvider>
    </CustomWindow>
  )
}

export default TabelaMatchFuncionarios
