import {
  resolveProgressStatus,
  isError,
  isLoaded
} from '../../lib/progressStatus'

// Action Types
export const Types = {
  RESET: 'OFFSHORE_THEME/RESET',
  SET_S3_PATH: 'OFFSHORE_THEME/SET_S3_PATH',
  SET_ERROR: 'OFFSHORE_THEME/SET_ERROR'
}

// Reducer
const initialState = {
  offshoreLeaks: {
    s3Path: undefined,
    error: false,
    loaded: false
  },
  rde: {
    s3Path: undefined,
    error: false,
    loaded: false
  }
}

export default function reducer (state = initialState, action) {
  switch (action.type) {
    case Types.SET_S3_PATH: {
      const statusOffshoreLeaks = resolveProgressStatus(
        action.payload.offshoreLeaks
      )
      const statusRde = resolveProgressStatus(action.payload.rde)

      return {
        ...state,
        offshoreLeaks: {
          ...state.offshoreLeaks,
          s3Path: action.payload.offshoreLeaks,
          loaded: isLoaded(statusOffshoreLeaks),
          error: isError(statusOffshoreLeaks)
        },
        rde: {
          ...state.rde,
          s3Path: action.payload.rde,
          loaded: isLoaded(statusRde),
          error: isError(statusRde)
        }
      }
    }
    case Types.SET_ERROR:
      return {
        ...state,
        offshoreLeaks: {
          ...state.offshoreLeaks,
          error: action.payload,
          loaded: true
        },
        rde: { ...state.sde, error: action.payload, loaded: true }
      }
    case Types.RESET:
      return initialState
    default:
      return state
  }
}

// Action Creators
export function setErrorOffshore (data) {
  return {
    type: Types.SET_ERROR,
    payload: data
  }
}

export function setS3PathOffshore ({ rde, offshoreLeaks }) {
  return {
    type: Types.SET_S3_PATH,
    payload: { rde, offshoreLeaks }
  }
}

export function resetOffshoreTheme () {
  return {
    type: Types.RESET
  }
}
