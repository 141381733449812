export const Types = {
  RESET_GRAFO: 'grafo/RESET',
  SET_SELECTED_NODES: 'grafo/SET_SELECTED_NODES',
  SET_ERROR: 'grafo/SET_ERROR',
  SET_LOADING: 'grafo/SET_LOADING',
  SET_DATA: 'grafo/SET_DATA',
  SET_LINK: 'grafo/SET_LINK',
  SET_ERROR_LINK: 'grafo/SET_ERROR_LINK'
}

const INITIAL_VALUE = {
  selectedNodes: undefined,
  values: {},
  loading: false,
  error: false,
  link: '',
  errorLink: false
}

const grafoReducer = (state = INITIAL_VALUE, action) => {
  if (action.type === Types.SET_DATA) {
    const { key, data } = action.payload
    return {
      ...state,
      values: { ...state.values, [key]: data },
      loading: false,
      error: false
    }
  } else if (action.type === Types.SET_SELECTED_NODES) {
    return {
      ...state,
      selectedNodes: action.payload
    }
  } else if (action.type === Types.RESET_GRAFO) {
    return INITIAL_VALUE
  } else if (action.type === Types.SET_ERROR) {
    return {
      ...state,
      error: true
    }
  } else if (action.type === Types.SET_LOADING) {
    return {
      ...state,
      loading: action.payload
    }
  } else if (action.type === Types.SET_LINK) {
    return {
      ...state,
      link: action.payload
    }
  } else if (action.type === Types.SET_ERROR_LINK) {
    return {
      ...state,
      errorLink: action.payload
    }
  }

  return {
    ...state
  }
}

export default grafoReducer
