// Action Types
export const Types = {
  RESET: 'googleMaps/RESET',
  SET_IMAGES: 'googleMaps/SET_IMAGES',
  SET_ADDRESS: 'googleMaps/SET_ADDRESS',
  SET_ERROR_IMAGES: 'googleMaps/SET_ERROR_IMAGES',
  FETCH_IMAGES: 'googleMaps/FETCH_IMAGES'
}

// Reducer
const initialState = {
  images: [],
  address: undefined,
  rawAddress: {
    tipoLogradouro: undefined,
    logradouro: undefined,
    numero: undefined,
    municipio: undefined,
    estado: undefined,
    complemento: undefined
  },
  geolocation: undefined,
  fetchedAddress: undefined,
  isLoadedImages: false,
  errorImages: false
}

export default function reducer (state = initialState, action) {
  switch (action.type) {
    case Types.SET_IMAGES: {
      const { images, address: fetchedAddress, geolocation } = action.payload
      return {
        ...state,
        images,
        fetchedAddress,
        geolocation,
        isLoadedImages: true
      }
    }
    case Types.SET_ADDRESS:
      return {
        ...state,
        address: action.payload.parsedData,
        rawAddress: action.payload.rawData
      }
    case Types.SET_ERROR_IMAGES:
      return { ...state, errorImages: action.payload, isLoadedImages: true }
    case Types.RESET:
      return initialState
    default:
      return state
  }
}
