import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { selectMinData } from '../../../../../../redux/selectors/dadosBasicos'
import { setImagensSulBrasil } from '../../../../../../redux/store/sulbrasil'

import ImagePicker from './ImagePicker'

export default function Images () {
  const dispatch = useDispatch()
  const {
    marca: marcaProps,
    streetView: streetViewProps,
    produto: produtoProps
  } = useSelector(state => state.sulbrasil.formulario.imagens)
  const { targetDocument } = useSelector(selectMinData)
  const googleMaps = useSelector(state => state.googleMaps)

  if (
    !targetDocument &&
    !googleMaps.errorImages &&
    !googleMaps.isLoadedImages
  ) {
    return <div />
  }

  const handleSubmit = async ({ marca, produto, streetView }) => {
    dispatch(setImagensSulBrasil({ marca, produto, streetView }))
  }

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-6 d-flex flex-column align-items-center text-center'>
          <div>
            <h2 className='h3'>Imagem da Marca</h2>
            <ImagePicker
              data={marcaProps}
              onChange={marca =>
                handleSubmit({
                  marca,
                  produto: produtoProps,
                  streetView: streetViewProps
                })
              }
              onError={err => console.error(err)}
            />
          </div>
        </div>
        <div className='col-6 d-flex flex-column align-items-center text-center'>
          <div>
            <h2 className='h3'>Imagem do Produto</h2>
            <ImagePicker
              data={produtoProps}
              onChange={produto =>
                handleSubmit({
                  produto,
                  marca: marcaProps,
                  streetView: streetViewProps
                })
              }
              onError={err => console.error(err)}
            />
          </div>
        </div>
      </div>
      <div className='row mt-4'>
        <div className='col-12 d-flex flex-column align-items-center text-center'>
          <div>
            <h2 className='h3'>Imagens do Google Street View</h2>
            <ImagePicker
              data={streetViewProps}
              onChange={streetView => {
                if (streetView?.length > 4) {
                  toast.warning('São permitidas no máximo 4 imagens')
                }
                handleSubmit({
                  streetView: streetView?.slice(0, 4),
                  marca: marcaProps,
                  produto: produtoProps
                })
              }}
              onError={err => console.error(err)}
              multiple
            />
          </div>
        </div>
      </div>
    </div>
  )
}
