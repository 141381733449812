import axios from 'axios'

import { COMMENTS_BASE_URL } from '../../config/env'
import { getEmail, getIdToken, toCamelCase } from '../../utils'

export const moveComment = async ({ document, field, newField }) => {
  const [token, email] = await Promise.all([getIdToken(), getEmail()])

  const { data } = await axios
    .get(`${COMMENTS_BASE_URL}/moveField`, {
      params: {
        fields: field,
        key: document,
        new_fields: newField
      },
      headers: {
        Authorization: `Bearer ${token}`,
        'x-scope-id': 'boanota',
        'x-username-id': email
      }
    })
    .catch(err => {
      const errorMessage = err.response.data.message || err.message

      if (errorMessage === 'blob not found.') {
        return { data: { message: 'blob not found.' } }
      }

      throw err
    })

  return toCamelCase(data)
}
